import { RoleEnum } from "@/appModules/userApp/domain/enumerated/RoleEnum";
import { i18n } from "@/main";
import moment from "moment";
import "moment-timezone";
import { ViewModeEnum } from "../enumerated/ViewModeEnum";

export default class Format {
   amountToString(amount: number) {
      if (amount === null) {
         return "- - , - -";
      } else if (amount <= 0) {
         return "0,00";
      } else {
         const val = (amount / 100).toFixed(2).replace(".", ",");
         return val;
      }
   }

   isInPreconditionFailedException(message: string, code: string) {
      for (const item of message.split(";")) {
         if (item === code) {
            return true;
         }
      }
      return false;
   }

   generateLabelDeclareTimeRent(timeValue: number, timeType: number, multiply: number) {
      let label = "";
      label = (timeValue * multiply).toString();

      if (timeType == 1) {
         label += " sec.";
      } else if (timeType == 2) {
         label += " min";
      } else if (timeType == 3) {
         label += " godz.";
      } else {
         label += " d.";
      }
      return label;
   }
   generateInfoTariff(tariff: any) {
      let rez = "";
      if (tariff.mode == 1) {
         for (const tariffBasicItem of tariff.tariffBasicItems) {
            const declareTimeRent = tariffBasicItem.declareTimeRent;
            if (declareTimeRent.status == 1 && tariffBasicItem.amount > 0) {
               rez = rez + this.amountToString(tariffBasicItem.amount) + " zł / ";
               const rez2 = this.generateLabelDeclareTimeRent(declareTimeRent.timeValue, declareTimeRent.timeType, 1);
               rez = rez + rez2 + "<br/>";
            }
         }
      }
      return rez;
   }
}

export function toStringRoleEnum(role: string, short: boolean): string {
   const enumValues = Object.values(RoleEnum);
   const enumKeys = Object.keys(RoleEnum);
   for (let i = 0; i < enumValues.length; i++) {
      if (enumValues[i] === role) {
         return short ? i18n.global.t(`${enumKeys[i]}`) : i18n.global.t(`${enumKeys[i]}Short`);
      }
   }
   return "";
}

export function toStringEnum(value: string, enumerated: any): string | undefined {
   if (value) {
      const enumValues = Object.values(enumerated) as string[];
      const enumKeys = Object.keys(enumerated) as string[];

      for (let i = 0; i < enumValues.length; i++) {
         if (enumValues[i].length > 0) {
            if (enumValues[i].toUpperCase() === value.replace("_", "")) {
               return enumKeys[i];
            }
         }
      }
   }
   return "";
}

export function getClassTab(currentNumber: number, numberTab: number, mode: ViewModeEnum) {
   if (mode === ViewModeEnum.NEW) {
      if (currentNumber === numberTab) {
         return "tab-active";
      } else {
         if (currentNumber > numberTab) {
            return "tab-complete";
         }
      }
      return "tab";
   } else {
      if (currentNumber === numberTab) {
         return "tab-active";
      } else {
         return "tab-inactive underline-transition";
      }
   }
}

export function getFormattedTabTitle(title: string) {
   return title ? `${title} · ${document.title}` : "";
}
