
import { httpAuthorization } from "@/appModules/common/domain/service/http-common";
import { defineComponent, onMounted, ref } from "vue";
import { RouterLink } from "vue-router";

export default defineComponent({
   name: "Tariffs",
   setup() {
      const tariffs = ref();
      onMounted(() => {
         fetchTariffs();
         console.log(tariffs);
      });

      function fetchTariffs() {
         httpAuthorization()
            .get("/api/c/tariffs")
            .then((response) => {
               tariffs.value = response.data;
            });
      }
      return { tariffs };
   },
});
