
import { defineComponent, onMounted } from "vue";
import { useProfileUserAppStore } from "../store/ProfileUserAppStore";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

export default defineComponent({
   name: "UserAppProfileHeader",
   setup() {
      const store = useProfileUserAppStore();
      const { findById } = store;
      const { getUserApp } = storeToRefs(store);
      const route = useRoute();

      onMounted(() => {
         if (!getUserApp.value.uuid)
            if (route.params.id) {
               findById(route.params.id);
            }
      });

      return { getUserApp };
   },
});
