import RoleDto from "@/appModules/userApp/domain/dto/RoleDto";

export interface IUserApp {
   uuid: string;
   firstName: string;
   lastName: string;
   account: boolean;
   username: string;
   enabled: boolean;
   roles: RoleDto[];
   version: number;
}

export class UserAppDto implements IUserApp {
   constructor(
      public uuid: string = "",
      public firstName: string = "",
      public lastName: string = "",
      public account: boolean = false,
      public username: string = "",
      public enabled: boolean = false,
      public roles: RoleDto[] = [],
      public version: number = 0
   ) {}
}

export default class UserApp extends UserAppDto {
   constructor(dto?: UserAppDto) {
      super();
      if (dto) {
         this.uuid = dto.uuid;
         this.firstName = dto.firstName;
         this.lastName = dto.lastName;
         this.username = dto.username;
         this.account = dto.account;
         this.enabled = dto.enabled;
         this.roles = dto.roles;
      }
   }

   get fullName(): string {
      let rez = "";
      if (this.firstName) rez = this.firstName + " ";

      if (this.lastName) rez = rez + this.lastName;

      return rez;
   }
}
