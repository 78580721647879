import usePageParams from "@/appModules/common/domain/composable/usePageParams";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { i18n } from "@/main";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import WarehouseApi from "../domain/repository/WarehouseApi";
import WarehouseDto from "../domain/dto/WarehouseDto";
import { WarehouseErrorItemCodeEnum } from "@/appModules/service/domain/enumerated/WarehouseErrorItemCodeEnum";
import CreateWarehouseDto from "../domain/dto/CreateWarehouseDto";
import UpdateWarehouseDto from "../domain/dto/UpdateWarehouseDto";

const api = new WarehouseApi();
const toast = useToast();
const params = usePageParams();

export const useWarehouseStore = defineStore({
   id: "warehouseStore",
   state: () => ({
      warehouseDto: new WarehouseDto(),
      errors: [] as ErrorItem[],
   }),
   getters: {
      getPageParams: () => params,
      getWarehouse: (state) => state.warehouseDto,
      getErrors: (state) => state.errors,
   },
   actions: {
      async findById(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .findById(id)
            .then(
               (success) => {
                  this.warehouseDto = success.data;
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async delete(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .delete(id)
            .then(
               (success) => {
                  if (success.status == 200) {
                     toast.success(i18n.global.t("WarehouseDeleted"));
                  }
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async create() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         const createWarehouseDto = new CreateWarehouseDto(
            this.warehouseDto.name,
            this.warehouseDto.type,
            this.warehouseDto.addressOtherThanMain,
            this.warehouseDto.addressOtherThanMain ? this.warehouseDto.address : undefined
         );

         return api
            .create(createWarehouseDto)
            .then(
               (success) => {
                  if (success.status == 201) {
                     this.warehouseDto = success.data;
                     params.created.value = true;
                     this.errors = [];
                     toast.success(i18n.global.t("NewWarehouseAdded"));
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      async update() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         const updateWarehouseDto = new UpdateWarehouseDto(
            this.warehouseDto.name,
            this.warehouseDto.type,
            this.warehouseDto.addressOtherThanMain,
            this.warehouseDto.addressOtherThanMain ? this.warehouseDto.address : undefined,
            this.warehouseDto.version
         );

         return api
            .update(updateWarehouseDto, this.warehouseDto.uuid)
            .then(
               (success) => {
                  if (success.status == 200) {
                     this.warehouseDto = success.data;
                     toast.success(i18n.global.t("WarehouseUpdated"));
                     this.errors = [];
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      resetToInitial() {
         params.created.value = false;
         params.noDataToDisplay.value = false;
         this.warehouseDto = new WarehouseDto();
         this.errors = [];
      },

      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === WarehouseErrorItemCodeEnum.DuplicateName) {
               setFieldError("warehouseName", i18n.global.t("ErrorWarehouseNameExists"));
            }
            if (e.code === WarehouseErrorItemCodeEnum.DuplicateName2) {
               setFieldError("warehouseName", i18n.global.t("ErrorWarehouseNameIsAlreadyTaken"));
            }
         }
      },
   },
});
