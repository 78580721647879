import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "history-bar" }
const _hoisted_2 = { class: "container text-light" }
const _hoisted_3 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Products = _resolveComponent("Products")!
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
    classes: "modal-container",
    "content-class": "container modal-content",
    "click-to-close": false,
    "esc-to-close": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "modal-close btn btn-outline-light",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false))
      }, [
        _createVNode(_component_font_awesome_icon, { icon: "times" })
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("Products")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Products, {
          ref: "products",
          isModal: true,
          onSelectProduct: _ctx.selectProduct
        }, null, 8, ["onSelectProduct"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}