
import { defineComponent } from "vue";
// import SearchIndex from "@/components/common/SearchIndex.vue";

export default defineComponent({
   name: "AppIndex",
   components: {
      //   SearchIndex,
   },
});
