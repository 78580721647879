export interface IContact {
   subject: string;
   nameLastName: string;
   contents: string;
   email: string;
   phone: string;
   recaptchaTokenKey: string | undefined;
}

export class ContactDto implements IContact {
   constructor(
      public subject: string = "",
      public nameLastName: string = "",
      public contents: string = "",
      public email: string = "",
      public phone: string = "",
      public recaptchaTokenKey: string | undefined = ""
   ) {}
}

export default class Contact extends ContactDto {
   constructor(dto?: ContactDto) {
      super();
      if (!dto) dto = new ContactDto();

      Object.assign(this, dto);
   }
}
