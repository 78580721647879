
import * as yup from "yup";
import { i18n } from "@/main";
import { storeToRefs } from "pinia";
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { useProductStore } from "../store/ProductStore";
import { useRoute } from "vue-router";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { useToast } from "vue-toastification";
import { useCategoriesStore } from "@/appModules/category/store/CategoriesStore";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import CategoryDto from "@/appModules/category/domain/dto/CategoryDto";
import CategoryItemPanel from "@/appModules/category/component/CategoryItemPanel.vue";
import { getFormattedTabTitle } from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "Product",
   components: { LoadBar, Field, ErrorMessage, CategoryItemPanel },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectProduct"],
   setup(props, { emit }) {
      const store = useProductStore();
      const categoriesStore = useCategoriesStore();
      const { create, update, getPageParams, resetToInitial, findById, setErrorMsg } = store;
      const { getProduct, getErrors } = storeToRefs(store);
      const uuid = ref();
      const route = useRoute();
      const toast = useToast();
      const selected = ref("");
      const { typeGrid } = useBreakpointsGrid();

      const { errors, validate, setFieldError } = useForm({
         validationSchema: yup.object().shape({
            productName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            productDescription: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(1024, i18n.global.t("TextMaxLength", ["1024"])),
            categoryItemPanel: yup.string().required(i18n.global.t("FieldCanNotBeEmpty")),
         }) as any,
      });

      onMounted(async () => {
         uuid.value = route.params.id as string;
         if (uuid.value) {
            await findById(uuid.value);
            document.title = getFormattedTabTitle(getProduct.value.name);
         }
      });

      watch(
         () => getErrors.value,
         (newValue) => {
            if (newValue.length > 0) setErrorMsg(setFieldError);
         }
      );

      onUnmounted(() => {
         resetToInitial();
      });

      function showValue(id: string | string[]) {
         uuid.value = id;
         findById(uuid.value);
         categoriesStore.fetchPaginationData(categoriesStore.getPageDto.number, categoriesStore.getPageDto.size, "");
      }

      async function onValidate() {
         const { valid } = await validate();

         return valid;
      }

      async function submitSave() {
         if (await onValidate()) {
            if (uuid.value) await update();
            else await create();
            if (getProduct.value.uuid) {
               emit("selectProduct", getProduct.value);
            }
         } else toast.error(i18n.global.t("FormHasError"));
      }

      function change(categoryUuid: string) {
         getProduct.value.category.uuid = categoryUuid;
      }

      function selectCategory(category: CategoryDto) {
         getProduct.value.category = category;
      }

      function cancelCategory() {
         getProduct.value.category = new CategoryDto();
      }

      return {
         uuid,
         getProduct,
         getPageParams,
         errors,
         selected,
         categoriesStore,
         typeGrid,
         showValue,
         equalsOrGreaterGrid,
         submitSave,
         resetToInitial,
         setFieldError,
         change,
         selectCategory,
         cancelCategory,
         onValidate,
      };
   },
});
