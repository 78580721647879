import axios from "axios";
import { getAuthHeader } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import UserAppDTO from "@/appModules/userApp/domain/dto/UserAppDto";
import CreateUserAppDto from "@/appModules/userApp/domain/dto/CreateUserAppDto";
import UpdateUserAppDto from "../dto/UpdateUserAppDto";
import UpdatePasswordUserAppDto from "../../../service/domain/dto/UpdatePasswordUserAppDto";
import UserAppPaginationDto from "../dto/UserAppPaginationDto";
import AddAccessUserAppDto from "../dto/AddAccessUserAppDto";

const urlApi = "/api/users-app/v1/";

export default class UserAppApi {
   create(dto: CreateUserAppDto) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi, dto, {
         headers: getAuthHeader(),
      });
   }

   update(dto: UpdateUserAppDto, id: string | string[] | undefined = undefined) {
      return axios.put(process.env.VUE_APP_API_DOMAIN + urlApi + `${id ? id : ""}`, dto, {
         headers: getAuthHeader(),
      });
   }

   addAccess(dto: AddAccessUserAppDto) {
      return axios.put(process.env.VUE_APP_API_DOMAIN + urlApi + "enable-account", dto, {
         headers: getAuthHeader(),
      });
   }

   findById(uuid: string | string[]) {
      return axios.get<UserAppDTO>(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   findByPagination(query: string) {
      return axios.get<UserAppPaginationDto>(process.env.VUE_APP_API_DOMAIN + urlApi + query, {
         headers: getAuthHeader(),
      });
   }

   changePassword(dto: UpdatePasswordUserAppDto) {
      return axios.put(process.env.VUE_APP_API_DOMAIN + urlApi + "change-password", dto, {
         headers: getAuthHeader(),
      });
   }
}
