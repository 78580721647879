
import { httpAuthorization } from "@/appModules/common/domain/service/http-common";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent, onMounted, ref } from "vue";
import { RouterLink, useRoute } from "vue-router";

export default defineComponent({
   name: "tariff-template",
   setup() {
      const newObject = ref(true);
      const tariff = ref({
         id: 0,
         name: "",
         description: "",
      });
      const rowData = ref([] as any);
      const errorText = ref("");
      const successText = ref("");
      const nameIsInvalid = ref(false);
      const nameInvalidFeedback = ref("");
      const route = useRoute();
      const loadingData = ref(false);

      onMounted(() => {
         if (route.params.id) {
            newObject.value = false;
            fetchTariff();
         }
      });

      function fetchTariff() {
         httpAuthorization()
            .get("/api/c/tariffs/" + route.params.id)
            .then(
               (response) => {
                  tariff.value = response.data;
               },
               (error) => {
                  showMessage(2);
               }
            );
      }
      function addRow() {
         rowData.value.push({
            time_value: 1,
            time_type: 2,
            amount_current: "0.00",
            repeat_type: false,
         });
      }
      function deleteRow(index: number) {
         rowData.value.splice(index, 1);
      }
      function submitSave() {
         successText.value = "";
         errorText.value = "";

         if (validate()) {
            if (route.params.id) {
               httpAuthorization()
                  .put("/api/c/tariffs", tariff.value)
                  .then(
                     (response) => {
                        if (response.status === 200) {
                           tariff.value = response.data;
                           showMessage(0);
                        }
                     },
                     (error) => {
                        validError(error);
                     }
                  );
            } else {
               httpAuthorization()
                  .post("/api/c/tariffs", tariff.value)
                  .then(
                     (response) => {
                        if (response.status === 201) {
                           tariff.value = response.data;
                           showMessage(0);
                        }
                     },
                     (error) => {
                        validError(error);
                     }
                  );
            }
         } else {
            showMessage(1);
         }
      }
      function validate() {
         var rez = false;
         nameIsInvalid.value = false;
         if (validNameTextInput(tariff.value.name)) {
            rez = true;
         }
         return rez;
      }
      function validNameTextInput(name: string) {
         var rez = true;
         if (!name) {
            nameInvalidFeedback.value = "Pole nie może być puste";
            nameIsInvalid.value = true;
            rez = false;
         }
         return rez;
      }
      function validError(error: any) {
         if (error.response.status === 409) {
            // http status 409 oznacz problem z unikalnoscia pola
            if (error.response.data.message === "tariff_name") {
               nameInvalidFeedback.value = "Cennik o takiej nazwie już istnieje, proszę podać inną nazwe.";
               nameIsInvalid.value = true;
               showMessage(1);
            } else {
               showMessage(2);
            }
         } else {
            showMessage(2);
         }
      }
      function showMessage(messageType: number) {
         if (messageType === 0) {
            if (route.params.id) {
               successText.value = "Zapis zakończony powodzeniem. Zmiany zapisane.";
            } else {
               successText.value = "Zapis zakończony powodzeniem. Cennik dodany.";
            }
         } else if (messageType === 1) {
            errorText.value = "Formularz zawiera błędy, proszę poprawić.";
         } else if (messageType === 2) {
            errorText.value = "Problem z zapisem, proszę spróbować później.";
         } else {
            errorText.value = "Problem z połaczeniem do serwera, proszę spróbować później.";
         }
      }
      return {
         newObject,
         tariff,
         rowData,
         errorText,
         successText,
         nameIsInvalid,
         nameInvalidFeedback,
         loadingData,
         deleteRow,
         addRow,
         submitSave,
      };
   },
});
