
import { PropType, defineComponent, ref, watchEffect } from "vue";
import ItemInput from "../../common/component/ItemInput.vue";
import ProductsModal from "@/appModules/product/view/ProductsModal.vue";
import ProductModal from "@/appModules/product/view/ProductModal.vue";
import ProductDto from "@/appModules/product/domain/dto/ProductDto";
import { useProductStore } from "@/appModules/product/store/ProductStore";
import ItemInputCollapseResults from "../../common/component/ItemInputCollapseResults.vue";
import { storeToRefs } from "pinia";
import { useProductsStore } from "../store/ProductsStore";

export default defineComponent({
   name: "ProductItemPanel",
   components: {
      ItemInput,
      ProductsModal,
      ProductModal,
      ItemInputCollapseResults,
   },
   props: {
      name: {
         type: String,
         required: true,
      },
      label: {
         type: String,
      },
      placeholder: {
         type: String,
      },
      isRequiredStyle: {
         type: Boolean,
         default: false,
      },
      productExists: {
         type: Boolean,
         required: true,
      },
      errors: {
         type: Object,
      },
      product: {
         type: Object as PropType<ProductDto>,
         required: true,
      },
      isReadOnly: {
         type: Boolean,
         required: true,
      },
   },
   emits: ["selectProduct", "cancelProduct"],
   setup(props, { emit }) {
      const productsModal = ref<typeof ProductsModal>();
      const productModal = ref<typeof ProductModal>();
      const itemInput = ref<typeof ItemInput>();
      const productStore = useProductStore();
      const { resetToInitial } = productStore;
      const productsStore = useProductsStore();
      const { fetchPaginationData } = productsStore;
      const { getPageDto, getPageParams } = storeToRefs(productsStore);
      const collapse = ref(false);
      const maxResultsNumber = 10;
      const searchByLength = ref(0);
      const productName = ref("");

      watchEffect(() => (productName.value = props.product.name));

      function searchProduct() {
         productsModal.value?.openModal();
      }

      function addProduct() {
         productModal.value?.openModal();
         resetToInitial();
      }

      function editProduct() {
         productModal.value?.openModal(props.product.uuid);
      }

      function cancelProduct() {
         emit("cancelProduct");
         productName.value = "";
         productsStore.resetToInitial();
      }

      function selectProduct(product: ProductDto) {
         productName.value = product.name;
         collapse.value = false;
         productsStore.resetToInitial();
         emit("selectProduct", product);
      }

      async function showValue(searchBy: string) {
         searchByLength.value = searchBy.length;
         if (searchByLength.value >= 3) {
            await fetchPaginationData(0, maxResultsNumber, searchBy);
         }
      }

      function setCollapse(isFocus: boolean) {
         collapse.value = isFocus;
         if (!isFocus) {
            searchByLength.value = 0;
            if (props.product.uuid.length == 0) {
               itemInput.value?.resetField();
            }
         }
      }

      return {
         productsModal,
         productModal,
         getPageDto,
         collapse,
         maxResultsNumber,
         searchByLength,
         productName,
         getPageParams,
         itemInput,
         addProduct,
         searchProduct,
         cancelProduct,
         selectProduct,
         editProduct,
         showValue,
         setCollapse,
      };
   },
});
