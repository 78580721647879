
import { i18n } from "@/main";
import * as yup from "yup";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { useDataUserAppStore } from "../store/DataUserAppStore";
import { getTokenUserApp } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import { getFormattedTabTitle } from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "DataUserApp",
   components: {
      LoadBar,
      Field,
      ErrorMessage,
      Form,
   },
   setup() {
      const route = useRoute();
      const dataUserAppStore = useDataUserAppStore();
      const { findById, update, resetToInitial } = dataUserAppStore;
      const { getUserApp, getPageParams } = storeToRefs(dataUserAppStore);
      const { typeGrid } = useBreakpointsGrid();
      const uuid = ref();
      const rules = {
         firstName: yup
            .string()
            .required(i18n.global.t("FieldCanNotBeEmpty"))
            .max(25, i18n.global.t("TextMaxLength", ["25"])),
         lastName: yup
            .string()
            .required(i18n.global.t("FieldCanNotBeEmpty"))
            .max(25, i18n.global.t("TextMaxLength", ["25"])),
      };

      onMounted(async () => {
         uuid.value = route.params.id as string;
         if (uuid.value) {
            await findById(route.params.id);
            document.title = getFormattedTabTitle(`${getUserApp.value.firstName} ${getUserApp.value.lastName}`);
         }
      });

      async function submitSave() {
         if (uuid.value) {
            if (uuid.value === getTokenUserApp().uuid) {
               update();
            } else {
               update(uuid.value);
            }
         }
      }

      return {
         getUserApp,
         getPageParams,
         rules,
         typeGrid,
         equalsOrGreaterGrid,
         getTokenUserApp,
         submitSave,
         resetToInitial,
      };
   },
});
