
import SortingPaginationParam from "@/appModules/common/domain/command/SortingPaginationParam";
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
   name: "SortComboBox",
   props: {
      sortOptions: {
         type: Array as PropType<Array<SortingPaginationParam>>,
         required: true,
      },
      defaultSort: {
         type: SortingPaginationParam,
         required: true,
      },
   },
   emits: ["onChangeSort"],
   setup(props, { emit }) {
      const selectedOption = ref(props.defaultSort);

      function handleChangeSort(sortOption: SortingPaginationParam) {
         emit("onChangeSort", sortOption);
      }
      return { selectedOption, handleChangeSort };
   },
});
