
import { defineComponent, ref, watch } from "vue";
import { Field, ErrorMessage, useField } from "vee-validate";
import ItemButtonPanel from "./ItemButtonPanel.vue";
import { vue3Debounce } from "vue-debounce";
import * as yup from "yup";

export default defineComponent({
   name: "ItemInput",
   components: {
      Field,
      ErrorMessage,
      ItemButtonPanel,
   },
   directives: {
      debounce: vue3Debounce({ lock: true }),
   },
   props: {
      modelValue: {
         type: String,
         default: "",
      },
      name: {
         type: String,
         required: true,
      },
      label: {
         type: String,
         default: "",
      },
      placeholder: {
         type: String,
         default: "",
      },
      visibleSearchButton: {
         type: Boolean,
         default: true,
      },
      visibleAddButton: {
         type: Boolean,
         default: false,
      },
      visibleEditButton: {
         type: Boolean,
         default: false,
      },
      visibleCancelButton: {
         type: Boolean,
         default: false,
      },
      isRequiredStyle: {
         type: Boolean,
         default: false,
      },
      disableInput: {
         type: Boolean,
         default: false,
      },
      errors: {
         type: Object,
      },
   },
   emits: ["searchButtonOnClick", "addButtonOnClick", "editButtonOnClick", "cancelButtonOnClick", "showValue", "setCollapse"],
   setup(props, { emit }) {
      const isFocus = ref(false);
      const { resetField } = useField<string>(props.name, yup.string());

      watch(
         () => isFocus.value,
         (newValue) => {
            emit("setCollapse", newValue);
         }
      );

      function searchButtonOnClick() {
         emit("searchButtonOnClick");
      }

      function addButtonOnClick() {
         emit("addButtonOnClick");
      }

      function editButtonOnClick() {
         emit("editButtonOnClick");
      }

      function cancelButtonOnClick() {
         emit("cancelButtonOnClick");
      }

      function showValue(value: string) {
         emit("showValue", value);
      }

      function isErrors(keyParam: string) {
         if (props.errors) {
            return Object.keys(props.errors).includes(keyParam);
         }
         return false;
      }

      return {
         isFocus,
         resetField,
         searchButtonOnClick,
         addButtonOnClick,
         editButtonOnClick,
         cancelButtonOnClick,
         showValue,
         isErrors,
      };
   },
});
