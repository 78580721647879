
import { storeToRefs } from "pinia";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import RentProfileForm from "@/appModules/rent/component/RentProfileForm.vue";
import { useRentStore } from "../store/RentStore";
import { useRoute } from "vue-router";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import { getFormattedTabTitle } from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "Rent",
   components: { LoadBar, RentProfileForm },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   setup() {
      const store = useRentStore();
      const { getPageParams, resetToInitial, findById } = store;
      const { getRent } = storeToRefs(store);
      const uuid = ref();
      const route = useRoute();
      const { typeGrid } = useBreakpointsGrid();

      onMounted(async () => {
         uuid.value = route.params.id as string;
         if (uuid.value) {
            await findById(uuid.value);
            document.title = getFormattedTabTitle(getRent.value.documentBegin.number);
         }
      });

      onUnmounted(() => {
         resetToInitial();
      });

      return {
         uuid,
         getRent,
         getPageParams,
         typeGrid,
         equalsOrGreaterGrid,
         resetToInitial,
      };
   },
});
