
import { defineComponent } from "vue";

export default defineComponent({
   name: "TitlePage",
   components: {},
   props: {
      title: String,
   },
});
