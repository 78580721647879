export interface ISortingPaginationParam {
   key: number;
   name: string;
   sortParam: string;
   sortAscending: boolean;
}

export default class SortingPaginationParam implements ISortingPaginationParam {
   private static _key = 0;
   public key = 0;
   constructor(public name: string = "", public sortParam: string = "", public sortAscending: boolean = true) {
      this.key = SortingPaginationParam._key++;
   }
}
