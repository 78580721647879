import { defineStore } from "pinia";
import { i18n } from "@/main";
import useListParams from "@/appModules/common/domain/composable/useListParams";
import SortingPaginationParam from "@/appModules/common/domain/command/SortingPaginationParam";
import PaginationParams from "@/appModules/common/domain/command/PaginationParam";
import CategoryPaginationDto from "../domain/dto/CategoryPaginationDto";
import CategoryApi from "../domain/repository/CategoryApi";

const api = new CategoryApi();
const params = useListParams();

export const useCategoriesStore = defineStore({
   id: "categoriesStore",
   state: () => ({
      pageDto: new CategoryPaginationDto(),
      sortOptions: [
         new SortingPaginationParam(i18n.global.t("NameFromA"), "name", true),
         new SortingPaginationParam(i18n.global.t("NameFromZ"), "name", false),
      ] as SortingPaginationParam[],
      queryFields: ["name"] as string[],
      query: "" as string,
   }),
   getters: {
      getPageParams: () => params,
      getPageDto: (state) => state.pageDto,
   },
   actions: {
      async fetchPaginationData(pageNumber: number, pageSize: number, searchBy: string | undefined) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         const searchByMap = new Map<string, string>();
         if (this.queryFields && searchBy) {
            this.queryFields.forEach((element) => {
               searchByMap.set(element, searchBy);
            });
         }
         const paginationParam = new PaginationParams(
            pageNumber,
            pageSize,
            params.sort.value ? params.sort.value : this.sortOptions[0],
            searchByMap,
            this.query
         );
         this.query = paginationParam.getQueryFromParams();
         api.findByPagination(this.query)
            .then(
               (success) => {
                  this.pageDto = success.data;
                  params.noDataToDisplay.value = this.pageDto.content.length === 0;
               },
               (error) => {
                  if (error.response && error.response.status == 401) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      resetToInitial() {
         params.created.value = false;
         params.noDataToDisplay.value = false;
         this.pageDto = new CategoryPaginationDto();
      },
   },
});
