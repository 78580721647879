import ProductDto from "@/appModules/product/domain/dto/ProductDto";
import WarehouseDto from "@/appModules/warehouse/domain/dto/WarehouseDto";

export interface IDocumentPosition {
   uuid: string;
   product: ProductDto;
   warehouse: WarehouseDto;
   quantity: number;
   sourceWarehouseUuid: string;
   version: number;
}

export default class DocumentPositionDto implements IDocumentPosition {
   constructor(
      public uuid: string = "",
      public product: ProductDto = new ProductDto(),
      public warehouse: WarehouseDto = new WarehouseDto(),
      public quantity: number = 1,
      public sourceWarehouseUuid: string = "",
      public version: number = 0
   ) {}
}
