
import { i18n } from "@/main";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import WarehouseProfileForm from "@/appModules/warehouse/component/WarehouseProfileForm.vue";
import { useWarehouseStore } from "../store/WarehouseStore";
import { useRoute } from "vue-router";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import { getFormattedTabTitle } from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "Warehouse",
   components: { LoadBar, WarehouseProfileForm },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectWarehouse"],
   setup(props, { emit }) {
      const store = useWarehouseStore();
      const { create, update, getPageParams, resetToInitial, findById } = store;
      const { getWarehouse } = storeToRefs(store);
      const warehouseProfileForm = ref<typeof WarehouseProfileForm>();
      const toast = useToast();
      const uuid = ref();
      const route = useRoute();
      const { typeGrid } = useBreakpointsGrid();

      onMounted(async () => {
         uuid.value = route.params.id as string;
         if (uuid.value) {
            await findById(uuid.value);
            document.title = getFormattedTabTitle(getWarehouse.value.name);
         }
      });

      onUnmounted(() => {
         resetToInitial();
      });

      function showValue(id: string | string[]) {
         uuid.value = id;
         findById(uuid.value);
      }

      async function onValidate() {
         let res = true;

         if (warehouseProfileForm.value) {
            res = await warehouseProfileForm.value.onValidate();
         }

         return res;
      }

      async function submitSave() {
         if (await onValidate()) {
            if (uuid.value) await update();
            else await create();
            if (getWarehouse.value.uuid) {
               emit("selectWarehouse", getWarehouse.value);
            }
         } else toast.error(i18n.global.t("FormHasError"));
      }
      return {
         uuid,
         getWarehouse,
         getPageParams,
         warehouseProfileForm,
         typeGrid,
         showValue,
         equalsOrGreaterGrid,
         submitSave,
         resetToInitial,
      };
   },
});
