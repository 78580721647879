
import { defineComponent, ref } from "vue";

export default defineComponent({
   name: "CookiesAgreementModal",
   emits: ["hideAgreement"],
   setup(props, { emit }) {
      const versionAgreement = ref(2);

      function hideAgreement() {
         emit("hideAgreement");
      }

      return {
         versionAgreement,
         hideAgreement,
      };
   },
});
