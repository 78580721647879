<template>
   <div class="row mt-3">
      <div class="col">
         <h1 style="text-align: center">{{ $t("MobileAppEwypozyczaj") }}</h1>
         <span class="separator"></span>
      </div>
   </div>
   <div class="row m-2">
      <div class="col">
         <p>
            {{ $t("AppStreamlinesTheManagementProcessByUsingSmartphoneAndTablet") }}
         </p>
      </div>
   </div>

   <div class="row mt-3">
      <div class="col">
         <h6 class="text-muted">{{ $t("MostImportantFunctionalities") }}:</h6>
      </div>
   </div>

   <div class="row mt-4">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("QuickSearchWithNFCTags") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col">
         <p>{{ $t("AppUsesTheSmartphoneToReadTheUniqueNFCTagTheTagCanTakeManyForms") }}</p>
         <p>
            <span class="h6">{{ $t("TheUseOfTagsIsOptional") }}</span
            >{{ $t("AppAlsoAllowsAllOperationsWithoutTheNeedToUseTags") }}
         </p>
      </div>
   </div>

   <div class="row m-1">
      <div class="col">
         <h6 class="text-muted">{{ $t("ExamplesOfNFCTags") }}:</h6>
      </div>
   </div>

   <div class="row m-3">
      <div class="col-12 col-md-8 col-lg-9">
         <h6>{{ $t("CardForEmployeesOrCustomers") }}</h6>
         <p>
            {{ $t("PrintableCardFacilitatesTheProcessOfIdentifyingThePerson") }}
         </p>
      </div>
      <div class="col-12 col-md-4 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/nfc_karty_mini.png" :alt="$t('CardForEmployeesOrCustomers')" />
      </div>
   </div>

   <div class="row m-3">
      <div class="col-12 col-md-8 col-lg-9">
         <h6>{{ $t("EquipmentTag") }}</h6>
         {{ $t("TagWithThePossibilityOfMountingOnMetalSurfaces") }}
      </div>
      <div class="col-12 col-md-4 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/nfc_tag_mini.png" :alt="$t('EquipmentTag')" />
      </div>
   </div>

   <div class="row mt-5">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("InformationAboutCurrentStockLevels") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col-12 col-md-8 col-lg-9">
         <p>
            {{ $t("InformationAboutAvailabilityOfEquipmentIsConstantlySentToTheCentralServer") }}
         </p>
      </div>
      <div class="col-12 col-md-4 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/mobile_app_scr_1.jpg" alt="Informacja o aktualnych stanach magazynowych" />
      </div>
   </div>

   <div class="row mt-5">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("WarehouseManagement") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col-12 col-md-8 col-lg-9">
         <p>
            {{
               $t(
                  "EmployeesCanManageEquipmentInformationUsingConvenientFormsAndTheyCanUseTheDevicesCameraToAssignPhotoToTheHardware"
               )
            }}
         </p>
      </div>
      <div class="col-12 col-md-4 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/mobile_app_scr_2.jpg" :alt="$t('WarehouseManagement')" />
      </div>
   </div>

   <div class="row mt-5">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("CalculationOfFeesForCustomers") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col-12 col-md-12 col-lg-6">
         <p>
            {{ $t("AppChargesAnAppropriateFeeForTheRentalTimeAndForExceedingIt") }}
         </p>
      </div>
      <div class="col-12 col-sm-6 col-lg-3 mb-3" style="text-align: center">
         <img src="@/assets/image/about/mobile_app_scr_3.jpg" :alt="$t('CalculationOfFeesForCustomersHandOver')" />
      </div>
      <div class="col-12 col-sm-6 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/mobile_app_scr_4.jpg" :alt="$t('CalculationOfFeesForCustomersReturn')" />
      </div>
   </div>

   <div class="row mt-5">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("AndManyOtherFunctionalities") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col">
         <p>
            {{ $t("WeEncourageYouToDownloadTheAppFromThePlayStore") }}: <u class="text-primary">{{ $t("DownloadeRentalTool") }}</u
            >.
         </p>
      </div>
   </div>
</template>
