import { defineStore } from "pinia";
import { i18n } from "@/main";
import { useToast } from "vue-toastification";
import usePageParams from "@/appModules/common/domain/composable/usePageParams";
import UserAppApi from "../domain/repository/UserAppApi";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { ErrorItemCodeEnum } from "@/appModules/service/domain/enumerated/ErrorItemCodeEnum";
import CreateUserAppDto from "../domain/dto/CreateUserAppDto";
import AddAccessUserAppDto from "../domain/dto/AddAccessUserAppDto";

const api = new UserAppApi();
const toast = useToast();
const params = usePageParams();

export const useCreateUserAppStore = defineStore({
   id: "createUserAppStore",
   state: () => ({
      createUserApp: new CreateUserAppDto(),
      errors: [] as ErrorItem[],
   }),
   getters: {
      getPageParams: () => params,
      getCreateUserApp: (state) => state.createUserApp,
   },
   actions: {
      async create(setFieldError: any, addAccessUserAppDto: AddAccessUserAppDto) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         this.createUserApp.username = addAccessUserAppDto.username;
         this.createUserApp.password = addAccessUserAppDto.password;
         this.createUserApp.confirmPassword = addAccessUserAppDto.confirmPassword;
         this.createUserApp.uuidRoles = addAccessUserAppDto.uuidRoles;

         api.create(this.createUserApp)
            .then(
               (success) => {
                  if (success.status == 201) {
                     params.created.value = true;
                     toast.success(i18n.global.t("EmployeeRegistered"));
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                        this.setErrorMsg(setFieldError);
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },
      resetToInitial() {
         params.created.value = false;
         this.createUserApp = new CreateUserAppDto();
      },
      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === ErrorItemCodeEnum.DuplicateLogin) {
               setFieldError("username", i18n.global.t("ErrorLoginIsAlreadyTaken"));
            }
            if (e.code === ErrorItemCodeEnum.CurrentPasswordInvalid) {
               setFieldError("currentPassword", i18n.global.t("CurrentPasswordInvalid"));
            }
         }
      },
   },
});
