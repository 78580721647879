import TokenUserAppDto from "@/appModules/service/domain/dto/TokenUserAppDto";

const KEY_TOKEN_USER_APP_LOCAL_STORAGE = "authUser";

export const saveTokenUserApp = function (dto: TokenUserAppDto) {
   localStorage.setItem(KEY_TOKEN_USER_APP_LOCAL_STORAGE, JSON.stringify(dto));
};

export const getTokenUserApp = function () {
   return JSON.parse(localStorage.getItem(KEY_TOKEN_USER_APP_LOCAL_STORAGE) as string) as TokenUserAppDto;
};

export const removeTokenUserApp = function () {
   localStorage.removeItem(KEY_TOKEN_USER_APP_LOCAL_STORAGE);
};

export const getAuthHeader = function () {
   const user = getTokenUserApp();
   if (user && user.accessToken) {
      return {
         "Content-type": "application/json",
         Authorization: "Bearer " + user.accessToken,
      };
   }
};
