import CategoryDto from "@/appModules/category/domain/dto/CategoryDto";

export interface IProduct {
   uuid: string;
   name: string;
   description: string;
   totalQuantity: number;
   availableQuantity: number;
   category: CategoryDto;
   version: number;
}

export default class ProductDto implements IProduct {
   constructor(
      public uuid: string = "",
      public name: string = "",
      public description = "",
      public totalQuantity = 0,
      public availableQuantity = 0,
      public category: CategoryDto = new CategoryDto(),
      public version: number = 0
   ) {}
}
