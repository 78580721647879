import PaginationDto from "@/appModules/common/domain/dto/PaginationDto";
import UserApp from "./UserAppDto";

export default class UserAppPaginationDto extends PaginationDto {
   public content: Array<UserApp> = new Array(0);

   constructor() {
      super();
   }
}
