
import { defineComponent, ref } from "vue";
import Warehouse from "@/appModules/warehouse/view/Warehouse.vue";
import WarehouseDto from "@/appModules/warehouse/domain/dto/WarehouseDto";
import { storeToRefs } from "pinia";
import { useWarehouseStore } from "@/appModules/warehouse/store/WarehouseStore";

export default defineComponent({
   name: "WarehouseModal",
   components: {
      Warehouse,
   },
   emits: ["selectWarehouse"],
   setup(props, { emit }) {
      const showModal = ref(false);
      const warehouse = ref<typeof Warehouse>();
      const store = useWarehouseStore();
      const { getWarehouse, getErrors } = storeToRefs(store);

      function openModal(id: string | string[]) {
         showModal.value = true;
         if (id) warehouse.value?.showValue(id);
      }

      function selectWarehouse(warehouse: WarehouseDto) {
         if (getErrors.value.length == 0) {
            showModal.value = false;
            emit("selectWarehouse", warehouse);
         }
      }

      return {
         warehouse,
         showModal,
         getWarehouse,
         openModal,
         selectWarehouse,
      };
   },
});
