import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_2 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentPositionPanel = _resolveComponent("DocumentPositionPanel")!
  const _component_FieldArray = _resolveComponent("FieldArray")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FieldArray, {
      class: "mt-4",
      name: "positions"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document.documentPositions, (position, idx) => {
          return (_openBlock(), _createBlock(_component_DocumentPositionPanel, {
            key: idx + _ctx.index + 1,
            idx: idx,
            position: position,
            errors: _ctx.errors,
            addRemoveBtn: _ctx.document.documentPositions.length > 1,
            isReadOnly: _ctx.document.uuid.length > 0,
            onRemoveDocumentPosition: _ctx.removeDocumentPosition
          }, null, 8, ["idx", "position", "errors", "addRemoveBtn", "isReadOnly", "onRemoveDocumentPosition"]))
        }), 128))
      ]),
      _: 1
    }),
    (!_ctx.document.uuid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "btn btn-outline-primary btn-lg",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addPositionOnButtonClick && _ctx.addPositionOnButtonClick(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "me-1",
                icon: "plus",
                size: "1x"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("AddPosition")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}