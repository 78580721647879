import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12 col-lg-6" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  for: "lastName",
  class: "form-label"
}
const _hoisted_5 = { class: "col-sm-12 col-lg-6 mt-3 mt-lg-0" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  for: "firstName",
  class: "form-label"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-sm-12 col-lg-6 mt-3" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  for: "username",
  class: "form-label"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-sm-12 col-lg-6 mt-3" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = {
  for: "password",
  class: "form-label"
}
const _hoisted_16 = { class: "col-sm-12 col-lg-6 mt-3" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = {
  for: "confirmPassword",
  class: "form-label"
}
const _hoisted_19 = { class: "row form-group" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "text-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("LastName")), 1),
          _createVNode(_component_Field, {
            name: "lastName",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.lastName }]),
            placeholder: _ctx.$t('EnterLastName'),
            modelValue: _ctx.store.serviceRegistrationDto.userApp.lastName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.serviceRegistrationDto.userApp.lastName) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "lastName",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("FirstName")), 1),
          _createVNode(_component_Field, {
            name: "firstName",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.firstName }]),
            placeholder: _ctx.$t('EnterFirstName'),
            modelValue: _ctx.store.serviceRegistrationDto.userApp.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.store.serviceRegistrationDto.userApp.firstName) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "firstName",
            class: "invalid-feedback"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("Email")), 1),
          _createVNode(_component_Field, {
            name: "username",
            type: "email",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.username }]),
            placeholder: _ctx.$t('EnterEmail'),
            modelValue: _ctx.store.serviceRegistrationDto.userApp.username,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.store.serviceRegistrationDto.userApp.username) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "username",
            class: "invalid-feedback"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("Password")), 1),
          _createVNode(_component_Field, {
            name: "password",
            type: "password",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.password }]),
            placeholder: _ctx.$t('EnterPassword'),
            modelValue: _ctx.store.serviceRegistrationDto.userApp.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.store.serviceRegistrationDto.userApp.password) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "password",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("RepeatPassword")), 1),
          _createVNode(_component_Field, {
            name: "confirmPassword",
            type: "password",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.confirmPassword }]),
            placeholder: _ctx.$t('RepeatPassword'),
            modelValue: _ctx.store.serviceRegistrationDto.userApp.confirmPassword,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.store.serviceRegistrationDto.userApp.confirmPassword) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "confirmPassword",
            class: "invalid-feedback"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("i", _hoisted_21, _toDisplayString(_ctx.$t("LongerPasswordMoreSecureUseAtLeast8Characters")), 1)
      ])
    ])
  ], 64))
}