
import { storeToRefs } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { defineComponent, onMounted, ref } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import LoadBar from "../../common/component/LoadBar.vue";
import DeleteItemModal from "../../common/component/DeleteItemModal.vue";
import PaginationPanel from "../../common/component/PaginationPanel.vue";
import SortComboBox from "../../common/component/SortComboBox.vue";
import SortingPaginationParam from "../../common/domain/command/SortingPaginationParam";
import { useCategoriesStore } from "../store/CategoriesStore";
import EnumService from "@/appModules/common/domain/service/EnumService";
import { toStringEnum } from "@/appModules/common/domain/service/format";
import { useCategoryStore } from "../store/CategoryStore";
import CategoryDto from "../domain/dto/CategoryDto";
import CategoryListItem from "../component/CategoryListItem.vue";
import PaginationService from "../../common/domain/service/PaginationService";

export default defineComponent({
   name: "Categories",
   components: {
      LoadBar,
      PaginationPanel,
      SortComboBox,
      DeleteItemModal,
      CategoryListItem,
   },
   directives: {
      debounce: vue3Debounce({ lock: true }),
   },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectCategory"],
   setup(props, { emit }) {
      const categoriesStore = useCategoriesStore();
      const categoryStore = useCategoryStore();
      const { getPageParams, getPageDto, sortOptions } = storeToRefs(categoriesStore);
      const { fetchPaginationData } = categoriesStore;
      const { typeGrid } = useBreakpointsGrid();
      const defaultSort = sortOptions.value[0];
      const searchBy = ref("");
      const selectedCategory = ref(new CategoryDto());

      onMounted(() => {
         getPageParams.value.sort.value = defaultSort;
         if (!props.isModal) showValue();
      });

      function sortData(sortOption: SortingPaginationParam) {
         getPageParams.value.sort.value = sortOption;
         showValue();
      }

      function changePagination(page: number, size: number) {
         fetchPaginationData(page, size, searchBy.value);
      }

      function showValue() {
         fetchPaginationData(getPageDto.value.number, getPageDto.value.size, searchBy.value);
      }

      async function deleteCategory(uuid: string | string[]) {
         await categoryStore.delete(uuid);
         fetchPaginationData(new PaginationService().calcPageNumber(getPageDto.value), getPageDto.value.size, searchBy.value);
      }

      function selectCategory(category: CategoryDto) {
         selectedCategory.value = category;
         emit("selectCategory", category);
      }

      return {
         selectedCategory,
         getPageDto,
         getPageParams,
         typeGrid,
         sortOptions,
         defaultSort,
         searchBy,
         EnumService,
         useBreakpointsGrid,
         equalsOrGreaterGrid,
         changePagination,
         sortData,
         showValue,
         toStringEnum,
         deleteCategory,
         selectCategory,
      };
   },
});
