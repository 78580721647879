import AddressDto from "@/appModules/service/domain/dto/AddressDto";
import { WarehouseTypeEnum } from "../enumerated/WarehouseTypeEnum";

export interface IWarehouse {
   uuid: string;
   name: string;
   type: WarehouseTypeEnum;
   addressOtherThanMain: boolean;
   address: AddressDto | undefined;
   version: number;
}

export default class WarehouseDto implements IWarehouse {
   constructor(
      public uuid: string = "",
      public name: string = "",
      public type: WarehouseTypeEnum = WarehouseTypeEnum.OperationalProducts,
      public addressOtherThanMain: boolean = false,
      public address: AddressDto | undefined = new AddressDto(),
      public version: number = 0
   ) {}
}
