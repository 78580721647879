
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import TitlePage from "@/appModules/common/component/TitlePage.vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import { useReCaptcha } from "vue-recaptcha-v3";
import UserAuthDto from "@/appModules/service/domain/dto/UserAuthDto";
import { useLoginStore } from "@/appModules/service/store/LoginStore";
import { storeToRefs } from "pinia";

export default defineComponent({
   name: "Login",
   components: {
      TitlePage,
      LoadBar,
      Field,
      ErrorMessage,
   },
   setup() {
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            email: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .email(i18n.global.t("IncorrectFormatEmail"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            password: yup.string().required(i18n.global.t("FieldCanNotBeEmpty")),
         }),
      });

      const success = ref(0);
      const client = ref({
         login: "",
         password: "",
      });
      const errorText = ref("");
      const useRecaptcha = useReCaptcha();
      const recaptchaBadge = useReCaptcha()?.instance;
      const store = useLoginStore();
      const { logInUser } = store;
      const { getParams } = storeToRefs(store);

      onMounted(() => {
         setTimeout(() => {
            recaptchaBadge?.value?.showBadge();
         }, 500);
      });

      onUnmounted(() => {
         recaptchaBadge?.value?.hideBadge();
      });

      async function onValidate() {
         let rez = true;
         const { valid } = await validate();

         rez = valid;
         return rez;
      }

      // function showMessage(messageType: number) {
      //    // 0 - blad polaczenia do serwera - nieznany blad
      //    // 1 - niepoprawny login lub haslo
      //    if (messageType === 1) {
      //       errorText.value = "Niepoprawny login lub hasło";
      //    } else {
      //       errorText.value = "Problem z logowaniem, prosze spróbować później";
      //    }
      // }
      async function submitLogIn() {
         if (await onValidate()) {
            await useRecaptcha?.recaptchaLoaded();
            let token = await useRecaptcha?.executeRecaptcha("login");
            logInUser(new UserAuthDto(client.value.login, client.value.password, token));
         }
      }
      return {
         errors,
         success,
         client,
         errorText,
         getParams,
         submitLogIn,
         onValidate,
      };
   },
});
