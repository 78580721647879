import { computed, onMounted, onUnmounted, ref } from "vue";

const typeGrids = ["xs", "sm", "md", "lg", "xl", "xxl"];

export default function () {
   const windowWidth = ref(window.innerWidth);

   const onWidthChange = () => (windowWidth.value = window.innerWidth);
   onMounted(() => window.addEventListener("resize", onWidthChange));
   onUnmounted(() => window.removeEventListener("resize", onWidthChange));

   const typeGrid = computed(() => {
      if (windowWidth.value < 576) return typeGrids[0];
      if (windowWidth.value >= 576 && windowWidth.value < 768) return typeGrids[1];
      if (windowWidth.value >= 768 && windowWidth.value < 992) return typeGrids[2];
      if (windowWidth.value >= 992 && windowWidth.value < 1200) return typeGrids[3];
      if (windowWidth.value >= 1200 && windowWidth.value < 1400) return typeGrids[4];
      if (windowWidth.value >= 1400) return typeGrids[5];
      return null; // This is an unreachable line, simply to keep eslint happy.
   });

   const widthGrid = computed(() => windowWidth.value);

   return { widthGrid, typeGrid };
}

export function equalsOrGreaterGrid(v1: string, v2: string | null) {
   if (v1 && v2) {
      const i1 = typeGrids.indexOf(v1);
      const i2 = typeGrids.indexOf(v2);
      if (i1 > -1 && i2 > -1) {
         return i2 >= i1;
      }
   }
   return false;
}

export function equalsOrLessGrid(v1: string, v2: string | null) {
   if (v1 && v2) {
      const i1 = typeGrids.indexOf(v1);
      const i2 = typeGrids.indexOf(v2);
      if (i1 > -1 && i2 > -1) {
         return i2 <= i1;
      }
   }
   return false;
}

export function lessGrid(v1: string, v2: string | null) {
   if (v1 && v2) {
      const i1 = typeGrids.indexOf(v1);
      const i2 = typeGrids.indexOf(v2);
      if (i1 > -1 && i2 > -1) {
         return i2 < i1;
      }
   }
   return false;
}

export function greaterGrid(v1: string, v2: string | null) {
   if (v1 && v2) {
      const i1 = typeGrids.indexOf(v1);
      const i2 = typeGrids.indexOf(v2);
      if (i1 > -1 && i2 > -1) {
         return i2 > i1;
      }
   }
   return false;
}
