
import { defineComponent, ref, PropType } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "@/plugins/window-width";

export default defineComponent({
   name: "PaginationPanel",
   props: {
      options: {
         type: Array as PropType<Array<number>>,
         default: () => [1, 5, 10, 25, 50, 100],
      },
      pageSize: {
         type: Number,
         required: true,
      },
      totalPages: {
         type: Number,
         required: true,
      },
      pageNumber: {
         type: Number,
         required: true,
      },
      totalElements: {
         type: Number,
         required: true,
      },
      isTheFirstPage: {
         type: Boolean,
         required: true,
      },
      isTheLastPage: {
         type: Boolean,
         required: true,
      },
   },
   emits: ["onChangePagination"],
   setup(props, { emit }) {
      const index = ref(props.pageNumber);
      const pageSizeRef = ref(props.pageSize);
      const { typeGrid } = useBreakpointsGrid();

      function onNextPageClick() {
         if (index.value >= 0) {
            index.value++;
         }
         emit("onChangePagination", index.value, props.pageSize);
      }
      function onBackPageClick() {
         if (index.value >= 0) {
            index.value--;
         }
         emit("onChangePagination", index.value, props.pageSize);
      }
      function onFirstPageClick() {
         index.value = 0;
         emit("onChangePagination", index.value, props.pageSize);
      }
      function onLastPageClick() {
         index.value = props.totalPages - 1;
         emit("onChangePagination", index.value, props.pageSize);
      }
      function isPageActive(page: number) {
         return index.value === page - 1;
      }
      function onPageClick(page: number) {
         index.value = page - 1;
         emit("onChangePagination", index.value, props.pageSize);
      }
      function onPageSizeChange(pageSize: number) {
         if (props.totalElements / pageSize < index.value) {
            index.value = 0;
         }
         emit("onChangePagination", index.value, pageSize);
      }

      return {
         typeGrid,
         pageSizeRef,
         isPageActive,
         onNextPageClick,
         onBackPageClick,
         onFirstPageClick,
         onLastPageClick,
         onPageClick,
         onPageSizeChange,
         equalsOrGreaterGrid,
      };
   },
});
