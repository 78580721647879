
import { defineComponent, onUnmounted, ref } from "vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import { storeToRefs } from "pinia";
import { useLoginStore } from "../store/LoginStore";
import ResetPasswordClientStepOneDto from "../domain/dto/ResetPasswordClientStepOneDto";
import { useReCaptcha } from "vue-recaptcha-v3";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
   name: "ResetPasswordStep1",
   components: {
      LoadBar,
      Field,
      ErrorMessage,
   },
   setup() {
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            email: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .email(i18n.global.t("IncorrectFormatEmail"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
         }),
      });
      const errorText = ref("");
      const successText = ref("");
      const email = ref("");
      const store = useLoginStore();
      const { resetPassStep1, resetSuccessToInitial } = store;
      const { isResetSuccess, getParams } = storeToRefs(store);
      const useRecaptcha = useReCaptcha();

      onUnmounted(() => {
         resetSuccessToInitial();
      });

      function showMessage(messageType: number) {
         // 0 - blad polaczenia do serwera - nieznany blad
         // 1 - niepoprawny login lub haslo
         if (messageType === 1) {
            errorText.value = "Niepoprawny adres email";
         } else {
            errorText.value = "Problem z resetowaniem hasła, proszę spróbować później";
         }
      }
      async function onValidate() {
         let rez = true;
         const { valid } = await validate();

         rez = valid;
         return rez;
      }
      async function submitResetPass() {
         successText.value = "";
         errorText.value = "";

         if (await onValidate()) {
            await useRecaptcha?.recaptchaLoaded();
            let recaptcha = await useRecaptcha?.executeRecaptcha("resetPass");
            resetPassStep1(new ResetPasswordClientStepOneDto(email.value, recaptcha));
         }
      }

      return {
         errors,
         getParams,
         isResetSuccess,
         errorText,
         email,
         submitResetPass,
         onValidate,
      };
   },
});
