const MASK_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/;
const MASK_POSTALCODE = /^[0-9]{2}-[0-9]{3}/;
const MASK_PHONENUMBER = /^[+]?[(]?[0-9]{2,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export const getPasswordMask = function () {
   return MASK_PASSWORD;
};

export const getPostalCodeMask = function () {
   return MASK_POSTALCODE;
};

export const getPhoneNumberMask = function () {
   return MASK_PHONENUMBER;
};
