
import { useRoute } from "vue-router";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
   name: "Menu",
   components: {},
   setup() {
      const isActive = ref(false);
      const route = useRoute();

      const isLoginPage = computed(() => {
         return route.name == "login";
      });

      function ShowMenu() {
         isActive.value = !isActive.value;
      }

      function toggleMenu() {
         isActive.value = !isActive.value;
      }

      return { ShowMenu, toggleMenu, isActive, isLoginPage };
   },
});
