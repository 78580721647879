
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import useBreakpointsGrid, { greaterGrid } from "@/plugins/window-width";
import { i18n } from "@/main";
import moment from "moment";
import { useToast } from "vue-toastification";
import { getTokenUserApp, saveTokenUserApp } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import ServiceApi from "@/appModules/service/domain/repository/ServiceApi";
import DateTimeService from "@/appModules/common/domain/service/DateTimeService";
import TokenUserAppDto from "../domain/dto/TokenUserAppDto";
import { useLoginStore } from "../store/LoginStore";
import { RefreshTokenUserAppDto } from "../domain/dto/RefreshTokenUserAppDto";

export default defineComponent({
   name: "UserAppSessionTimerPanel",
   setup() {
      const timerInstance = ref();
      const totalSeconds = ref(0);
      const { typeGrid } = useBreakpointsGrid();
      const isRunning = ref(false);
      const userToken = ref(new TokenUserAppDto());
      const toast = useToast();
      const lastClickDate = ref();
      const dateTimeService = new DateTimeService();
      const loginStore = useLoginStore();
      const serviceApi = new ServiceApi();

      onUnmounted(() => {
         stop();
      });

      onMounted(() => {
         start();
      });

      function onClickReset() {
         refreshToken();
      }

      function logoutTokenExpired() {
         loginStore.logOutUser();
         toast.error(i18n.global.t("TokenExpiredToast"));
      }

      function clickToGetTime() {
         document.addEventListener("mousedown", () => {
            lastClickDate.value = moment().format("DD HH:mm:ss");
         });
      }

      function checkTimeBeforeRefreshToken() {
         userToken.value = getTokenUserApp();
         if (userToken.value === null) {
            return logoutTokenExpired();
         }
         let diffTokenAndCurrentDate = dateTimeService.getRemainingSeconds(
            userToken.value.expirationDateAccessToken,
            "YYYY-MM-DDTHH:mm:ss"
         );

         if (diffTokenAndCurrentDate < userToken.value.expirationSecondsAccessToken / 2) {
            clickToGetTime();
            if (lastClickDate.value != undefined) {
               let diffClickAndCurrentTime = Math.abs(dateTimeService.getRemainingSeconds(lastClickDate.value, "DD HH:mm:ss"));
               if (diffClickAndCurrentTime < 10) {
                  refreshToken();
               }
            }
         }
      }

      function refreshToken() {
         const refreshTokenUserAppDto = new RefreshTokenUserAppDto(userToken.value.refreshToken);
         serviceApi.refreshToken(refreshTokenUserAppDto).then(
            (success) => {
               if (success.data) {
                  userToken.value = success.data;
                  saveTokenUserApp(userToken.value);
               }
            },
            (error) => {
               logoutTokenExpired();
            }
         );
      }

      function start() {
         if (!isRunning.value) {
            timerInstance.value = setInterval(() => {
               userToken.value = getTokenUserApp();

               if (userToken.value === null) {
                  return logoutTokenExpired();
               }
               totalSeconds.value = dateTimeService.getRemainingSeconds(
                  userToken.value.expirationDateAccessToken.substring(0, 19),
                  "YYYY-MM-DDTHH:mm:ss"
               );

               if (totalSeconds.value <= 0) {
                  return logoutTokenExpired();
               }

               checkTimeBeforeRefreshToken();
               totalSeconds.value -= 1;
            }, 1000);
            isRunning.value = true;
         }
      }

      function stop() {
         clearInterval(timerInstance.value);
      }

      const displayHours = computed(() => {
         var hours = Math.floor(totalSeconds.value / 3600);
         return dateTimeService.formatTimeWithLeadingZero(hours);
      });

      const displayMinutes = computed(() => {
         var minutes = Math.floor((totalSeconds.value % 3600) / 60);
         return dateTimeService.formatTimeWithLeadingZero(minutes).slice(0, 2);
      });

      const displaySeconds = computed(() => {
         const seconds = (totalSeconds.value % 3600) % 60;
         return dateTimeService.formatTimeWithLeadingZero(seconds).slice(0, 2);
      });

      return { greaterGrid, displayHours, displayMinutes, displaySeconds, typeGrid, totalSeconds, onClickReset };
   },
});
