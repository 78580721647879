
import { defineComponent, onMounted, onUnmounted } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import { useContactFormStore } from "../store/ContactFormStore";
import { useReCaptcha } from "vue-recaptcha-v3";
import { getPhoneNumberMask } from "@/appModules/common/domain/command/MaskCommon";
import { storeToRefs } from "pinia";
import LoadBar from "@/appModules/common/component/LoadBar.vue";

export default defineComponent({
   name: "ContactForm",
   components: { Field, ErrorMessage, LoadBar },
   setup() {
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            subject: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            nameLastName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            phone: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(12, i18n.global.t("TextMaxLength", ["12"]))
               .matches(getPhoneNumberMask(), i18n.global.t("IncorrectPhoneNumberWithMask"))
               .test("testPhoneNumber", i18n.global.t("IncorrectPhoneNumberWithMask"), function (value) {
                  if (value?.length != 12 && value?.length != 9) {
                     return false;
                  }
                  if (value.length === 12) {
                     return value.substring(0, 3) === "+48";
                  }
                  if (value.length === 9) {
                     return value.substring(0, 3) !== "+48";
                  }
                  return false;
               }),
            email: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .email(i18n.global.t("IncorrectFormatEmail"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            contents: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(1024, i18n.global.t("TextMaxLength", ["1024"])),
            agreement: yup.bool().test("testAgreementCheckbox", i18n.global.t("AcceptTermsAndConditions"), function (value) {
               if (value === false) {
                  return true;
               }
               return false;
            }),
         }),
      });
      const store = useContactFormStore();
      const { isLoading, isError, isNoDataToDisplay, getMsgSent } = storeToRefs(store);
      const { sendMsg } = store;
      const useRecaptcha = useReCaptcha();
      const recaptchaBadge = useReCaptcha()?.instance;

      onMounted(() => {
         setTimeout(() => {
            recaptchaBadge?.value?.showBadge();
         }, 500);
      });

      onUnmounted(() => {
         recaptchaBadge?.value?.hideBadge();
      });

      async function onValidate() {
         let rez = true;
         const { valid, errors } = await validate();
         rez = valid;
         return rez;
      }

      async function submitSend() {
         await useRecaptcha?.recaptchaLoaded();
         let token = await useRecaptcha?.executeRecaptcha("login");

         sendMsg();
      }
      // function showMessage(messageType: number) {
      //    // 0 - formulaz zawiera niepoprawne dane
      //    if (messageType === 1) {
      //       successText.value = "Wiadomość wysłana.";
      //    } else {
      //       errorText.value = "Problem z wysłanem wiadomości, proszę spróbować później.";
      //    }
      // }

      return {
         store,
         errors,
         isLoading,
         isError,
         isNoDataToDisplay,
         getMsgSent,
         onValidate,
         submitSend,
      };
   },
});
