export interface IUserAuth {
   username: string;
   password: string;
   recaptchaTokenKey: string | undefined;
}

export default class UserAuthDto implements IUserAuth {
   constructor(
      public username: string = "",
      public password: string = "",
      public recaptchaTokenKey: string | undefined = undefined
   ) {}
}
