import { ref } from "vue";

export default function usePageParams() {
   const noDataToDisplay = ref(false);
   const noAccessToDisplay = ref(false);
   const loadingData = ref(false);
   const errorLoadData = ref(false);
   const tokenExpired = ref(false);
   const created = ref(false);

   return {
      noDataToDisplay,
      noAccessToDisplay,
      loadingData,
      errorLoadData,
      tokenExpired,
      created,
   };
}
