import { getAuthHeader } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import axios from "axios";
import ProductPaginationDto from "../dto/ProductPaginationDto";
import ProductDto from "../dto/ProductDto";
import CreateProductDto from "../dto/CreateProductDto";
import UpdateProductDto from "../dto/UpdateProductDto";

const urlApi = "/api/products/v1/";

export default class ProductApi {
   findById(uuid: string | string[]) {
      return axios.get<ProductDto>(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   delete(uuid: string | string[]) {
      return axios.delete(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   create(dto: CreateProductDto) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi, dto, {
         headers: getAuthHeader(),
      });
   }

   findByPagination(query: string) {
      ProductPaginationDto;
      return axios.get<ProductPaginationDto>(process.env.VUE_APP_API_DOMAIN + urlApi + query, {
         headers: getAuthHeader(),
      });
   }

   update(dto: UpdateProductDto, uuid: string | string[]) {
      return axios.put(process.env.VUE_APP_API_DOMAIN + urlApi + `${uuid ? uuid : ""}`, dto, {
         headers: getAuthHeader(),
      });
   }
}
