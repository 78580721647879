import { RoleEnum } from "@/appModules/userApp/domain/enumerated/RoleEnum";

export interface ITokenUserApp {
   uuid: string;
   username: string;
   accessToken: string;
   refreshToken: string;
   type: string;
}

export class TokenUserAppDto implements ITokenUserApp {
   constructor(
      public uuid: string = "",
      public username: string = "",
      public accessToken: string = "",
      public refreshToken: string = "",
      public roles: Array<RoleEnum> = new Array<RoleEnum>(),
      public expirationDateAccessToken: string = "",
      public expirationSecondsAccessToken: number = 0,
      public type: string = ""
   ) {}
}

export default class TokenUserApp extends TokenUserAppDto {
   constructor(dto?: TokenUserAppDto) {
      super();
      if (!dto) dto = new TokenUserAppDto();

      this.uuid = dto.uuid;
      this.username = dto.username;
      this.accessToken = dto.accessToken;
      this.refreshToken = dto.refreshToken;
      this.roles = dto.roles;
      this.expirationDateAccessToken = dto.expirationDateAccessToken;
      this.expirationSecondsAccessToken = dto.expirationSecondsAccessToken;
      this.type = dto.type;
   }

   // get roleEnum(): RoleEnum {
   //    return this.roles as RoleEnum;
   // }
}
