import usePageParams from "@/appModules/common/domain/composable/usePageParams";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { i18n } from "@/main";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import ProductDto from "../domain/dto/ProductDto";
import { ProductErrorItemCodeEnum } from "@/appModules/product/domain/enumerated/ProductErrorItemCodeEnum";
import ProductApi from "../domain/repository/ProductApi";
import CreateProductDto from "../domain/dto/CreateProductDto";
import UpdateProductDto from "../domain/dto/UpdateProductDto";

const api = new ProductApi();
const toast = useToast();
const params = usePageParams();

export const useProductStore = defineStore({
   id: "productStore",
   state: () => ({
      productDto: new ProductDto(),
      errors: [] as ErrorItem[],
   }),
   getters: {
      getPageParams: () => params,
      getProduct: (state) => state.productDto,
      getErrors: (state) => state.errors,
   },
   actions: {
      async findById(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .findById(id)
            .then(
               (success) => {
                  this.productDto = success.data;
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async delete(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .delete(id)
            .then(
               (success) => {
                  if (success.status == 200) {
                     toast.success(i18n.global.t("ProductDeleted"));
                  }
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async create() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         const createProductDto = new CreateProductDto(
            this.productDto.name,
            this.productDto.description,
            this.productDto.category.uuid
         );
         return api
            .create(createProductDto)
            .then(
               (success) => {
                  if (success.status == 201) {
                     this.productDto = success.data;
                     params.created.value = true;
                     this.errors = [];
                     toast.success(i18n.global.t("NewProductAdded"));
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      async update() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         const updateProductDto = new UpdateProductDto(
            this.productDto.name,
            this.productDto.description,
            this.productDto.category.uuid,
            this.productDto.version
         );

         return api
            .update(updateProductDto, this.productDto.uuid)
            .then(
               (success) => {
                  if (success.status == 200) {
                     this.productDto = success.data;
                     toast.success(i18n.global.t("ProductUpdated"));
                     this.errors = [];
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      resetToInitial() {
         params.created.value = false;
         params.noDataToDisplay.value = false;
         this.productDto = new ProductDto();
         this.errors = [];
      },

      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === ProductErrorItemCodeEnum.DuplicateName) {
               setFieldError("productName", i18n.global.t("ErrorProductNameExists"));
            }
            if (e.code === ProductErrorItemCodeEnum.DuplicateName2) {
               setFieldError("productName", i18n.global.t("ErrorProductNameExists2"));
            }
         }
      },

      setProduct(product: ProductDto) {
         this.productDto = product;
      },
   },
});
