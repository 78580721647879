
import { defineComponent, PropType, ref } from "vue";
import Users from "@/appModules/userApp/view/Users.vue";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";

export default defineComponent({
   name: "UsersModal",
   components: {
      Users,
   },
   props: {
      userApp: {
         type: Object as PropType<UserAppDto>,
         required: true,
      },
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectUserApp"],
   setup(props, { emit }) {
      const showModal = ref(false);
      const users = ref<typeof Users>();

      function openModal() {
         users.value?.showValue();
         showModal.value = true;
      }

      function selectUserApp(userApp: UserAppDto) {
         showModal.value = false;
         emit("selectUserApp", userApp);
      }

      return {
         users,
         showModal,
         openModal,
         selectUserApp,
      };
   },
});
