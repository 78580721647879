
import { defineComponent, onMounted, ref } from "vue";
import TitlePage from "@/appModules/common/component/TitlePage.vue";
export default defineComponent({
   name: "Agreement",
   components: { TitlePage },
   setup() {
      const authUser = ref();
      onMounted(() => {
         authUser.value = JSON.parse(localStorage.getItem("authUser") as string);
      });
      return { authUser };
   },
});
