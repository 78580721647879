<template>
   <div class="row">
      <div class="col">
         <h1 style="text-align: center">{{ $t("WebAppEwypozyczaj") }}</h1>
         <span class="separator"></span>
      </div>
   </div>
   <div class="row m-2">
      <div class="col">
         <p>{{ $t("ItAllowsYouToManageRentalPointsCustomersCanCheckTheAvailabilityOfEquipment") }}</p>
      </div>
   </div>

   <div class="row mt-3">
      <div class="col">
         <h6 class="text-muted">{{ $t("MostImportantFunctionalities") }}:</h6>
      </div>
   </div>

   <div class="row mt-4">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("ReportAndLists") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col-12 col-md-8 col-lg-9">
         <p>
            {{ $t("ReportsAndListsOfRentalsAndReturnsWithSummary") }}
         </p>
      </div>
      <div class="col-12 col-md-4 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/web_app_scr_1.jpg" :alt="$t('ReportAndLists')" />
      </div>
   </div>

   <div class="row mt-4">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("RentalHistory") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col-12 col-md-8 col-lg-9">
         <p>
            {{ $t("ViewDetailsOfRentalsAndReturnsPossibilityToSearchByEmployeeCustomerAndEquipment") }}
         </p>
      </div>
      <div class="col-12 col-md-4 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/web_app_scr_2.jpg" :alt="$t('RentalHistory')" />
      </div>
   </div>

   <div class="row mt-4">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("CentralConfiguration") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col-12 col-md-8 col-lg-9">
         <p>{{ $t("CentralConfigurationOfSystemOperationSettings") }}</p>
      </div>
      <div class="col-12 col-md-4 col-lg-3" style="text-align: center">
         <img src="@/assets/image/about/web_app_scr_3.jpg" :alt="$t('CentralConfiguration')" />
      </div>
   </div>

   <div class="row mt-5">
      <div class="col">
         <h4>
            <span class="text-success"><b>></b></span> {{ $t("AndManyOtherFunctionalities") }}
         </h4>
      </div>
   </div>
   <div class="row m-2">
      <div class="col">
         <p>
            {{ $t("WeEncourageYou") }}
            <router-link class="card-link" :to="{ name: 'registration' }"
               ><u>{{ $t("ToRegister") }}</u></router-link
            >.
         </p>
      </div>
   </div>
</template>
