import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vShow as _vShow, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "history-bar" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_4 = { class: "breadcrumb" }
const _hoisted_5 = { class: "breadcrumb-item" }
const _hoisted_6 = { class: "breadcrumb-item" }
const _hoisted_7 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_8 = { class: "container mt-3 d-flex flex-column justify-content-center" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "position-absolute top-50 start-50 translate-middle"
}
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "row text-center" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "display-5 text-success" }
const _hoisted_15 = { class: "row mt-3 mt-md-3 text-center" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "text-secondary" }
const _hoisted_18 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_21 = { class: "col" }
const _hoisted_22 = {
  key: 1,
  class: "row"
}
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "row form-group mt-3" }
const _hoisted_25 = { class: "col-sm-12 col-lg-8" }
const _hoisted_26 = {
  for: "lastName",
  class: "form-label"
}
const _hoisted_27 = { class: "row form-group mt-3" }
const _hoisted_28 = { class: "col-sm-12 col-lg-8" }
const _hoisted_29 = {
  for: "firstName",
  class: "form-label"
}
const _hoisted_30 = { class: "row form-check mt-3" }
const _hoisted_31 = { class: "col-sm-12 col-lg-8" }
const _hoisted_32 = {
  class: "form-check-label",
  for: "visibleInput"
}
const _hoisted_33 = { class: "col-12 mt-4 text-center" }
const _hoisted_34 = { class: "col-6 col-sm-4 d-grid gap-2 mx-auto mt-1 mb-5" }
const _hoisted_35 = {
  type: "submit",
  class: "btn btn-success btn-lg d-flex align-items-center justify-content-center"
}
const _hoisted_36 = { class: "ms-2 ms-sm-3 d-md-inline d-lg-inline d-xl-inline d-xxl-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_UserAppAccessForm = _resolveComponent("UserAppAccessForm")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("nav", _hoisted_3, [
          _createElementVNode("ol", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, { to: { name: 'panelSettings' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Settings")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, { to: { name: 'panelUsers' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Employees")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t("NewClientProfile")), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        noDataToDisplay: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "noDataToDisplay"]),
      (!_ctx.getPageParams.noDataToDisplay.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_9, [
            (_ctx.getPageParams.created.value)
              ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("h1", _hoisted_14, _toDisplayString(_ctx.$t("NewEmployeeAdded")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("DoYouWantNow")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("button", {
                          class: "btn btn-outline-success btn-lg",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetToInitial()))
                        }, _toDisplayString(_ctx.$t("AddAnotherNewEmployee")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_router_link, {
                          class: "btn btn-outline-primary btn-lg text-center",
                          to: { name: 'panelUsers' }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("ShowEmployeeList")), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _withDirectives(_createElementVNode("div", _hoisted_23, [
                    _createElementVNode("form", {
                      onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.submitSave()), ["prevent"]))
                    }, [
                      _createElementVNode("form", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("LastName")), 1),
                          _createVNode(_component_Field, {
                            id: "lastName",
                            name: "lastName",
                            type: "text",
                            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.lastName }]),
                            placeholder: _ctx.$t('EnterLastName'),
                            modelValue: _ctx.getCreateUserApp.lastName,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getCreateUserApp.lastName) = $event))
                          }, null, 8, ["class", "placeholder", "modelValue"]),
                          _createVNode(_component_ErrorMessage, {
                            name: "lastName",
                            class: "invalid-feedback"
                          })
                        ])
                      ]),
                      _createElementVNode("form", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("FirstName")), 1),
                          _createVNode(_component_Field, {
                            id: "firstName",
                            name: "firstName",
                            type: "text",
                            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.firstName }]),
                            placeholder: _ctx.$t('EnterFirstName'),
                            modelValue: _ctx.getCreateUserApp.firstName,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getCreateUserApp.firstName) = $event))
                          }, null, 8, ["class", "placeholder", "modelValue"]),
                          _createVNode(_component_ErrorMessage, {
                            name: "firstName",
                            class: "invalid-feedback"
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "checkbox",
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getCreateUserApp.account) = $event)),
                            id: "visibleInput"
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.getCreateUserApp.account]
                          ]),
                          _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t("AccessToTheSite")), 1)
                        ])
                      ]),
                      _withDirectives(_createElementVNode("div", null, [
                        _createVNode(_component_UserAppAccessForm, { ref: "userAppAccessForm" }, null, 512)
                      ], 512), [
                        [_vShow, _ctx.getCreateUserApp.account]
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("button", _hoisted_35, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "save",
                              size: "1x"
                            }),
                            _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("Save")), 1)
                          ])
                        ])
                      ])
                    ], 32)
                  ], 512), [
                    [_vShow, !_ctx.getPageParams.loadingData.value]
                  ])
                ]))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}