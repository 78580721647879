import { getAuthHeader } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import axios from "axios";
import CategoryDto from "../dto/CategoryDto";
import CategoryPaginationDto from "../dto/CategoryPaginationDto";

const urlApi = "/api/categories/v1/";

export default class CategoryApi {
   findById(uuid: string | string[]) {
      return axios.get<CategoryDto>(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   delete(uuid: string | string[]) {
      return axios.delete(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   create(name: string) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi, name, {
         headers: getAuthHeader(),
      });
   }

   findByPagination(query: string) {
      return axios.get<CategoryPaginationDto>(process.env.VUE_APP_API_DOMAIN + urlApi + query, {
         headers: getAuthHeader(),
      });
   }

   update(dto: CategoryDto) {
      return axios.put(process.env.VUE_APP_API_DOMAIN + urlApi + `${dto.uuid ? dto.uuid : ""}`, dto, {
         headers: getAuthHeader(),
      });
   }
}
