
import { PropType, defineComponent, onMounted, ref, watchEffect } from "vue";
import ItemInput from "../../common/component/ItemInput.vue";
import UsersModal from "@/appModules/userApp/view/UsersModal.vue";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";
import { useUsersStore } from "@/appModules/userApp/store/UsersStore";
import ItemInputCollapseResults from "../../common/component/ItemInputCollapseResults.vue";
import { storeToRefs } from "pinia";

export default defineComponent({
   name: "UserAppFilterItemPanel",
   components: {
      ItemInput,
      UsersModal,
      ItemInputCollapseResults,
   },
   props: {
      name: {
         type: String,
         required: true,
      },
      label: {
         type: String,
      },
      placeholder: {
         type: String,
      },
      isRequiredStyle: {
         type: Boolean,
         default: false,
      },
      userAppExists: {
         type: Boolean,
         required: true,
      },
      errors: {
         type: Object,
      },
      userApp: {
         type: Object as PropType<UserAppDto>,
         required: true,
      },
      isReadOnly: {
         type: Boolean,
         required: true,
      },
   },
   emits: ["selectUserApp", "cancelUserApp"],
   setup(props, { emit }) {
      const usersModal = ref<typeof UsersModal>();
      const itemInput = ref<typeof ItemInput>();
      const usersStore = useUsersStore();
      const { fetchPaginationData } = usersStore;
      const { getPageDto, getPageParams } = storeToRefs(usersStore);
      const collapse = ref(false);
      const maxResultsNumber = 10;
      const searchByLength = ref(0);
      const userAppName = ref("");

      watchEffect(
         () => (userAppName.value = props.userApp.lastName ? `${props.userApp.firstName} ${props.userApp.lastName}` : "")
      );

      onMounted(() => {
         fetchPaginationData(0, maxResultsNumber, "");
      });

      function searchUserApp() {
         usersModal.value?.openModal();
      }

      function cancelUserApp() {
         emit("cancelUserApp");
         userAppName.value = "";
      }

      function selectUserApp(userApp: UserAppDto) {
         userAppName.value = userApp.lastName ? `${userApp.firstName} ${userApp.lastName}` : "";
         collapse.value = false;
         emit("selectUserApp", userApp);
      }

      async function showValue(searchBy: string) {
         searchByLength.value = searchBy.length;
         if (searchByLength.value >= 3) {
            fetchPaginationData(0, maxResultsNumber, searchBy);
         }
      }

      function setCollapse(isFocus: boolean) {
         collapse.value = isFocus;
         if (!isFocus) {
            searchByLength.value = 0;
            if (props.userApp?.uuid.length == 0) {
               itemInput.value?.resetField();
            }
         }
      }

      return {
         usersModal,
         getPageDto,
         collapse,
         maxResultsNumber,
         searchByLength,
         userAppName,
         getPageParams,
         itemInput,
         searchUserApp,
         cancelUserApp,
         selectUserApp,
         showValue,
         setCollapse,
      };
   },
});
