import axios from "axios";

export default axios.create({
   baseURL: process.env.VUE_APP_API_DOMAIN,
   headers: {
      "Content-type": "application/json",
   },
});

export const httpLogIn = function () {
   return axios.create({
      baseURL: process.env.VUE_APP_API_DOMAIN,
      auth: {
         username: process.env.VUE_APP_USERNAME as string,
         password: process.env.VUE_APP_SECRET as string,
      },
      headers: {
         "Content-type": "application/x-www-form-urlencoded",
      },
   });
};

export const httpAuthorization = function () {
   const tokenData = JSON.parse(localStorage.getItem("authUser") as string);
   return axios.create({
      baseURL: process.env.VUE_APP_API_DOMAIN,
      headers: {
         "Content-type": "application/json",
         Authorization: "Bearer " + tokenData.token,
      },
   });
};
