import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "text-secondary" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = {
  key: 0,
  class: "sr"
}
const _hoisted_7 = {
  key: 1,
  class: "sr"
}
const _hoisted_8 = { class: "text-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.errored)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["row text-center", { 'load-data-panel': !_ctx.isSmallSize }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("ErrorLoadData")), 1)
          ])
        ], 2)
      ]))
    : (_openBlock(), _createElementBlock("section", _hoisted_4, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["row text-center", { 'load-data-panel': !_ctx.isSmallSize }])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: _normalizeClass(["spinner-border text-success", _ctx.isSmallSize ? 'spinner-border-sm' : 'spinner-border-xl']),
                  role: "status"
                }, null, 2)
              ]),
              (!_ctx.sending)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("Loading")) + "...", 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("SendingMsg")) + "...", 1))
            ], 2))
          : (_ctx.noDataToDisplay)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["row", { 'load-data-panel': !_ctx.isSmallSize }])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["col-12", _ctx.isSmallSize ? ' text-start' : 'text-center fs-4'])
                }, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.isSmallSize ? _ctx.$t("NoSearchResultsForSelectedPhrase") : _ctx.$t("Absence")), 1)
                ], 2)
              ], 2))
            : _createCommentVNode("", true)
      ]))
}