import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "deleteItemModal",
  tabindex: "-1",
  "aria-labelledby": "deleteItemModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "m-auto icon-background-size d-flex justify-content-center align-items-center border bg-danger border-5 rounded-circle p-4 mb-4" }
const _hoisted_6 = { class: "fs-5 fw-bold" }
const _hoisted_7 = { class: "text-success" }
const _hoisted_8 = { class: "modal-footer" }
const _hoisted_9 = {
  type: "button",
  class: "btn btn-secondary px-4",
  "data-bs-dismiss": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, {
              icon: "triangle-exclamation",
              class: "text-light",
              size: "3x"
            })
          ]),
          _createElementVNode("p", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.$t("AreYouSureYouWantToDelete")) + " ", 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.name), 1),
            _createTextVNode("? ")
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.bodyText), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-danger",
            "data-bs-dismiss": "modal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteItem(_ctx.uuid)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: "me-1",
              icon: "trash",
              size: "1x"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("YesDelete")), 1)
          ]),
          _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t("No")), 1)
        ])
      ])
    ])
  ]))
}