import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { vfmPlugin } from "vue-final-modal";
import { createPinia } from "pinia";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
   // You can set your default options here
   position: POSITION.BOTTOM_RIGHT,
};

import i18n from "@/plugins/i18n";
export { i18n };

//Bootstrap framework
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

//Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
   faSearch,
   faHandHoldingUsd,
   faMobileAlt,
   faGlobe,
   faSignInAlt,
   faBan,
   faPlus,
   faMinus,
   faSave,
   faRedoAlt,
   faPaperPlane,
   faTools,
   faCheck,
   faEraser,
   faUser,
   faUsers,
   faCashRegister,
   faSlidersH,
   faBackward,
   faBusinessTime,
   faDollarSign,
   faBook,
   faLock,
   faHouseUser,
   faCircleUser,
   faEnvelope,
   faImage,
   faCalendarCheck,
   faMobileScreen,
   faTags,
   faXmark,
   faPiggyBank,
   faEllipsisH,
   faLaptop,
   faUnlockKeyhole,
   faCheckCircle,
   faHourglassHalf,
   faTrash,
   faTriangleExclamation,
   faWarehouse,
   faSitemap,
   faEdit,
   faArrowRight,
   faFlagCheckered,
   faInfo,
   faArrowLeft,
   faListOl,
   faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
   faSearch,
   faHandHoldingUsd,
   faMobileAlt,
   faGlobe,
   faSignInAlt,
   faBan,
   faPlus,
   faMinus,
   faSave,
   faRedoAlt,
   faPaperPlane,
   faTools,
   faCheck,
   faEraser,
   faUser,
   faUsers,
   faCashRegister,
   faSlidersH,
   faBackward,
   faBusinessTime,
   faCheckCircle,
   faDollarSign,
   faBook,
   faLock,
   faHouseUser,
   faCircleUser,
   faEnvelope,
   faImage,
   faMobileScreen,
   faCalendarCheck,
   faTags,
   faXmark,
   faPiggyBank,
   faEllipsisH,
   faLaptop,
   faUnlockKeyhole,
   faHourglassHalf,
   faTrash,
   faTriangleExclamation,
   faWarehouse,
   faSitemap,
   faEdit,
   faArrowRight,
   faFlagCheckered,
   faCircleInfo,
   faArrowLeft,
   faListOl,
   faTriangleExclamation
);
import "./styles/main.less";

createApp(App)
   .use(VueReCaptcha, { siteKey: "6LeZNXwjAAAAAM2GdrMHiT_f6l09vyKiL-rVqPz6", loaderOptions: { autoHideBadge: true } })
   .use(createPinia())
   .use(router)
   .use(i18n)
   .use(vfmPlugin())
   .component("font-awesome-icon", FontAwesomeIcon)
   .use(Toast, options)
   .mount("#app");
