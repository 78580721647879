import axios from "axios";
import UserAuthDto from "../dto/UserAuthDto";
import ClientActivationAccountDto from "../dto/ClientActivationAccountDto";
import TokenUserAppDto from "../dto/TokenUserAppDto";
import ResetPasswordClientStepOneDto from "../dto/ResetPasswordClientStepOneDto";
import ResetPasswordClientStepTwoDto from "../dto/ResetPasswordClientStepTwoDto";
import ServiceRegistrationDto from "../dto/ServiceRegistrationDto";
import { getAuthHeader } from "./TokenUserAppStorage";
import { RefreshTokenUserAppDto } from "../dto/RefreshTokenUserAppDto";

const urlApi = "/api/service/v1/";

export default class ServiceApi {
   signIn(dto: UserAuthDto) {
      return axios.post<TokenUserAppDto>(process.env.VUE_APP_API_DOMAIN + urlApi + "login", dto, {});
   }

   logout(dto: RefreshTokenUserAppDto) {
      return axios.put<TokenUserAppDto>(process.env.VUE_APP_API_DOMAIN + urlApi + "logout", dto, {});
   }

   create(dto: ServiceRegistrationDto) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi + "registration", dto, {});
   }

   resetPassStep1(dto: ResetPasswordClientStepOneDto) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi + "reset-password/step-1", dto, {});
   }

   resetPassStep2(dto: ResetPasswordClientStepTwoDto) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi + "reset-password/step-2", dto, {});
   }

   activation(dto: ClientActivationAccountDto) {
      return axios.post<ClientActivationAccountDto>(process.env.VUE_APP_API_DOMAIN + urlApi + "activation", dto, {});
   }

   refreshToken(dto: RefreshTokenUserAppDto) {
      return axios.post<TokenUserAppDto>(process.env.VUE_APP_API_DOMAIN + urlApi + "refresh-token", dto, {
         headers: getAuthHeader(),
         withCredentials: true,
      });
   }
}
