
import { PropType, defineComponent, ref } from "vue";
import DocumentPositionsControlPanel from "@/appModules/document/component/DocumentPositionsControlPanel.vue";
import DocumentDto from "../domain/dto/Document";

export default defineComponent({
   name: "DocumentPositionsPanel",
   components: {
      DocumentPositionsControlPanel,
   },
   props: {
      document: {
         type: Object as PropType<DocumentDto>,
         required: true,
      },
   },
   setup(props) {
      const documentPositionsControlPanel = ref<typeof DocumentPositionsControlPanel>();

      async function onValidate() {
         let res = true;

         if (documentPositionsControlPanel.value) {
            res = await documentPositionsControlPanel.value.onValidate();
         }

         return res;
      }

      return {
         documentPositionsControlPanel,
         onValidate,
      };
   },
});
