import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  for: "street",
  class: "form-label"
}
const _hoisted_5 = { class: "col-sm-6 col-lg-3 mb-3" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  for: "streetNumber",
  class: "form-label"
}
const _hoisted_8 = { class: "col-sm-6 col-lg-3 mb-3" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  for: "apartmentNumber",
  class: "form-label"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  for: "postalCode",
  class: "form-label"
}
const _hoisted_15 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = {
  for: "postalName",
  class: "form-label"
}
const _hoisted_18 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = {
  for: "city",
  class: "form-label"
}
const _hoisted_21 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = {
  for: "voviodeship",
  class: "form-label"
}
const _hoisted_24 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = {
  for: "country",
  class: "form-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Street")) + ":", 1),
          _createVNode(_component_Field, {
            name: "street",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.street }]),
            placeholder: _ctx.$t('EnterStreet'),
            modelValue: _ctx.address.street,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address.street) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "street",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("HomeNumber")) + ":", 1),
          _createVNode(_component_Field, {
            name: "streetNumber",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.streetNumber }]),
            placeholder: _ctx.$t('EnterHomeNumber'),
            modelValue: _ctx.address.streetNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address.streetNumber) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "streetNumber",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("ApartmentNumber")) + ":", 1),
          _createVNode(_component_Field, {
            name: "apartmentNumber",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.apartmentNumber }]),
            placeholder: _ctx.$t('EnterApartmentNumber'),
            modelValue: _ctx.address.apartmentNumber,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address.apartmentNumber) = $event))
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "apartmentNumber",
            class: "invalid-feedback"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("Postcode")) + ":", 1),
          _createVNode(_component_Field, {
            name: "postalCode",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.postalCode }]),
            placeholder: _ctx.$t('EnterPostcode'),
            modelValue: _ctx.address.postalCode,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.address.postalCode) = $event)),
            maxlength: "6"
          }, null, 8, ["class", "placeholder", "modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "postalCode",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("PostOffice")) + ":", 1),
          _createVNode(_component_Field, {
            name: "postalName",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.postalName }]),
            modelValue: _ctx.address.postalName,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.address.postalName) = $event)),
            placeholder: _ctx.$t('EnterPostOffice')
          }, null, 8, ["class", "modelValue", "placeholder"]),
          _createVNode(_component_ErrorMessage, {
            name: "postalName",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("City")) + ":", 1),
          _createVNode(_component_Field, {
            name: "city",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.city }]),
            modelValue: _ctx.address.city,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.address.city) = $event)),
            placeholder: _ctx.$t('EnterCity')
          }, null, 8, ["class", "modelValue", "placeholder"]),
          _createVNode(_component_ErrorMessage, {
            name: "city",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("Voviodeship")) + ":", 1),
          _createVNode(_component_Field, {
            name: "voviodeship",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.voviodeship }]),
            modelValue: _ctx.address.voviodeship,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.address.voviodeship) = $event)),
            placeholder: _ctx.$t('EnterVoviodeship')
          }, null, 8, ["class", "modelValue", "placeholder"]),
          _createVNode(_component_ErrorMessage, {
            name: "voviodeship",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("Country")) + ":", 1),
          _createVNode(_component_Field, {
            name: "country",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.country }]),
            modelValue: _ctx.address.country,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.address.country) = $event)),
            placeholder: _ctx.$t('EnterCountry')
          }, null, 8, ["class", "modelValue", "placeholder"]),
          _createVNode(_component_ErrorMessage, {
            name: "country",
            class: "invalid-feedback"
          })
        ])
      ])
    ])
  ], 64))
}