<template>
    <div id="product-instruction-mobile-app-template"
        class="container">
        <div class="row mt-2">
            <div class="col">
                <p>
                    Przedmiot w systemie grupuje pozycje tego samego typu, dla których przypisane są takie same cech jak np. wysokość opłata za wypożyczenie lub dopłata za zwrot. <br/>
                    Dla przykładu: przedmiotem może być 'rower górski' do którego będą przypisane rzeczywiste pozycje oznaczone np.: numerami 'Nr 1', 'Nr 2', 'Nr 3'. <br/>
                    Innym przedmiotami mogą być np.: kaski które ciężko jest ewidencjować poszczególne sztuki, dlatego można definiować ilościową wartość czyli 'Kaski' w liczbie '100'.
                </p>
            </div>
        </div>

<!-- 
    ===========================================================
    DODAWANIE
    =========================================================== 
-->
        <div class="row mt-2">
            <div class="col">
                <p class="table_of_contents_submenu">
                    Dodawanie
                </p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-sm-1 col-md-1"></div>
            <div class="col-12 col-sm-2 col-md-5">
                <p>
                    Aby dodać nowy przedmiot, należy z menu po lewej stronie wybrać pozycje <b class="text-primary">Magazyn</b>. 
                </p>
            </div>
            <div class="col-12 col-sm-9 col-md-6" style="text-align:center;">
                <img src="@/assets/image/instruction/mobile/eWypozyczaj_Tool_01.jpg" style="height: 100%" alt="Menu magazyn"> 
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12 col-sm-1 col-md-1"></div>
            <div class="col-12 col-sm-2 col-md-5">
                <p>
                    Następnie, kliknąć przycisk plus <b class="text-primary">(+)</b> znajdujący się na dole listy. 
                </p>
            </div>
            <div class="col-12 col-sm-9 col-md-6" style="text-align:center;">
                <img src="@/assets/image/instruction/mobile/eWypozyczaj_Tool_02.jpg" style="height: 100%" alt="Menu magazyn"> 
            </div>
        </div>

<!-- 
    ===========================================================
    EDYCJA
    =========================================================== 
-->

        <div class="row mt-5">
            <div class="col">
                <p class="table_of_contents_submenu">
                    Edycja
                </p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-sm-1 col-md-1"></div>
            <div class="col-12 col-sm-2 col-md-5">
                <p>
                    Po przejściu do listy <b>Magazyn</b>, należy wybrany przedmiot przesunąć <b class="text-primary">w lewo</b> na liście.
                </p>
            </div>
            <div class="col-12 col-sm-9 col-md-6" style="text-align:center;">
                <img src="@/assets/image/instruction/mobile/eWypozyczaj_Tool_04.jpg" style="height: 100%" alt="Menu magazyn"> 
            </div>
            
        </div>
        
    </div>
</template>

<script>

    export default {
        name: 'product-instruction-mobile-app-template'
    }
</script>

<style>
</style>