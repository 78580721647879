import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "position-relative full-view" }
const _hoisted_2 = { class: "history-bar" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = { class: "breadcrumb-item" }
const _hoisted_7 = { class: "breadcrumb-item" }
const _hoisted_8 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_9 = { class: "container mt-3" }
const _hoisted_10 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col mt-3 text-center" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = {
  key: 0,
  class: "col mt-4 text-center"
}
const _hoisted_15 = {
  key: 1,
  class: "col mt-4 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_ProfileHeaderUserApp = _resolveComponent("ProfileHeaderUserApp")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _createElementVNode("ol", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, { to: { name: 'panelSettings' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Settings")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, { to: { name: 'panelUsers' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Employees")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.getTokenUserApp().uuid === _ctx.getUserApp.uuid ? _ctx.$t("MyProfile") : _ctx.$t("Profile")), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        isDataToShow: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "isDataToShow"]),
      (!_ctx.getPageParams.noDataToDisplay.value && !_ctx.getPageParams.errorLoadData.value && !_ctx.getPageParams.loadingData.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
            _createVNode(_component_ProfileHeaderUserApp),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_router_link, {
                  class: "btn btn-lg border btn-outline-secondary",
                  to: {
                        name: 'panelDataUserApp',
                        params: { uuid: _ctx.getUserApp.uuid },
                     },
                  style: {"width":"300px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      class: "me-2 fs-3",
                      icon: "house-user"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("AccountDetails")), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_ctx.getUserApp.account && _ctx.getTokenUserApp().uuid === _ctx.getUserApp.uuid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_router_link, {
                      class: "btn btn-lg border btn-outline-secondary",
                      to: {
                        name: 'panelChangePasswordUserApp',
                        params: { uuid: _ctx.getUserApp.uuid },
                     },
                      style: {"width":"300px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          class: "me-2 fs-3",
                          icon: "lock"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("Password")), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.getUserApp.account)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_router_link, {
                      class: "btn btn-lg border btn-outline-secondary",
                      to: {
                        name: 'panelAccessToServiceUserApp',
                        params: { uuid: _ctx.getUserApp.uuid },
                     },
                      style: {"width":"300px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          class: "me-2 fs-3",
                          icon: "laptop"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("AccessToTheSite")), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}