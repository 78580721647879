
import { storeToRefs } from "pinia";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { useCategoryStore } from "../store/CategoryStore";
import { useRoute } from "vue-router";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import CategoryControlPanel from "../component/CategoryControlPanel.vue";
import { getFormattedTabTitle } from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "Category",
   components: { LoadBar, CategoryControlPanel },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   setup() {
      const store = useCategoryStore();
      const { getPageParams, resetToInitial } = store;
      const { getCategory } = storeToRefs(store);
      const uuid = ref();
      const route = useRoute();
      const { typeGrid } = useBreakpointsGrid();
      const categoryControlPanel = ref<typeof CategoryControlPanel>();

      onMounted(async () => {
         uuid.value = route.params.id as string;
         if (uuid.value) {
            await categoryControlPanel.value?.showValue(uuid.value);
            document.title = getFormattedTabTitle(getCategory.value.name);
         } else {
            categoryControlPanel.value?.showValue(null);
         }
      });

      onUnmounted(() => {
         resetToInitial();
      });

      return {
         uuid,
         getCategory,
         getPageParams,
         typeGrid,
         categoryControlPanel,
         equalsOrGreaterGrid,
         resetToInitial,
      };
   },
});
