import { getAuthHeader } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import axios from "axios";
import DocumentDto from "../dto/Document";
import CreateDocumentDto from "../dto/CreateDocument";
import UpdateDocumentDto from "../dto/UpdateDocument";
import DocumentPaginationDto from "../dto/DocumentPaginationDto";

const urlApi = "/api/documents/v1/";

export default class DocumentApi {
   findById(uuid: string | string[]) {
      return axios.get<DocumentDto>(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   delete(uuid: string | string[]) {
      return axios.delete(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   create(dto: CreateDocumentDto) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi, dto, {
         headers: getAuthHeader(),
      });
   }

   findByPagination(query: string) {
      return axios.get<DocumentPaginationDto>(process.env.VUE_APP_API_DOMAIN + urlApi + query, {
         headers: getAuthHeader(),
      });
   }

   update(uuid: string, dto: UpdateDocumentDto) {
      return axios.put(process.env.VUE_APP_API_DOMAIN + urlApi + `${uuid ? uuid : ""}`, dto, {
         headers: getAuthHeader(),
      });
   }
}
