class EnumService {
   static getValues<T extends number>(e: any) {
      return EnumService.getObjValues(e).filter((v) => typeof v === "number") as T[];
   }

   static getKeys<T extends number>(e: any) {
      return EnumService.getObjValues(e).filter((v) => typeof v === "string") as T[];
   }

   static getIndex(e: any, stringEnum: string) {
      const keys = EnumService.getKeys(e);
      for (let i = 0; i < keys.length; i++) {
         if (keys[i].toString().toUpperCase() === stringEnum.replace("_", "")) {
            return i;
         }
      }
      return 0;
   }

   private static getObjValues(e: any): (number | string)[] {
      return Object.keys(e).map((k) => e[k]);
   }
}
export default EnumService;
