import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "full-view" }
const _hoisted_2 = {
  key: 0,
  class: "history-bar"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = { class: "breadcrumb-item" }
const _hoisted_7 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_8 = { class: "container mt-3 d-flex flex-column justify-content-center" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "position-absolute top-50 start-50 translate-middle"
}
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "row text-center" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "display-5 text-success" }
const _hoisted_15 = { class: "row mt-3 mt-md-3 text-center" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "text-secondary" }
const _hoisted_18 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_21 = { class: "col" }
const _hoisted_22 = {
  key: 1,
  class: "row"
}
const _hoisted_23 = {
  key: 0,
  class: "col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_RentProfileForm = _resolveComponent("RentProfileForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("nav", _hoisted_4, [
              _createElementVNode("ol", _hoisted_5, [
                _createElementVNode("li", _hoisted_6, [
                  _createVNode(_component_router_link, { to: { name: 'panelRents' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Rentals")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t("Rental")) + " " + _toDisplayString(_ctx.getRent.documentBegin.number), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        noDataToDisplay: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "noDataToDisplay"]),
      (!_ctx.getPageParams.noDataToDisplay.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_9, [
            (_ctx.getPageParams.created.value)
              ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("h1", _hoisted_14, _toDisplayString(_ctx.$t("NewRentAdded")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("DoYouWantNow")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("button", {
                          class: "btn btn-outline-success btn-lg",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetToInitial()))
                        }, _toDisplayString(_ctx.$t("AddAnotherNewRent")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_router_link, {
                          class: "btn btn-outline-primary btn-lg text-center",
                          to: { name: 'panelRents' }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("ShowRentList")), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  (!_ctx.getPageParams.loadingData.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("form", null, [
                          _createVNode(_component_RentProfileForm)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}