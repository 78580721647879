
import { storeToRefs } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { defineComponent, onMounted, ref } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import LoadBar from "../../common/component/LoadBar.vue";
import DeleteItemModal from "../../common/component/DeleteItemModal.vue";
import PaginationPanel from "../../common/component/PaginationPanel.vue";
import SortComboBox from "../../common/component/SortComboBox.vue";
import SortingPaginationParam from "../../common/domain/command/SortingPaginationParam";
import { useWarehousesStore } from "../store/WarehousesStore";
import EnumService from "@/appModules/common/domain/service/EnumService";
import { toStringEnum } from "@/appModules/common/domain/service/format";
import { WarehouseTypeEnum } from "../domain/enumerated/WarehouseTypeEnum";
import { useWarehouseStore } from "../store/WarehouseStore";
import WarehouseListItem from "../component/WarehouseListItem.vue";
import WarehouseDto from "../domain/dto/WarehouseDto";
import PaginationService from "@/appModules/common/domain/service/PaginationService";

export default defineComponent({
   name: "Warehouses",
   components: {
      LoadBar,
      PaginationPanel,
      SortComboBox,
      WarehouseListItem,
      DeleteItemModal,
   },
   directives: {
      debounce: vue3Debounce({ lock: true }),
   },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectWarehouse"],
   setup(props, { emit }) {
      const warehousesStore = useWarehousesStore();
      const warehouseStore = useWarehouseStore();
      const { getPageParams, getPageDto, sortOptions } = storeToRefs(warehousesStore);
      const { fetchPaginationData } = warehousesStore;
      const { typeGrid } = useBreakpointsGrid();
      const defaultSort = sortOptions.value[0];
      const searchBy = ref("");
      const selectedWarehouse = ref(new WarehouseDto());

      onMounted(() => {
         getPageParams.value.sort.value = defaultSort;
         if (!props.isModal) showValue();
      });

      function sortData(sortOption: SortingPaginationParam) {
         getPageParams.value.sort.value = sortOption;
         showValue();
      }

      function changePagination(page: number, size: number) {
         fetchPaginationData(page, size, searchBy.value);
      }

      function showValue() {
         fetchPaginationData(getPageDto.value.number, getPageDto.value.size, searchBy.value);
      }

      async function deleteWarehouse(uuid: string | string[]) {
         await warehouseStore.delete(uuid);
         fetchPaginationData(new PaginationService().calcPageNumber(getPageDto.value), getPageDto.value.size, searchBy.value);
      }

      function selectWarehouse(warehouse: WarehouseDto) {
         selectedWarehouse.value = warehouse;
         emit("selectWarehouse", warehouse);
      }

      return {
         selectedWarehouse,
         getPageDto,
         getPageParams,
         typeGrid,
         sortOptions,
         defaultSort,
         searchBy,
         WarehouseTypeEnum,
         EnumService,
         useBreakpointsGrid,
         equalsOrGreaterGrid,
         changePagination,
         sortData,
         showValue,
         toStringEnum,
         deleteWarehouse,
         selectWarehouse,
      };
   },
});
