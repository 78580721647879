
import { PropType, defineComponent, toRefs } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import ProductDto from "../domain/dto/ProductDto";

export default defineComponent({
   name: "ProductListItem",
   components: {},
   props: {
      productDto: {
         type: Object as PropType<ProductDto>,
         required: true,
      },
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectProduct"],
   setup(props, { emit }) {
      const { productDto: product } = toRefs(props);
      const { typeGrid } = useBreakpointsGrid();

      function selectProduct() {
         emit("selectProduct", product.value);
      }

      return {
         product,
         typeGrid,
         equalsOrGreaterGrid,
         selectProduct,
      };
   },
});
