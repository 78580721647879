
import { storeToRefs } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { defineComponent, onMounted, ref } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import LoadBar from "../../common/component/LoadBar.vue";
import PaginationPanel from "../../common/component/PaginationPanel.vue";
import SortComboBox from "../../common/component/SortComboBox.vue";
import SortingPaginationParam from "../../common/domain/command/SortingPaginationParam";
import { useRentsStore } from "../store/RentsStore";
import RentListItem from "../component/RentListItem.vue";
import UserAppFilterItemPanel from "@/appModules/userApp/component/UserAppFilterItemPanel.vue";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";

export default defineComponent({
   name: "Rents",
   components: {
      LoadBar,
      PaginationPanel,
      SortComboBox,
      RentListItem,
      UserAppFilterItemPanel,
   },
   directives: {
      debounce: vue3Debounce({ lock: true }),
   },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   setup() {
      const rentsStore = useRentsStore();
      const { getPageParams, getPageDto, sortOptions } = storeToRefs(rentsStore);
      const { fetchPaginationData } = rentsStore;
      const { typeGrid } = useBreakpointsGrid();
      const defaultSort = sortOptions.value[0];
      const searchBy = ref("");
      const userApp = ref(new UserAppDto());

      onMounted(() => {
         showValue();
      });

      function sortData(sortOption: SortingPaginationParam) {
         getPageParams.value.sort.value = sortOption;
         showValue();
      }

      function changePagination(page: number, size: number) {
         fetchPaginationData(page, size, searchBy.value);
      }

      function showValue() {
         fetchPaginationData(getPageDto.value.number, getPageDto.value.size, searchBy.value, userApp?.value.uuid);
      }

      function filterByUserApp(userAppDto: UserAppDto) {
         userApp.value = userAppDto;
         showValue();
      }

      function cancelUserApp() {
         userApp.value = new UserAppDto();
         showValue();
      }

      return {
         getPageDto,
         getPageParams,
         typeGrid,
         sortOptions,
         defaultSort,
         searchBy,
         userApp,
         useBreakpointsGrid,
         equalsOrGreaterGrid,
         changePagination,
         sortData,
         showValue,
         filterByUserApp,
         cancelUserApp,
      };
   },
});
