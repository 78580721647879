import { ref } from "vue";

export interface IPagination {
   number: number;
   totalElements: number;
   totalPages: number;
   numberOfElements: number;
   size: number;
   empty: boolean;
   first: boolean;
   last: boolean;
}

export default class PaginationDto implements IPagination {
   constructor(
      public empty: boolean = false,
      public first: boolean = true,
      public last: boolean = false,
      public number: number = 0,
      public numberOfElements: number = 0,
      public size: number = 5,
      public totalElements: number = 0,
      public totalPages: number = 0
   ) {}
}
