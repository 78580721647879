
import * as yup from "yup";
import { i18n } from "@/main";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { PropType, defineComponent, onMounted, ref, toRefs, watch } from "vue";
import DocumentDto from "../domain/dto/Document";
import { useDocumentStore } from "../store/DocumentStore";
import { storeToRefs } from "pinia";
import { DocumentErrorItemCodeEnum } from "../domain/enumerated/DocumentErrorItemCodeEnum";

export default defineComponent({
   name: "GeneralDocumentPanel",
   components: {
      Field,
      ErrorMessage,
   },
   props: {
      document: {
         type: Object as PropType<DocumentDto>,
         required: true,
      },
   },
   setup(props) {
      const { document: documentRef } = toRefs(props);
      const isLoadingData = ref(false);
      const store = useDocumentStore();
      const { setErrorMsg, resetErrors } = store;
      const { getErrors } = storeToRefs(store);

      const { setFieldError, errors, validate } = useForm({
         validationSchema: yup.object().shape({
            documentExternalNumber: yup
               .string()
               .nullable()
               .test("testExternalNumber", i18n.global.t("ErrorExternalNumberExists"), function () {
                  return getErrors.value.find((x) => x.code === DocumentErrorItemCodeEnum.DuplicateExternalNumber) === undefined;
               }),
         }),
      });

      onMounted(() => {
         setErrorMsg(setFieldError);
      });

      watch(
         () => documentRef.value.externalNumber,
         () => {
            resetErrors();
         }
      );

      async function onValidate() {
         const { valid } = await validate();

         return valid;
      }

      return {
         errors,
         isLoadingData,
         documentRef,
         onValidate,
      };
   },
});
