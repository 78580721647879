
import { defineComponent, ref, onMounted, computed, onUnmounted, watch } from "vue";
import { i18n } from "@/main";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import LoadBar from "../../common/component/LoadBar.vue";
import DocumentPositionsPanel from "@/appModules/document/component/DocumentPositionsPanel.vue";
import NavigationWizardButtons from "@/appModules/common/component/NavigationWizardButtons.vue";
import GeneralDocumentPanel from "../component/GeneralDocumentPanel.vue";
import { storeToRefs } from "pinia";
import { useDocumentStore } from "../store/DocumentStore";
import { ViewModeEnum } from "@/appModules/common/domain/enumerated/ViewModeEnum";
import { getClassTab } from "@/appModules/common/domain/service/format";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import { DocumentErrorItemCodeEnum } from "../domain/enumerated/DocumentErrorItemCodeEnum";
import { getFormattedTabTitle } from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "Document",
   components: {
      LoadBar,
      GeneralDocumentPanel,
      DocumentPositionsPanel,
      NavigationWizardButtons,
   },
   setup() {
      const numberGeneralDocumentPanel = 1;
      const numberPositionsDocumentPanel = 2;
      const step = ref(numberGeneralDocumentPanel);
      const generalDocumentPanel = ref<typeof GeneralDocumentPanel>();
      const documentPositionsPanel = ref<typeof DocumentPositionsPanel>();
      const toast = useToast();
      const route = useRoute();
      const documentStore = useDocumentStore();
      const { create, update, findById, resetToInitial } = documentStore;
      const { getPageParams, getDocument, getErrors } = storeToRefs(documentStore);
      const viewModeEnum = ref(ViewModeEnum.NEW);
      const uuid = ref();
      const { typeGrid } = useBreakpointsGrid();
      const isDataLoaded = ref(false);

      onMounted(async () => {
         uuid.value = route.params.id as string;
         if (uuid.value) {
            viewModeEnum.value = ViewModeEnum.EDIT;
            await findById(uuid.value);
            document.title = getFormattedTabTitle(getDocument.value.number);
         }
         isDataLoaded.value = true;
      });

      onUnmounted(() => {
         resetToInitial();
      });

      watch(
         () => getErrors.value,
         (newValue) => {
            if (newValue.length > 0) {
               if (newValue.find((x) => x.code === DocumentErrorItemCodeEnum.DuplicateExternalNumber))
                  step.value = numberGeneralDocumentPanel;
            }
         }
      );

      async function isValid() {
         let valid = true;

         if (step.value === numberGeneralDocumentPanel) {
            if (generalDocumentPanel.value) {
               valid = await generalDocumentPanel.value.onValidate();
            }
         }

         if (step.value === numberPositionsDocumentPanel) {
            if (documentPositionsPanel.value) {
               valid = await documentPositionsPanel.value.onValidate();
            }
         }

         return valid;
      }

      async function onClickSwitchTab(stepClick: number) {
         if (viewModeEnum.value === ViewModeEnum.EDIT) {
            switchTabIfIsValidateCurrentTab(stepClick);
         }
      }

      async function switchTabIfIsValidateCurrentTab(stepClick: number) {
         window.scrollTo(0, 0);

         if (await isValid()) {
            step.value = stepClick;
         }
      }

      async function onBackStepClick() {
         window.scrollTo(0, 0);
         step.value -= 1;
      }

      async function onNextStepClick() {
         switchTabIfIsValidateCurrentTab(step.value + 1);
      }

      async function submitSave() {
         if (await isValid()) {
            if (uuid.value) await update();
            else await create();
         } else toast.error(i18n.global.t("FormHasError"));
      }

      function addNewDocument() {
         resetToInitial();
         step.value = 1;
      }

      const validPositionsTab = computed(() => {
         return viewModeEnum.value === ViewModeEnum.EDIT
            ? true
            : getDocument.value.documentPositions?.every((x) => x.product.uuid && x.warehouse.uuid);
      });

      return {
         step,
         getPageParams,
         viewModeEnum,
         numberGeneralDocumentPanel,
         numberPositionsDocumentPanel,
         getDocument,
         uuid,
         typeGrid,
         generalDocumentPanel,
         documentPositionsPanel,
         validPositionsTab,
         isDataLoaded,
         addNewDocument,
         submitSave,
         getClassTab,
         resetToInitial,
         equalsOrGreaterGrid,
         onClickSwitchTab,
         onBackStepClick,
         onNextStepClick,
      };
   },
});
