
import { PropType, defineComponent, ref, watchEffect } from "vue";
import ItemInput from "../../common/component/ItemInput.vue";
import ItemInputCollapseResults from "../../common/component/ItemInputCollapseResults.vue";
import CategoriesModal from "../view/CategoriesModal.vue";
import CategoryModal from "../view/CategoryModal.vue";
import { placeholder } from "@babel/types";
import CategoryDto from "../domain/dto/CategoryDto";
import { useCategoryStore } from "../store/CategoryStore";
import { useCategoriesStore } from "../store/CategoriesStore";
import { storeToRefs } from "pinia";

export default defineComponent({
   name: "CategoryItemPanel",
   components: {
      ItemInput,
      CategoriesModal,
      CategoryModal,
      ItemInputCollapseResults,
   },
   props: {
      name: {
         type: String,
         required: true,
      },
      label: {
         type: String,
      },
      placeholder: {
         type: String,
      },
      category: {
         type: Object as PropType<CategoryDto>,
         required: true,
      },
      isRequiredStyle: {
         type: Boolean,
         default: false,
      },
      categoryExists: {
         type: Boolean,
         required: true,
      },
      errors: {
         type: Object,
      },
   },
   emits: ["selectCategory", "cancelCategory"],
   setup(props, { emit }) {
      const categoriesModal = ref<typeof CategoriesModal>();
      const categoryModal = ref<typeof CategoryModal>();
      const itemInput = ref<typeof ItemInput>();
      const categoryStore = useCategoryStore();
      const { setCategory, resetToInitial } = categoryStore;
      const categoriesStore = useCategoriesStore();
      const { fetchPaginationData } = categoriesStore;
      const { getPageDto, getPageParams } = storeToRefs(categoriesStore);
      const collapse = ref(false);
      const maxResultsNumber = 10;
      const searchByLength = ref(0);
      const categoryName = ref("");

      watchEffect(() => (categoryName.value = props.category.name));

      function searchCategory() {
         categoriesModal.value?.openModal();
      }

      function addCategory() {
         categoryModal.value?.openModal();
         resetToInitial();
      }

      function editCategory() {
         categoryModal.value?.openModal();
         setCategory(props.category);
      }

      function cancelCategory() {
         emit("cancelCategory");
         categoryName.value = "";
         categoriesStore.resetToInitial();
      }

      function selectCategory(category: CategoryDto) {
         categoryName.value = category.name;
         collapse.value = false;
         categoriesStore.resetToInitial();
         setCategory(category);
         emit("selectCategory", category);
      }

      async function showValue(searchBy: string) {
         searchByLength.value = searchBy.length;
         if (searchByLength.value >= 3) {
            await fetchPaginationData(0, maxResultsNumber, searchBy);
         }
      }

      function setCollapse(isFocus: boolean) {
         collapse.value = isFocus;
         if (!isFocus) {
            searchByLength.value = 0;
            if (props.category.uuid.length == 0) {
               itemInput.value?.resetField();
            }
         }
      }

      return {
         categoriesModal,
         categoryModal,
         getPageDto,
         collapse,
         maxResultsNumber,
         searchByLength,
         categoryName,
         getPageParams,
         itemInput,
         addCategory,
         searchCategory,
         cancelCategory,
         selectCategory,
         editCategory,
         showValue,
         setCollapse,
      };
   },
});
