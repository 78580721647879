import Contact from "@/appModules/service/domain/dto/ContactDto";
import axios from "axios";

const urlContact = "/api/contact/v1/";

export default class ContactService {
   sendMsg(contact: Contact) {
      return axios.post<Contact>(process.env.VUE_APP_API_DOMAIN + urlContact + "service-provider", contact, {
         withCredentials: true,
      });
   }
}
