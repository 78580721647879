import AddressDto from "@/appModules/service/domain/dto/AddressDto";
import { WarehouseTypeEnum } from "../enumerated/WarehouseTypeEnum";

export interface ICreateWarehouse {
   name: string;
   type: WarehouseTypeEnum;
   addressOtherThanMain: boolean;
   address: AddressDto | undefined;
}

export default class CreateWarehouseDto implements ICreateWarehouse {
   constructor(
      public name: string = "",
      public type: WarehouseTypeEnum = WarehouseTypeEnum.OperationalProducts,
      public addressOtherThanMain: boolean = false,
      public address: AddressDto | undefined = undefined
   ) {}
}
