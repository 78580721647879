import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-body"
}
const _hoisted_2 = { class: "mt-3 px-4" }
const _hoisted_3 = { class: "fs-4 text-secondary" }
const _hoisted_4 = { class: "col-12 d-flex flex-column flex-xl-row justify-content-center" }
const _hoisted_5 = { class: "col-12 col-xl-5" }
const _hoisted_6 = { class: "col-12 col-xl-2 py-3 py-xl-0 px-xl-3" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "col-12 col-xl-5" }
const _hoisted_9 = { class: "col-12 mt-3 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductItemPanel = _resolveComponent("ProductItemPanel")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_WarehouseItemPanel = _resolveComponent("WarehouseItemPanel")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.idx + 1) + ". ", 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ProductItemPanel, {
                name: `positions[${_ctx.idx}].productItemPanel`,
                label: _ctx.$t('Product'),
                placeholder: _ctx.$t('SelectProduct'),
                isRequiredStyle: true,
                productExists: _ctx.positionRef.product.uuid.length > 0,
                errors: _ctx.errors,
                product: _ctx.positionRef.product,
                onSelectProduct: _ctx.selectProduct,
                onCancelProduct: _ctx.cancelProduct,
                isReadOnly: _ctx.isReadOnly
              }, null, 8, ["name", "label", "placeholder", "productExists", "errors", "product", "onSelectProduct", "onCancelProduct", "isReadOnly"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", {
                for: `positions[${_ctx.idx}].quantity`,
                class: "form-label"
              }, _toDisplayString(_ctx.$t("Quantity")), 9, _hoisted_7),
              _createVNode(_component_Field, {
                name: `positions[${_ctx.idx}].quantity`,
                type: "number",
                modelValue: _ctx.positionRef.quantity,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.positionRef.quantity) = $event)),
                min: "1",
                class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.isErrors(`positions[${_ctx.idx}].quantity`) }]),
                disabled: _ctx.isReadOnly
              }, null, 8, ["name", "modelValue", "class", "disabled"]),
              _createVNode(_component_ErrorMessage, {
                name: `positions[${_ctx.idx}].quantity`,
                class: "invalid-feedback"
              }, null, 8, ["name"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_WarehouseItemPanel, {
                name: `positions[${_ctx.idx}].warehouseItemPanel`,
                label: _ctx.$t('Warehouse'),
                placeholder: _ctx.$t('SelectWarehouse'),
                isRequiredStyle: true,
                warehouseExists: _ctx.positionRef.warehouse.uuid.length > 0,
                errors: _ctx.errors,
                warehouse: _ctx.positionRef.warehouse,
                isReadOnly: _ctx.isReadOnly,
                onSelectWarehouse: _ctx.selectWarehouse,
                onCancelWarehouse: _ctx.cancelWarehouse
              }, null, 8, ["name", "label", "placeholder", "warehouseExists", "errors", "warehouse", "isReadOnly", "onSelectWarehouse", "onCancelWarehouse"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.addRemoveBtn && !_ctx.isReadOnly)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-outline-danger me-3",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeDocumentPositionButtonOnClick && _ctx.removeDocumentPositionButtonOnClick(...args)))
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "me-1",
                  icon: "trash",
                  size: "1x"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("RemoveDocumentPosition")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}