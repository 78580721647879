<template>
    <div id="product-item-instruction-mobile-app-template"
        class="container">

<!-- 
    ===========================================================
    DODAWANIE
    =========================================================== 
-->
        <div class="row mt-2">
            <div class="col">
                <p class="table_of_contents_submenu">
                    Dodawanie
                </p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-sm-1 col-md-1"></div>
            <div class="col-12 col-sm-2 col-md-5">
                <p>
                    Po przejściu do listy <b>Magazyn</b>, należy wybrany przedmiot, do którego chcemy dodać pozycje, przesunąć <b class="text-primary">w prawo</b> na liście.
                </p>
            </div>
            <div class="col-12 col-sm-9 col-md-6" style="text-align:center;">
                <img src="@/assets/image/instruction/mobile/eWypozyczaj_Tool_05.jpg" style="height: 100%" alt="Menu magazyn"> 
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12 col-sm-1 col-md-1"></div>
            <div class="col-12 col-sm-2 col-md-5">
                <p>
                    Następnie, w okienku które się pojawi, kliknąć przycisk <b class="text-primary">Pozycje</b>. 
                </p>
            </div>
            <div class="col-12 col-sm-9 col-md-6" style="text-align:center;">
                <img src="@/assets/image/instruction/mobile/eWypozyczaj_Tool_06.jpg" style="height: 100%" alt="Menu magazyn"> 
            </div>
        </div>
        
<!-- 
    ===========================================================
    EDYCJA
    =========================================================== 
-->

<!--
        <div class="row mt-5">
            <div class="col">
                <p class="table_of_contents_submenu">
                    Edycja
                </p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-sm-1 col-md-1"></div>
            <div class="col-12 col-sm-2 col-md-5">
                <p>
                    Po przejściu do listy <b>Magazyn</b>, należy wybrany przedmiot przesunąć <b class="text-primary">w lewo</b> na liście.
                </p>
            </div>
            <div class="col-12 col-sm-9 col-md-6" style="text-align:center;">
                <img src="@/assets/image/instruction/mobile/eWypozyczaj_Tool_04.jpg" style="height: 100%" alt="Menu magazyn"> 
            </div>
            
        </div>
-->

    </div>
</template>

<script>

    export default {
        name: 'product-item-instruction-mobile-app-template'
    }
</script>

<style>
</style>