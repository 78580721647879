import usePageParams from "@/appModules/common/domain/composable/usePageParams";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { i18n } from "@/main";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import CreateDocumentDto from "../domain/dto/CreateDocument";
import DocumentDto from "../domain/dto/Document";
import UpdateDocumentDto from "../domain/dto/UpdateDocument";
import DocumentApi from "../domain/repository/DocumentApi";
import { DocumentErrorItemCodeEnum } from "../domain/enumerated/DocumentErrorItemCodeEnum";
import CreateDocumentPositionDto from "../domain/dto/CreateDocumentPosition";

const api = new DocumentApi();
const toast = useToast();
const params = usePageParams();

export const useDocumentStore = defineStore({
   id: "documentStore",
   state: () => ({
      documentDto: new DocumentDto(),
      errors: [] as ErrorItem[],
   }),
   getters: {
      getPageParams: () => params,
      getDocument: (state) => state.documentDto,
      getErrors: (state) => state.errors,
   },
   actions: {
      async findById(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .findById(id)
            .then(
               (success) => {
                  this.documentDto = success.data;
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async delete(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         api.delete(id)
            .then(
               (success) => {
                  if (success.status == 200) {
                     toast.success(i18n.global.t("DocumentDeleted"));
                  }
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async create() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         const createDocumentDto = new CreateDocumentDto(
            this.documentDto.note,
            this.documentDto.type,
            this.documentDto.externalNumber,
            this.documentDto.documentPositions.map((dto) => {
               return new CreateDocumentPositionDto(dto.product.uuid, dto.warehouse.uuid, dto.quantity);
            })
         );

         api.create(createDocumentDto)
            .then(
               (success) => {
                  if (success.status == 201) {
                     params.created.value = true;
                     this.resetErrors();
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      async update() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         const updateDocumentDto = new UpdateDocumentDto(
            this.documentDto.note,
            this.documentDto.externalNumber,
            this.documentDto.version
         );

         return api
            .update(this.documentDto.uuid, updateDocumentDto)
            .then(
               (success) => {
                  if (success.status == 200) {
                     this.documentDto = success.data;
                     toast.success(i18n.global.t("DocumentUpdated"));
                     this.resetErrors();
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      resetToInitial() {
         params.created.value = false;
         params.noDataToDisplay.value = false;
         this.documentDto = new DocumentDto();
         this.resetErrors();
      },

      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === DocumentErrorItemCodeEnum.DuplicateExternalNumber) {
               setFieldError("documentExternalNumber", i18n.global.t("ErrorExternalNumberExists"));
            }
         }
      },

      resetErrors() {
         this.errors = [];
      },
   },
});
