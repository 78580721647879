
import { defineComponent, ref } from "vue";
import UserAppRegistrationForm from "@/appModules/service/component/UserAppRegistrationForm.vue";
import CompanyRegistrationForm from "@/appModules/service/component/CompanyRegistrationForm.vue";
import AddressPanel from "@/appModules/service/component/AddressPanel.vue";
import { useCompanyRegistrationStore } from "../store/CompanyRegistrationStore";
import { Field, ErrorMessage, useForm } from "vee-validate";
import { i18n } from "@/main";
import * as yup from "yup";

export default defineComponent({
   name: "RegistrationForm",
   components: {
      UserAppRegistrationForm,
      CompanyRegistrationForm,
      AddressPanel,
      Field,
      ErrorMessage,
   },

   setup() {
      const loading = ref(true);
      const errored = ref(false);
      const isDataToShow = ref(false);
      const activeButtonClass = ref("btn btn-success");
      const inactiveButtonClass = ref("btn btn-outline-secondary");
      const companyModeRent = ref(0);
      const otherAddressRental = ref(false);
      const checkAgreement = ref(false);
      const checkAgreementInvalidFeedback = ref("");
      const store = useCompanyRegistrationStore();
      const addressPanel = ref<typeof AddressPanel>();
      const companyRegistrationForm = ref<typeof CompanyRegistrationForm>();
      const userAppRegistrationForm = ref<typeof UserAppRegistrationForm>();

      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            agreementCheckbox: yup
               .bool()
               .test("testAgreementCheckbox", i18n.global.t("AcceptTermsAndConditions"), function (value) {
                  if (value === false) {
                     return true;
                  }
                  return false;
               }),
         }),
      });

      async function onValidate() {
         let rez = false;
         let client = null;
         let company = null;
         let address = null;

         const { valid, errors } = await validate();

         if (userAppRegistrationForm.value) {
            if (await userAppRegistrationForm.value.onValidate()) {
               client = true;
            }
         }

         if (companyRegistrationForm.value) {
            if (await companyRegistrationForm.value.onValidate()) {
               company = true;
            }
         }

         if (otherAddressRental.value) {
            if (addressPanel.value) {
               if (await addressPanel.value.onValidate()) {
                  address = true;
               }
            }
         }

         if (client && company) {
            rez = true;
         }

         if (otherAddressRental.value) {
            if (!address) {
               return false;
            }
         }

         if (valid && rez) {
            return true;
         }
         return rez;
      }

      return {
         addressPanel,
         companyRegistrationForm,
         userAppRegistrationForm,
         loading,
         errored,
         isDataToShow,
         activeButtonClass,
         inactiveButtonClass,
         companyModeRent,
         otherAddressRental,
         checkAgreement,
         checkAgreementInvalidFeedback,
         store,
         errors,
         onValidate,
      };
   },
});
