
import { defineComponent } from "vue";
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import { getPasswordMask } from "@/appModules/common/domain/command/MaskCommon";
import { useCompanyRegistrationStore } from "../store/CompanyRegistrationStore";

export default defineComponent({
   name: "UserAppRegistrationForm",
   components: { Field, ErrorMessage },
   setup() {
      const store = useCompanyRegistrationStore();
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            lastName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"]))
               .nullable(true),
            firstName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(25, i18n.global.t("TextMaxLength", ["25"]))
               .nullable(true),
            username: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .email(i18n.global.t("IncorrectFormatEmail"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            password: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .matches(getPasswordMask(), i18n.global.t("IncorrectPasswordWithMask")),
            confirmPassword: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .test("passwords-match", i18n.global.t("IncorrectPasswordConfirmedNotMatch"), function (value) {
                  return this.parent.password === value;
               }),
         }),
      });
      async function onValidate() {
         let rez = true;
         const { valid } = await validate();

         rez = valid;
         return rez;
      }

      return {
         errors,
         store,
         onValidate,
      };
   },
});
