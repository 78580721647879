
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { defineComponent, ref } from "vue";
import { useAccessToServiceUserAppStore } from "@/appModules/userApp/store/AccessToServiceUserAppStore";
import { getTokenUserApp } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import ProfileHeaderUserApp from "@/appModules/userApp/component/ProfileHeaderUserApp.vue";
import UserAppAccessForm from "@/appModules/userApp/component/UserAppAccessForm.vue";
import { useProfileUserAppStore } from "../store/ProfileUserAppStore";
import { useToast } from "vue-toastification";
import i18n from "@/plugins/i18n";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";

export default defineComponent({
   name: "UserAppAccessToService",
   components: {
      LoadBar,
      UserAppAccessForm,
      ProfileHeaderUserApp,
   },
   setup() {
      const route = useRoute();
      const userProfileStore = useProfileUserAppStore();
      const addAccessStore = useAccessToServiceUserAppStore();
      const { getPageParams, getUserApp } = storeToRefs(userProfileStore);
      const { addAccess } = addAccessStore;
      const userAppAccessForm = ref<typeof UserAppAccessForm>();
      const toast = useToast();
      const { typeGrid } = useBreakpointsGrid();

      async function onValidate() {
         let res = true;

         if (userAppAccessForm.value) {
            res = await userAppAccessForm.value.onValidate();
         }

         return res;
      }

      async function submitSave() {
         if (await onValidate()) {
            addAccess(getUserApp.value.uuid, getUserApp.value.version);
         } else toast.error(i18n.global.t("FormHasError"));
      }
      return {
         getPageParams,
         getUserApp,
         route,
         userAppAccessForm,
         typeGrid,
         equalsOrGreaterGrid,
         getTokenUserApp,
         submitSave,
      };
   },
});
