
import { i18n } from "@/main";
import * as yup from "yup";
import { storeToRefs } from "pinia";
import { defineComponent, onMounted, ref, watch } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import { toStringRoleEnum, toStringEnum } from "@/appModules/common/domain/service/format";
import { getPasswordMask } from "@/appModules/common/domain/command/MaskCommon";
import { RoleEnum } from "@/appModules/userApp/domain/enumerated/RoleEnum";
import EnumService from "@/appModules/common/domain/service/EnumService";
import { useAccessToServiceUserAppStore } from "../store/AccessToServiceUserAppStore";

export default defineComponent({
   name: "UserAppAccessForm",
   components: { Field, ErrorMessage },
   setup() {
      const store = useAccessToServiceUserAppStore();
      const { getAddAccessUserAppDto, getErrors } = storeToRefs(store);
      const { setErrorMsg, resetToInitial } = store;
      const roles = ref([] as any);
      const selected = ref();
      const checkedUnicode = "\u{1F5F9}";
      const uncheckedUnicode = "\u{2610}";
      const showRoles = ref(false);

      const { errors, validate, setFieldError } = useForm({
         validationSchema: yup.object().shape({
            username: yup
               .string()
               .email(i18n.global.t("IncorrectFormatEmail"))
               .max(75, i18n.global.t("TextMaxLength", ["75"]))
               .required(i18n.global.t("FieldCanNotBeEmpty")),
            password: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"]))
               .matches(getPasswordMask(), i18n.global.t("IncorrectPasswordWithMask")),
            confirmPassword: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"]))
               .test("passwords-match", i18n.global.t("IncorrectPasswordConfirmedNotMatch"), function (value) {
                  return this.parent.password === value;
               }),
            role: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .test("roleValidation", i18n.global.t("FieldCanNotBeEmpty"), function () {
                  return roles.value.length > 0;
               }),
         }),
      });

      onMounted(() => {
         resetToInitial();
      });

      watch(
         () => getErrors.value,
         (newValue) => {
            if (newValue.length > 0) setErrorMsg(setFieldError);
         }
      );

      watch(
         () => roles.value,
         (newValue) => {
            if (newValue.length > 0) getAddAccessUserAppDto.value.uuidRoles = roles as unknown as RoleEnum[];
         }
      );

      function addRole(role: RoleEnum) {
         showRoles.value = !showRoles.value;
         if (showRoles.value || !role) return;
         let index = roles.value.indexOf(role.toString());
         if (roleExists(role)) {
            if (!showRoles.value) {
               roles.value[index] = "";
            }
         } else {
            if (!showRoles.value) {
               roles.value.push(role);
            }
         }
         roles.value = roles.value.filter((x: string) => x.length > 0);
      }

      function roleExists(role: RoleEnum) {
         return roles.value.indexOf(role.toString()) >= 0;
      }

      async function onValidate() {
         const { valid } = await validate();

         return valid;
      }

      return {
         EnumService,
         selected,
         roles,
         getAddAccessUserAppDto,
         errors,
         RoleEnum,
         checkedUnicode,
         uncheckedUnicode,
         showRoles,
         toStringRoleEnum,
         toStringEnum,
         onValidate,
         setFieldError,
         addRole,
         roleExists,
      };
   },
});
