
import { defineComponent, ref } from "vue";
import Product from "@/appModules/product/view/Product.vue";
import ProductDto from "@/appModules/product/domain/dto/ProductDto";
import { storeToRefs } from "pinia";
import { useProductStore } from "@/appModules/product/store/ProductStore";

export default defineComponent({
   name: "ProductModal",
   components: {
      Product,
   },
   emits: ["selectProduct"],
   setup(props, { emit }) {
      const showModal = ref(false);
      const product = ref<typeof Product>();
      const store = useProductStore();
      const { getProduct, getErrors } = storeToRefs(store);

      function openModal(id: string | string[]) {
         showModal.value = true;
         if (id) product.value?.showValue(id);
      }

      function selectProduct(product: ProductDto) {
         if (getErrors.value.length == 0) {
            showModal.value = false;
            emit("selectProduct", product);
         }
      }

      return {
         product,
         showModal,
         getProduct,
         openModal,
         selectProduct,
      };
   },
});
