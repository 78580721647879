export interface IAddress {
   uuid: string;
   street: string;
   streetNumber: string;
   apartmentNumber: string;
   postalCode: string;
   postalName: string;
   city: string;
   country: string;
   voviodeship: string;
}

export default class AddressDto implements IAddress {
   constructor(
      public uuid: string = "",
      public street: string = "",
      public streetNumber: string = "",
      public apartmentNumber: string = "",
      public postalCode: string = "",
      public postalName: string = "",
      public city: string = "",
      public country: string = "",
      public voviodeship: string = ""
   ) {}
}
