import usePageParams from "@/appModules/common/domain/composable/usePageParams";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { i18n } from "@/main";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import CategoryApi from "../domain/repository/CategoryApi";
import CategoryDto from "../domain/dto/CategoryDto";
import { CategoryErrorItemCodeEnum } from "../domain/enumerated/CategoryErrorItemCodeEnum";

const api = new CategoryApi();
const toast = useToast();
const params = usePageParams();

export const useCategoryStore = defineStore({
   id: "categoryStore",
   state: () => ({
      categoryDto: new CategoryDto(),
      errors: [] as ErrorItem[],
   }),
   getters: {
      getPageParams: () => params,
      getCategory: (state) => state.categoryDto,
      getErrors: (state) => state.errors,
   },
   actions: {
      async findById(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .findById(id)
            .then(
               (success) => {
                  this.categoryDto = success.data;
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async delete(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .delete(id)
            .then(
               (success) => {
                  if (success.status == 200) {
                     toast.success(i18n.global.t("CategoryDeleted"));
                  }
               },
               (error) => {
                  if (error.response.status == 400) {
                     this.errors = error.response.data["invalid-params"];
                     this.setErrorMsg(null);
                  } else if (error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async create() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         return api
            .create(this.getCategory.name)
            .then(
               (success) => {
                  if (success.status == 201) {
                     this.categoryDto = success.data;
                     params.created.value = true;
                     this.errors = [];
                     toast.success(i18n.global.t("NewCategoryAdded"));
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      async update() {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         api.update(this.categoryDto)
            .then(
               (success) => {
                  if (success.status == 200) {
                     this.categoryDto = success.data;
                     toast.success(i18n.global.t("CategoryUpdated"));
                     this.errors = [];
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },

      resetToInitial() {
         params.created.value = false;
         params.noDataToDisplay.value = false;
         this.categoryDto = new CategoryDto();
         this.errors = [];
      },

      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === CategoryErrorItemCodeEnum.DuplicateName) {
               setFieldError("categoryName", i18n.global.t("ErrorCategoryNameExists"));
            }
            if (e.code === CategoryErrorItemCodeEnum.DuplicateName2) {
               setFieldError("categoryName", i18n.global.t("ErrorCategoryNameExists2"));
            }
            if (e.code === CategoryErrorItemCodeEnum.ProductsAssigned) {
               toast.error(i18n.global.t("ProductsAssignedToCategoryStillExist"));
            }
         }
      },

      setCategory(category: CategoryDto) {
         this.categoryDto = category;
      },
   },
});
