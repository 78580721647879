
import { defineComponent, ref } from "vue";
import CategoryControlPanel from "../component/CategoryControlPanel.vue";
import CategoryDto from "../domain/dto/CategoryDto";
import { storeToRefs } from "pinia";
import { useCategoryStore } from "../store/CategoryStore";

export default defineComponent({
   name: "CategoryModal",
   components: {
      CategoryControlPanel,
   },
   emits: ["selectCategory"],
   setup(props, { emit }) {
      const showModal = ref(false);
      const categoryControlPanel = ref<typeof CategoryControlPanel>();
      const store = useCategoryStore();
      const { getCategory } = storeToRefs(store);

      function openModal() {
         showModal.value = true;
      }

      function selectCategory(category: CategoryDto) {
         showModal.value = false;
         emit("selectCategory", category);
      }

      return {
         categoryControlPanel,
         showModal,
         getCategory,
         openModal,
         selectCategory,
      };
   },
});
