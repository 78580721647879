import AddressDto from "@/appModules/service/domain/dto/AddressDto";
import { WarehouseTypeEnum } from "../enumerated/WarehouseTypeEnum";

export interface IUpdateWarehouseDto {
   name: string;
   type: WarehouseTypeEnum;
   addressOtherThanMain: boolean;
   address: AddressDto | undefined;
   version: number;
}

export default class UpdateWarehouseDto implements IUpdateWarehouseDto {
   constructor(
      public name: string = "",
      public type: WarehouseTypeEnum = WarehouseTypeEnum.OperationalProducts,
      public addressOtherThanMain: boolean = false,
      public address: AddressDto | undefined = undefined,
      public version: number = 0
   ) {}
}
