
import { defineComponent, ref } from "vue";
import HeaderPage from "@/appModules/common/component/HeaderPage.vue";
import FooterPage from "@/appModules/common/component/FooterPage.vue";
import CookiesAgreementModal from "@/appModules/agreement/component/CookiesAgreementModal.vue";
import { i18n } from "@/main";

export default defineComponent({
   components: {
      HeaderPage,
      FooterPage,
      CookiesAgreementModal,
   },
   watch: {
      $route: {
         immediate: true,
         handler(to) {
            document.title = to.meta.title ? `${to.meta.title} ·` : "";
            document.title += i18n.global.t("WebSiteTitle");
         },
      },
   },
   setup() {
      const showAgreement = ref(false);

      function hideAgreement() {
         showAgreement.value = false;
      }

      return {
         showAgreement,
         hideAgreement,
      };
   },
});
