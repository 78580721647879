import { createRouter, createWebHistory } from "vue-router";
import i18n from "../plugins/i18n";

import AppIndex from "@/appModules/common/view/AppIndex.vue";
import About from "@/appModules/common/view/About.vue";
import Login from "@/appModules/service/view/Login.vue";
import Agreement from "@/appModules/agreement/view/Agreement.vue";
import Registration from "@/appModules/service/view/Registration.vue";
import ResetPassword from "@/appModules/service/view/ResetPassword.vue";
import PanelDashboard from "@/appModules/common/view/Dashboard.vue";
import AboutAppBasicInfo from "@/appModules/common/view/AboutAppBasicInfo.vue";

import PanelReportTariff from "@/appModules/report/view/ReportTariff.vue";
import PanelSettings from "@/appModules/common/view/Settings.vue";
import PanelProfileUserApp from "@/appModules/userApp/view/ProfileUserApp.vue";
import PanelDataUserApp from "@/appModules/userApp/view/DataUserApp.vue";
import PanelChangePasswordUserApp from "@/appModules/userApp/view/ChangePasswordUserApp.vue";
import PanelUsers from "@/appModules/userApp/view/Users.vue";
import PanelDeclareTimeRent from "@/appModules/rent/view/DeclareTimeRent.vue";
import InstructionMobileApp from "@/appModules/instruction/view/InstructionMobileApp.vue";
import PanelCreateUserApp from "@/appModules/userApp/view/CreateUserApp.vue";
import PanelAccessToServiceUserApp from "@/appModules/userApp/view/AccessToServiceUserApp.vue";
import PanelTariffs from "@/appModules/report/view/Tariffs.vue";
import PanelTariff from "@/appModules/report/view/Tariff.vue";
import PanelProduct from "@/appModules/product/view/Product.vue";
import PanelProducts from "@/appModules/product/view/Products.vue";
import PanelClientVerification from "@/appModules/service/view/ClientVerification.vue";
import PanelWarehouse from "@/appModules/warehouse/view/Warehouse.vue";
import PanelWarehouses from "@/appModules/warehouse/view/Warehouses.vue";
import PanelCategory from "@/appModules/category/view/Category.vue";
import PanelCategories from "@/appModules/category/view/Categories.vue";
import PanelDocument from "@/appModules/document/view/Document.vue";
import PanelDocuments from "@/appModules/document/view/Documents.vue";
import PanelRents from "@/appModules/rent/view/Rents.vue";
import PanelRent from "@/appModules/rent/view/Rent.vue";
import { useLoginStore } from "@/appModules/service/store/LoginStore";

const routes = [
   {
      name: "appIndex",
      path: "/",
      component: AppIndex,
   },
   {
      name: "agreement",
      path: "/agreement",
      component: Agreement,
      meta: {
         title: i18n.global.t("Regulations"),
      },
   },
   {
      name: "about",
      path: "/about",
      component: About,
      meta: {
         title: i18n.global.t("About"),
      },
   },
   {
      name: "login",
      path: "/login",
      component: Login,
      meta: {
         title: i18n.global.t("LogIn"),
      },
   },
   {
      name: "registration",
      path: "/registration",
      component: Registration,
      meta: {
         title: i18n.global.t("Registration"),
      },
   },
   {
      name: "resetPassword",
      path: "/reset-password",
      component: ResetPassword,
      meta: {
         title: i18n.global.t("PasswordReset"),
      },
   },
   {
      name: "panelDashboard",
      path: "/p/dashboard",
      component: PanelDashboard,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Dashboard"),
      },
   },
   {
      name: "aboutAppBasicInfo",
      path: "/about/app-basic-info",
      component: AboutAppBasicInfo,
   },
   {
      name: "panelReportTariff",
      path: "/p/report-tariff",
      component: PanelReportTariff,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelSettings",
      path: "/p/settings",
      component: PanelSettings,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Settings"),
      },
   },
   {
      name: "panelProfileUserApp",
      path: "/p/users/:id",
      component: PanelProfileUserApp,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelUsers",
      path: "/p/users",
      component: PanelUsers,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Employees"),
      },
   },
   {
      name: "panelDeclareTimeRent",
      path: "/p/declare-time-rent",
      component: PanelDeclareTimeRent,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "instructionMobileApp",
      path: "/instruction-mobile-app",
      component: InstructionMobileApp,
   },
   {
      name: "panelDataUserApp",
      path: "/p/users/:id/data",
      component: PanelDataUserApp,
      meta: {
         requiresAuth: true,
         meta: {
            requiresAuth: true,
            title: i18n.global.t("DataEdit"),
         },
      },
   },
   {
      name: "panelChangePasswordUserApp",
      path: "/p/users/:id/password-change",
      component: PanelChangePasswordUserApp,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("PasswordEdit"),
      },
   },
   {
      name: "panelCreateUserApp",
      path: "/p/users/new",
      component: PanelCreateUserApp,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("NewClientProfile"),
      },
   },
   {
      name: "panelAccessToServiceUserApp",
      path: "/p/users/:id/new-access",
      component: PanelAccessToServiceUserApp,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("AccessToTheSite"),
      },
   },
   {
      name: "panelClientVerification",
      path: "/active-account",
      component: PanelClientVerification,
      title: i18n.global.t("ClientVerification"),
   },
   {
      name: "panelTariffs",
      path: "/p/tariffs",
      component: PanelTariffs,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelTariffNew",
      path: "/p/tariffs/new",
      component: PanelTariff,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelProduct",
      path: "/p/products/:id",
      component: PanelProduct,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelProducts",
      path: "/p/products",
      component: PanelProducts,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Products"),
      },
   },
   {
      name: "panelCreateProduct",
      path: "/p/products/new",
      component: PanelProduct,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("AddNewProduct"),
      },
   },
   // {
   //    name: "panelProductItem",
   //    path: "/p/product-items/:id_item",
   //    component: PanelProductItem,
   //    meta: {
   //       requiresAuth: true,
   //    },
   // },
   // {
   //    name: "panelProductItems",
   //    path: "/p/product/:id/items",
   //    component: PanelProductItems,
   //    meta: {
   //       requiresAuth: true,
   //    },
   // },
   {
      name: "panelCreateWarehouse",
      path: "/p/warehouses/new",
      component: PanelWarehouse,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("AddNewWarehouse"),
      },
   },
   {
      name: "panelWarehouses",
      path: "/p/warehouses",
      component: PanelWarehouses,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Warehouses"),
      },
   },
   {
      name: "panelWarehouse",
      path: "/p/warehouses/:id",
      component: PanelWarehouse,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelCreateCategory",
      path: "/p/categories/new",
      component: PanelCategory,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("AddNewCategory"),
      },
   },
   {
      name: "panelCategories",
      path: "/p/categories",
      component: PanelCategories,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Categories"),
      },
   },
   {
      name: "panelCategory",
      path: "/p/categories/:id",
      component: PanelCategory,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelCreateDocument",
      path: "/p/documents/new",
      component: PanelDocument,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("AddNewDocumentWizard"),
      },
   },
   {
      name: "panelDocument",
      path: "/p/documents/:id",
      component: PanelDocument,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelDocuments",
      path: "/p/documents",
      component: PanelDocuments,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Documents"),
      },
   },
   {
      name: "panelRent",
      path: "/p/rents/:id",
      component: PanelRent,
      meta: {
         requiresAuth: true,
      },
   },
   {
      name: "panelRents",
      path: "/p/rents",
      component: PanelRents,
      meta: {
         requiresAuth: true,
         title: i18n.global.t("Rentals"),
      },
   },
];

const router = createRouter({
   history: createWebHistory(),
   routes,
});

router.beforeEach((to, from, next) => {
   const loginStore = useLoginStore();

   if (to.meta.requiresAuth) {
      if (loginStore.loggedIn) {
         next();
         return;
      } else {
         next("/");
      }
   }
   next();
   return;
});

export default router;
