
import { defineComponent } from "vue";

export default defineComponent({
   name: "LoadBar",
   props: {
      loading: Boolean,
      errored: {
         type: Boolean,
         default: false,
      },
      noDataToDisplay: {
         type: Boolean,
         default: false,
      },
      sending: {
         type: Boolean,
         default: false,
      },
      isSmallSize: {
         type: Boolean,
         default: false,
      },
   },
});
