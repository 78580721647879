import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "full-view" }
const _hoisted_2 = { class: "history-bar" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = { class: "breadcrumb-item" }
const _hoisted_7 = {
  key: 0,
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_8 = {
  key: 1,
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_9 = { class: "container mt-3 d-flex flex-column justify-content-center" }
const _hoisted_10 = {
  key: 0,
  class: "position-absolute top-50 start-50 translate-middle"
}
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "row text-center" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "display-5 text-success" }
const _hoisted_15 = { class: "row mt-3 mt-md-3 text-center" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "text-secondary" }
const _hoisted_18 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = {
  key: 0,
  class: "col-12"
}
const _hoisted_24 = { class: "row tab-separator" }
const _hoisted_25 = { class: "d-block d-lg-none d-xl-none d-xxl-none" }
const _hoisted_26 = { class: "d-none d-lg-block d-xl-block d-xxl-block" }
const _hoisted_27 = { class: "d-block d-lg-none d-xl-none d-xxl-none" }
const _hoisted_28 = { class: "d-none d-lg-block d-xl-block d-xxl-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_GeneralDocumentPanel = _resolveComponent("GeneralDocumentPanel")!
  const _component_DocumentPositionsPanel = _resolveComponent("DocumentPositionsPanel")!
  const _component_NavigationWizardButtons = _resolveComponent("NavigationWizardButtons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _createElementVNode("ol", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, { to: { name: 'panelDocuments' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Documents")), 1)
                ]),
                _: 1
              })
            ]),
            (_ctx.uuid)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString(_ctx.$t("EditDocument")), 1))
              : (_openBlock(), _createElementBlock("li", _hoisted_8, _toDisplayString(_ctx.$t("AddNewDocumentWizard")), 1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        noDataToDisplay: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "noDataToDisplay"]),
      (_ctx.getPageParams.created.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("h1", _hoisted_14, _toDisplayString(_ctx.$t("NewDocumentAdded")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("DoYouWantNow")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("button", {
                    class: "btn btn-outline-success btn-lg",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNewDocument()))
                  }, _toDisplayString(_ctx.$t("AddAnotherNewDocument")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_router_link, {
                    class: "btn btn-outline-primary btn-lg text-center",
                    to: { name: 'panelDocuments' }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("ShowDocumentList")), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_22, [
            (!_ctx.getPageParams.loadingData.value && _ctx.isDataLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-3 text-center", _ctx.getClassTab(_ctx.step, _ctx.numberGeneralDocumentPanel, _ctx.viewModeEnum)]),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickSwitchTab(_ctx.numberGeneralDocumentPanel)))
                    }, [
                      _createElementVNode("span", _hoisted_25, [
                        _createVNode(_component_font_awesome_icon, { icon: "circle-info" })
                      ]),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("General")), 1)
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-3 text-center", _ctx.getClassTab(_ctx.step, _ctx.numberPositionsDocumentPanel, _ctx.viewModeEnum)]),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickSwitchTab(_ctx.numberPositionsDocumentPanel)))
                    }, [
                      _createElementVNode("span", _hoisted_27, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: "list-ol",
                          size: "1x"
                        })
                      ]),
                      _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("Positions")), 1)
                    ], 2)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.step === _ctx.numberGeneralDocumentPanel)
              ? (_openBlock(), _createBlock(_component_GeneralDocumentPanel, {
                  key: 1,
                  ref: "generalDocumentPanel",
                  document: _ctx.getDocument
                }, null, 8, ["document"]))
              : _createCommentVNode("", true),
            (_ctx.step === _ctx.numberPositionsDocumentPanel)
              ? (_openBlock(), _createBlock(_component_DocumentPositionsPanel, {
                  key: 2,
                  ref: "documentPositionsPanel",
                  document: _ctx.getDocument
                }, null, 8, ["document"]))
              : _createCommentVNode("", true),
            _createVNode(_component_NavigationWizardButtons, {
              viewMode: _ctx.viewModeEnum,
              maxStep: _ctx.numberPositionsDocumentPanel,
              currentStep: _ctx.step,
              savingData: _ctx.getPageParams.loadingData.value,
              onOnBackStepClick: _ctx.onBackStepClick,
              onOnNextStepClick: _ctx.onNextStepClick,
              onOnSaveClick: _ctx.submitSave
            }, null, 8, ["viewMode", "maxStep", "currentStep", "savingData", "onOnBackStepClick", "onOnNextStepClick", "onOnSaveClick"])
          ]))
    ])
  ]))
}