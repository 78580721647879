
import { PropType, defineComponent, ref, watchEffect } from "vue";
import ItemInput from "../../common/component/ItemInput.vue";
import ItemInputCollapseResults from "../../common/component/ItemInputCollapseResults.vue";
import WarehousesModal from "@/appModules/warehouse/view/WarehousesModal.vue";
import WarehouseModal from "@/appModules/warehouse/view/WarehouseModal.vue";
import { placeholder } from "@babel/types";
import WarehouseDto from "@/appModules/warehouse/domain/dto/WarehouseDto";
import { useWarehouseStore } from "@/appModules/warehouse/store/WarehouseStore";
import { useWarehousesStore } from "../store/WarehousesStore";
import { storeToRefs } from "pinia";

export default defineComponent({
   name: "WarehouseItemPanel",
   components: {
      ItemInput,
      WarehousesModal,
      WarehouseModal,
      ItemInputCollapseResults,
   },
   props: {
      name: {
         type: String,
         required: true,
      },
      label: {
         type: String,
      },
      placeholder: {
         type: String,
      },
      isRequiredStyle: {
         type: Boolean,
         default: false,
      },
      warehouseExists: {
         type: Boolean,
         required: true,
      },
      errors: {
         type: Object,
      },
      warehouse: {
         type: Object as PropType<WarehouseDto>,
         required: true,
      },
      isReadOnly: {
         type: Boolean,
         required: true,
      },
   },
   emits: ["selectWarehouse", "cancelWarehouse"],
   setup(props, { emit }) {
      const warehousesModal = ref<typeof WarehousesModal>();
      const warehouseModal = ref<typeof WarehouseModal>();
      const itemInput = ref<typeof ItemInput>();
      const warehouseStore = useWarehouseStore();
      const { resetToInitial } = warehouseStore;
      const warehousesStore = useWarehousesStore();
      const { fetchPaginationData } = warehousesStore;
      const { getPageDto, getPageParams } = storeToRefs(warehousesStore);
      const collapse = ref(false);
      const maxResultsNumber = 10;
      const searchByLength = ref(0);
      const warehouseName = ref("");

      watchEffect(() => (warehouseName.value = props.warehouse.name));

      function searchWarehouse() {
         warehousesModal.value?.openModal();
      }

      function addWarehouse() {
         warehouseModal.value?.openModal();
         resetToInitial();
      }

      function editWarehouse() {
         warehouseModal.value?.openModal(props.warehouse.uuid);
      }

      function cancelWarehouse() {
         emit("cancelWarehouse");
         warehouseName.value = "";
         warehousesStore.resetToInitial();
      }

      function selectWarehouse(warehouse: WarehouseDto) {
         warehouseName.value = warehouse.name;
         collapse.value = false;
         warehousesStore.resetToInitial();
         emit("selectWarehouse", warehouse);
      }

      async function showValue(searchBy: string) {
         searchByLength.value = searchBy.length;
         if (searchByLength.value >= 3) {
            await fetchPaginationData(0, maxResultsNumber, searchBy);
         }
      }

      function setCollapse(isFocus: boolean) {
         collapse.value = isFocus;
         if (!isFocus) {
            searchByLength.value = 0;
            if (props.warehouse.uuid.length == 0) {
               itemInput.value?.resetField();
            }
         }
      }

      return {
         warehousesModal,
         warehouseModal,
         getPageDto,
         collapse,
         maxResultsNumber,
         searchByLength,
         warehouseName,
         getPageParams,
         itemInput,
         addWarehouse,
         searchWarehouse,
         cancelWarehouse,
         selectWarehouse,
         editWarehouse,
         showValue,
         setCollapse,
      };
   },
});
