
import { PropType, defineComponent, toRefs } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import RentDto from "../domain/dto/RentDto";
import DateTimeService from "@/appModules/common/domain/service/DateTimeService";

export default defineComponent({
   name: "RentListItem",
   components: {},
   props: {
      rentDto: {
         type: Object as PropType<RentDto>,
         required: true,
      },
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectRent"],
   setup(props, { emit }) {
      const { rentDto: rentRef } = toRefs(props);
      const { typeGrid } = useBreakpointsGrid();
      const dateTimeService = new DateTimeService();

      function selectRent() {
         emit("selectRent", rentRef.value);
      }

      return {
         rentRef,
         typeGrid,
         dateTimeService,
         equalsOrGreaterGrid,
         selectRent,
      };
   },
});
