import { getAuthHeader } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import axios from "axios";
import RentDto from "../dto/RentDto";
import CreateRentDto from "../dto/CreateRentDto";
import RentPaginationDto from "../dto/RentPaginationDto";

const urlApi = "/api/rents/v1/";

export default class RentApi {
   findById(uuid: string | string[]) {
      return axios.get<RentDto>(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   findByPagination(query: string) {
      return axios.get<RentPaginationDto>(process.env.VUE_APP_API_DOMAIN + urlApi + query, {
         headers: getAuthHeader(),
      });
   }
}
