
import { defineComponent, PropType } from "vue";
import { ViewModeEnum } from "../domain/enumerated/ViewModeEnum";

export default defineComponent({
   name: "NavigationWizardButtons",
   props: {
      viewMode: {
         type: Number as PropType<ViewModeEnum>,
         required: true,
      },
      currentStep: {
         type: Number,
         required: true,
      },
      maxStep: {
         type: Number,
         required: true,
      },
      savingData: {
         type: Boolean,
         values: false,
         required: true,
      },
   },
   emits: ["onBackStepClick", "onNextStepClick", "onSaveClick"],
   setup(props, { emit }) {
      function isNewViewMode() {
         return props.viewMode === ViewModeEnum.NEW;
      }

      function backStepClick() {
         emit("onBackStepClick");
      }

      function nextStepClick() {
         emit("onNextStepClick");
      }

      function saveClick() {
         emit("onSaveClick");
      }

      return {
         isNewViewMode,
         backStepClick,
         nextStepClick,
         saveClick,
      };
   },
});
