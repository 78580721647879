
import { defineComponent } from "vue";
import { useRentStore } from "../store/RentStore";
import { storeToRefs } from "pinia";
import { toStringEnum } from "@/appModules/common/domain/service/format";
import { WarehouseTypeEnum } from "@/appModules/warehouse/domain/enumerated/WarehouseTypeEnum";
import DateTimeService from "@/appModules/common/domain/service/DateTimeService";

export default defineComponent({
   name: "RentProfileForm",
   components: {},
   setup() {
      const store = useRentStore();
      const { getRent } = storeToRefs(store);
      const dateTimeService = new DateTimeService();

      return { getRent, WarehouseTypeEnum, dateTimeService, toStringEnum };
   },
});
