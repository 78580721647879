import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row m-3" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  for: "companyName",
  class: "form-label"
}
const _hoisted_7 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  for: "nip",
  class: "form-label"
}
const _hoisted_10 = { class: "input-group" }
const _hoisted_11 = {
  id: "nipAriaDescribedby",
  class: "input-group-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_AddressPanel = _resolveComponent("AddressPanel")!
  const _component_ContactDetailsPanel = _resolveComponent("ContactDetailsPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("CompanyName")), 1),
            _createVNode(_component_Field, {
              name: "companyName",
              type: "text",
              class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.companyName }]),
              placeholder: _ctx.$t('EnterName'),
              modelValue: _ctx.getServiceRegistrationDTO.company.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getServiceRegistrationDTO.company.name) = $event))
            }, null, 8, ["class", "placeholder", "modelValue"]),
            _createVNode(_component_ErrorMessage, {
              name: "companyName",
              class: "invalid-feedback"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("NIP")), 1),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("PL")), 1),
              _createVNode(_component_Field, {
                name: "nip",
                type: "text",
                class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.nip }]),
                placeholder: _ctx.$t('EnterNIP'),
                modelValue: _ctx.getServiceRegistrationDTO.company.nip,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getServiceRegistrationDTO.company.nip) = $event)),
                maxlength: "10"
              }, null, 8, ["class", "placeholder", "modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "nip",
                class: "invalid-feedback"
              })
            ])
          ])
        ])
      ]),
      _createVNode(_component_AddressPanel, {
        addressDto: _ctx.getServiceRegistrationDTO.company.address,
        ref: "addressPanel"
      }, null, 8, ["addressDto"]),
      _createVNode(_component_ContactDetailsPanel, {
        contactDetailsDto: _ctx.getContactDetailsDto,
        ref: "contactDetailsPanel"
      }, null, 8, ["contactDetailsDto"])
    ])
  ]))
}