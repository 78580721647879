
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { useProfileUserAppStore } from "../store/ProfileUserAppStore";
import { getTokenUserApp } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import ProfileHeaderUserApp from "@/appModules/userApp/component/ProfileHeaderUserApp.vue";
import { getFormattedTabTitle } from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "ProfileUserApp",
   components: {
      LoadBar,
      ProfileHeaderUserApp,
   },
   setup() {
      const profileUserAppStore = useProfileUserAppStore();
      const { findById } = profileUserAppStore;
      const { getUserApp, getPageParams } = storeToRefs(profileUserAppStore);
      const route = useRoute();
      const uuid = ref();

      onMounted(async () => {
         uuid.value = route.params.id as string;
         if (uuid.value) {
            await findById(uuid.value);
            document.title = getFormattedTabTitle(`${getUserApp.value.firstName} ${getUserApp.value.lastName}`);
         }
      });

      return {
         getPageParams,
         getUserApp,
         uuid,
         getTokenUserApp,
      };
   },
});
