
import { PropType, defineComponent, toRefs } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import CategoryDto from "../domain/dto/CategoryDto";

export default defineComponent({
   name: "CategoryListItem",
   components: {},
   props: {
      categoryDto: {
         type: Object as PropType<CategoryDto>,
         required: true,
      },
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectCategory"],
   setup(props, { emit }) {
      const { categoryDto: category } = toRefs(props);
      const { typeGrid } = useBreakpointsGrid();

      function selectCategory() {
         emit("selectCategory", category.value);
      }

      return {
         category,
         typeGrid,
         equalsOrGreaterGrid,
         selectCategory,
      };
   },
});
