import { RoleEnum } from "@/appModules/userApp/domain/enumerated/RoleEnum";

export interface ICreateUserApp {
   firstName: string;
   lastName: string;
   account: boolean;
   username: string;
   enabled: boolean;
   password: string;
   confirmPassword: string;
   uuidRoles: RoleEnum[];
}

export default class CreateUserAppDto implements ICreateUserApp {
   constructor(
      public firstName: string = "",
      public lastName: string = "",
      public account: boolean = false,
      public username: string = "",
      public enabled: boolean = false,
      public password: string = "",
      public confirmPassword: string = "",
      public uuidRoles: RoleEnum[] = [RoleEnum.Employee]
   ) {}

   get fullName(): string {
      let rez = "";
      if (this.firstName) rez = this.firstName + " ";

      if (this.lastName) rez = rez + this.lastName;

      return rez;
   }
}
