import CreateUserAppDto from "@/appModules/userApp/domain/dto/CreateUserAppDto";
import CreateCompanyDto from "./CreateCompanyDto";

export interface IServiceRegistration {
   userApp: CreateUserAppDto;
   company: CreateCompanyDto;
   recaptchaTokenKey: string | undefined;
}

export default class ServiceRegistrationDto implements IServiceRegistration {
   constructor(
      public userApp: CreateUserAppDto = new CreateUserAppDto(),
      public company: CreateCompanyDto = new CreateCompanyDto(),
      public recaptchaTokenKey: string | undefined = ""
   ) {}
}
