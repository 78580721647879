
import { PropType, defineComponent, toRefs } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import DocumentDto from "../domain/dto/Document";

export default defineComponent({
   name: "DocumentListItem",
   components: {},
   props: {
      documentDto: {
         type: Object as PropType<DocumentDto>,
         required: true,
      },
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectDocument"],
   setup(props, { emit }) {
      const { documentDto: documentRef } = toRefs(props);
      const { typeGrid } = useBreakpointsGrid();

      function selectDocument() {
         emit("selectDocument", documentRef.value);
      }

      return {
         documentRef,
         typeGrid,
         equalsOrGreaterGrid,
         selectDocument,
      };
   },
});
