import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-483f99bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-column flex-md-row",
  "aria-label": "Page navigation"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["disabled", "value"]
const _hoisted_4 = { class: "pagination justify-content-center col-12 col-md-8" }
const _hoisted_5 = { class: "page-item" }
const _hoisted_6 = ["disabled", "title"]
const _hoisted_7 = { class: "page-item" }
const _hoisted_8 = ["disabled", "title"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  class: "fs-5",
  "aria-hidden": "true"
}
const _hoisted_11 = { class: "page-item" }
const _hoisted_12 = ["disabled", "title"]
const _hoisted_13 = { class: "page-item" }
const _hoisted_14 = ["disabled", "title"]
const _hoisted_15 = { class: "col-12 col-md-2 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.equalsOrGreaterGrid('md', _ctx.typeGrid))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "input-group mb-3 p-1 d-flex align-items-center",
          title: _ctx.$t('ShowNumberOfEvents')
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("ShowEvents")), 1),
          _withDirectives(_createElementVNode("select", {
            class: "text-dark border rounded-3 mx-1 px-1",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageSizeRef) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPageSizeChange(_ctx.pageSizeRef)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (page, index) => {
              return (_openBlock(), _createElementBlock("option", {
                disabled: page === _ctx.pageSizeRef,
                class: _normalizeClass([{ 'bg-disabled': page === _ctx.pageSizeRef }, "text-dark"]),
                value: page,
                key: index
              }, _toDisplayString(page), 11, _hoisted_3))
            }), 128))
          ], 544), [
            [_vModelSelect, _ctx.pageSizeRef]
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("OnPage")), 1)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("button", {
          class: _normalizeClass(["page-link p-1 px-2", { 'bg-disabled': _ctx.isTheFirstPage }]),
          href: "#",
          "aria-label": "First page",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFirstPageClick && _ctx.onFirstPageClick(...args))),
          disabled: _ctx.isTheFirstPage,
          title: _ctx.$t('FirstPage')
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["fs-5", { 'text-muted': _ctx.isTheFirstPage }]),
            "aria-hidden": "true"
          }, "«", 2)
        ], 10, _hoisted_6)
      ]),
      _createElementVNode("li", _hoisted_7, [
        _createElementVNode("button", {
          class: _normalizeClass(["page-link p-1 px-2", { 'bg-disabled': _ctx.isTheFirstPage }]),
          href: "#",
          "aria-label": "Previous",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBackPageClick && _ctx.onBackPageClick(...args))),
          disabled: _ctx.isTheFirstPage,
          title: _ctx.$t('PreviousPage')
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["fs-5", { 'text-muted': _ctx.isTheFirstPage }]),
            "aria-hidden": "true"
          }, "‹", 2)
        ], 10, _hoisted_8)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (page, i) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["page-item", { active: _ctx.isPageActive(page) }]),
          key: i
        }, [
          _createElementVNode("button", {
            class: "page-link p-1 px-2",
            href: "#",
            "aria-label": "Page",
            onClick: ($event: any) => (_ctx.onPageClick(page))
          }, [
            _createElementVNode("span", _hoisted_10, _toDisplayString(page), 1)
          ], 8, _hoisted_9)
        ], 2))
      }), 128)),
      _createElementVNode("li", _hoisted_11, [
        _createElementVNode("button", {
          class: _normalizeClass(["page-link p-1 px-2", { 'bg-disabled': _ctx.isTheLastPage }]),
          href: "#",
          "aria-label": "Next",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onNextPageClick && _ctx.onNextPageClick(...args))),
          disabled: _ctx.isTheLastPage,
          title: _ctx.$t('NextPage')
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["fs-5", { 'text-muted': _ctx.isTheLastPage }]),
            "aria-hidden": "true"
          }, "›", 2)
        ], 10, _hoisted_12)
      ]),
      _createElementVNode("li", _hoisted_13, [
        _createElementVNode("button", {
          class: _normalizeClass(["page-link p-1 px-2", { 'bg-disabled': _ctx.isTheLastPage }]),
          href: "#",
          "aria-label": "Last page",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onLastPageClick && _ctx.onLastPageClick(...args))),
          disabled: _ctx.isTheLastPage,
          title: _ctx.$t('LastPage')
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["fs-5", { 'text-muted': _ctx.isTheLastPage }]),
            "aria-hidden": "true"
          }, "»", 2)
        ], 10, _hoisted_14)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("NumberOfRecords")) + " " + _toDisplayString(_ctx.totalElements), 1)
    ])
  ]))
}