import { getAuthHeader } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import axios from "axios";
import WarehousePaginationDto from "../dto/WarehousePaginationDto";
import WarehouseDto from "../dto/WarehouseDto";
import CreateWarehouseDto from "../dto/CreateWarehouseDto";
import UpdateWarehouseDto from "../dto/UpdateWarehouseDto";

const urlApi = "/api/warehouses/v1/";

export default class WarehouseApi {
   findById(uuid: string | string[]) {
      return axios.get<WarehouseDto>(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   delete(uuid: string | string[]) {
      return axios.delete(process.env.VUE_APP_API_DOMAIN + urlApi + uuid, {
         headers: getAuthHeader(),
      });
   }

   create(dto: CreateWarehouseDto) {
      return axios.post(process.env.VUE_APP_API_DOMAIN + urlApi, dto, {
         headers: getAuthHeader(),
      });
   }

   findByPagination(query: string) {
      return axios.get<WarehousePaginationDto>(process.env.VUE_APP_API_DOMAIN + urlApi + query, {
         headers: getAuthHeader(),
      });
   }

   update(dto: UpdateWarehouseDto, uuid: string | string[]) {
      return axios.put(process.env.VUE_APP_API_DOMAIN + urlApi + `${uuid ? uuid : ""}`, dto, {
         headers: getAuthHeader(),
      });
   }
}
