
import { getTokenUserApp } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import { useLoginStore } from "@/appModules/service/store/LoginStore";
import { defineComponent, onMounted, ref } from "vue";
import UserAppSessionTimerPanel from "@/appModules/service/component/UserAppSessionTimerPanel.vue";
import { i18n } from "@/main";
import { useToast } from "vue-toastification";

export default defineComponent({
   name: "MenuPanel",
   components: { UserAppSessionTimerPanel },
   setup() {
      const authUserLogin = ref("");
      const authUserId = ref("");
      const isActive = ref(false);
      const loginStore = useLoginStore();
      const toast = useToast();

      onMounted(() => {
         fetchClientDetail();
      });

      function fetchClientDetail() {
         let tokenData = getTokenUserApp();

         authUserLogin.value = tokenData.username;
         authUserId.value = tokenData.uuid;
      }

      function logout() {
         loginStore.logOutUser();
         toast.success(i18n.global.t("LogoutSuccessToast"));
      }

      function toggleMenu() {
         isActive.value = !isActive.value;
      }
      return { toggleMenu, authUserLogin, authUserId, isActive, logout };
   },
});
