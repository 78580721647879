import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "full-view" }
const _hoisted_2 = {
  key: 0,
  class: "history-bar"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = { class: "breadcrumb-item" }
const _hoisted_7 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "row ms-sm-2 me-sm-2 d-flex justify-content-between" }
const _hoisted_10 = {
  key: 0,
  class: "col-2 col-md-1 mt-2"
}
const _hoisted_11 = { class: "col-2 col-md-1 mt-2" }
const _hoisted_12 = { class: "col-8 col-sm-8 col-md-4 col-lg-6 mt-2 d-flex align-items-end justify-content-end" }
const _hoisted_13 = { class: "col-12 col-sm-12 col-md-6 col-lg-4 mt-2" }
const _hoisted_14 = { class: "input-group" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = {
  key: 0,
  class: "row mt-4"
}
const _hoisted_17 = { class: "col" }
const _hoisted_18 = {
  key: 0,
  class: "row table-head"
}
const _hoisted_19 = { class: "col-6 ps-4" }
const _hoisted_20 = { class: "col-3" }
const _hoisted_21 = { class: "col-2" }
const _hoisted_22 = { class: "col-1 d-lg-flex justify-content-lg-center" }
const _hoisted_23 = {
  key: 1,
  class: "h3 mt-3 text-secondary text-center"
}
const _hoisted_24 = { class: "col-4 col-md-3 d-lg-none" }
const _hoisted_25 = { class: "col-4 col-md-3 d-lg-none" }
const _hoisted_26 = { class: "text-align-center" }
const _hoisted_27 = { class: "col-4 col-md-3 d-lg-none" }
const _hoisted_28 = { class: "col-4 col-md-3 d-lg-none mt-2 mt-lg-0" }
const _hoisted_29 = {
  key: 0,
  class: "col-8 col-md-9 col-lg-1 my-auto d-lg-flex justify-content-lg-center text-end mt-2 mt-lg-0"
}
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { class: "ps-2" }
const _hoisted_32 = { class: "dropdown" }
const _hoisted_33 = {
  class: "btn",
  type: "button",
  id: "dropdown",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_34 = {
  class: "dropdown-menu",
  "aria-labelledby": "dropdown"
}
const _hoisted_35 = {
  class: "dropdown-item text-danger",
  href: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_SortComboBox = _resolveComponent("SortComboBox")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_PaginationPanel = _resolveComponent("PaginationPanel")!
  const _directive_debounce = _resolveDirective("debounce")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("nav", _hoisted_4, [
              _createElementVNode("ol", _hoisted_5, [
                _createElementVNode("li", _hoisted_6, [
                  _createVNode(_component_router_link, { to: { name: 'panelSettings' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Settings")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t("Employees")), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (!_ctx.isModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_router_link, {
                class: "btn btn-outline-success",
                to: { name: 'panelCreateUserApp' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "plus",
                    size: "1x"
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-outline-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showValue && _ctx.showValue(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "redo-alt",
              size: "1x"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_SortComboBox, {
            sortOptions: _ctx.sortOptions,
            defaultSort: _ctx.defaultSort,
            onOnChangeSort: _ctx.sortData
          }, null, 8, ["sortOptions", "defaultSort", "onOnChangeSort"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchBy) = $event)),
              type: "text",
              placeholder: _ctx.$t('UsersSearchBy')
            }, null, 8, _hoisted_15), [
              [_directive_debounce, _ctx.showValue, "800ms"],
              [_vModelText, _ctx.searchBy]
            ])
          ])
        ])
      ]),
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        noDataToDisplay: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "noDataToDisplay"]),
      (!_ctx.getPageParams.noDataToDisplay.value && !_ctx.getPageParams.errorLoadData.value && !_ctx.getPageParams.loadingData.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              (_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid))
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("FirstNameAndLastName")), 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("Login")), 1),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("Role")), 1),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("Action")), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.getPageDto.content?.length === 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_23, [
                    _createElementVNode("i", null, _toDisplayString(_ctx.$t("Absence")), 1)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPageDto.content, (user) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: user.uuid,
                  class: "row table-body"
                }, [
                  _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("FirstNameAndLastName")) + ":", 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-8 col-md-9 col-lg-6 ps-4", { 'text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
                  }, [
                    _createVNode(_component_router_link, {
                      to: { name: 'panelProfileUserApp', params: { id: user.uuid } },
                      class: "table-a fs-5 text-success text-decoration-none"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(user.lastName) + " " + _toDisplayString(user.firstName), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ], 2),
                  _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("Login")) + ":", 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-8 col-md-9 col-lg-3 my-auto", { 'fw-bold text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
                  }, [
                    _createElementVNode("span", _hoisted_26, _toDisplayString(user.username), 1)
                  ], 2),
                  _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("Role")) + ":", 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-8 col-md-9 col-lg-2 my-auto", { 'fw-bold text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(user.roles, (role, index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: index,
                        class: "badge bg-secondary bg-opacity-25 text-dark ms-1"
                      }, _toDisplayString(_ctx.toStringRoleEnum(role.uuid, false)), 1))
                    }), 128))
                  ], 2),
                  _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("Action")) + ":", 1),
                  (_ctx.isModal)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                        _createElementVNode("button", {
                          class: "btn btn-primary",
                          onClick: ($event: any) => (_ctx.selectUserApp(user))
                        }, _toDisplayString(_ctx.$t("Choose")), 9, _hoisted_30)
                      ]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["col-8 col-md-9 col-lg-1 my-auto d-lg-flex justify-content-lg-center", { 'fw-bold text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
                      }, [
                        _createElementVNode("span", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createElementVNode("div", _hoisted_33, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: "ellipsis",
                                size: "2x",
                                class: "text-success"
                              })
                            ]),
                            _createElementVNode("ul", _hoisted_34, [
                              _createElementVNode("li", null, [
                                _createVNode(_component_router_link, {
                                  to: { name: 'panelProfileUserApp', params: { id: user.uuid } },
                                  class: "dropdown-item text-success text-decoration-none"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Edit")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("a", _hoisted_35, _toDisplayString(_ctx.$t("Delete")), 1)
                              ])
                            ])
                          ])
                        ])
                      ], 2))
                ]))
              }), 128))
            ]),
            (_ctx.getPageDto.content?.length > 0)
              ? (_openBlock(), _createBlock(_component_PaginationPanel, {
                  key: 0,
                  class: "mt-3",
                  pageSize: _ctx.getPageDto.size,
                  totalPages: _ctx.getPageDto.totalPages,
                  totalElements: _ctx.getPageDto.totalElements,
                  pageNumber: _ctx.getPageDto.number,
                  isTheFirstPage: _ctx.getPageDto.first,
                  isTheLastPage: _ctx.getPageDto.last,
                  onOnChangePagination: _ctx.changePagination
                }, null, 8, ["pageSize", "totalPages", "totalElements", "pageNumber", "isTheFirstPage", "isTheLastPage", "onOnChangePagination"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}