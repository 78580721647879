import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitlePage = _resolveComponent("TitlePage")!
  const _component_AboutAppBasicInfoMobile = _resolveComponent("AboutAppBasicInfoMobile")!
  const _component_AboutAppBasicInfoWeb = _resolveComponent("AboutAppBasicInfoWeb")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TitlePage, {
      title: "Elementy systemu",
      subTitle: "NAJWAŻNIEJSZE INFORMACJE O APLIKACJACH WCHODZACYCH W SKŁAD SYSTEMU"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AboutAppBasicInfoMobile, {
        id: "mobile_app",
        class: "mt-5"
      }),
      _createVNode(_component_AboutAppBasicInfoWeb, {
        id: "web_app",
        class: "mt-5"
      })
    ])
  ], 64))
}