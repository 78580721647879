import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "text-muted mt-3" }
const _hoisted_4 = { class: "row m-2" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "h5" }
const _hoisted_7 = {
  class: "bg-success text-light rounded px-2 py-1",
  id: "no_limit_packet"
}
const _hoisted_8 = { class: "row mt-4" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "text-muted" }
const _hoisted_11 = { class: "row m-3" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "text-muted" }
const _hoisted_16 = { class: "row mt-3" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { class: "text-muted" }
const _hoisted_19 = ["aria-label"]
const _hoisted_20 = {
  key: 0,
  class: "row m-3"
}
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { class: "row form-group" }
const _hoisted_23 = { class: "col-sm-12 mt-2" }
const _hoisted_24 = { class: "form-check" }
const _hoisted_25 = {
  class: "form-check-label",
  for: "agreementCheckbox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAppRegistrationForm = _resolveComponent("UserAppRegistrationForm")!
  const _component_CompanyRegistrationForm = _resolveComponent("CompanyRegistrationForm")!
  const _component_AddressPanel = _resolveComponent("AddressPanel")!
  const _component_Field = _resolveComponent("Field")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t("Package")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("NoLimit").toUpperCase()), 1),
          _createTextVNode(_toDisplayString(_ctx.$t("FreeFor3Months")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.$t("DataForAdministrator")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_UserAppRegistrationForm, { ref: "userAppRegistrationForm" }, null, 512)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("h5", _hoisted_15, _toDisplayString(_ctx.$t("CompanyDetailsInvoiceDetails")), 1)
      ])
    ]),
    _createVNode(_component_CompanyRegistrationForm, { ref: "companyRegistrationForm" }, null, 512),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("h5", _hoisted_18, [
          _createTextVNode(_toDisplayString(_ctx.$t("RentalAddress")) + " ", 1),
          _createElementVNode("div", {
            class: "btn-group",
            role: "group",
            "aria-label": _ctx.$t('RentalAddress')
          }, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass([!_ctx.otherAddressRental ? _ctx.activeButtonClass : _ctx.inactiveButtonClass]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.otherAddressRental = false))
            }, _toDisplayString(_ctx.$t("SameAsCompanyAddress")), 3),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass([_ctx.otherAddressRental ? _ctx.activeButtonClass : _ctx.inactiveButtonClass]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.otherAddressRental = true))
            }, _toDisplayString(_ctx.$t("OtherThanCompanyAddress")), 3)
          ], 8, _hoisted_19)
        ])
      ])
    ]),
    (_ctx.otherAddressRental === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_AddressPanel, {
              addressDto: _ctx.store.serviceRegistrationDto.company.addressToInvoice,
              ref: "addressPanel"
            }, null, 8, ["addressDto"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_Field, {
            value: false,
            name: "agreementCheckbox",
            type: "checkbox",
            class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.errors.agreementCheckbox }])
          }, null, 8, ["class"]),
          _createElementVNode("label", _hoisted_25, [
            _createTextVNode(_toDisplayString(_ctx.$t("Accept")) + " ", 1),
            _createVNode(_component_router_link, {
              class: "card-link",
              to: "/agreement"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Regulations")), 1)
              ]),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("ProvisionOfServiceAndUndertakeToComplyWithIt")), 1)
          ]),
          _createVNode(_component_ErrorMessage, {
            name: "agreementCheckbox",
            class: "invalid-feedback"
          })
        ])
      ])
    ])
  ], 64))
}