import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import { i18n } from "@/main";
import ServiceRegistrationDto from "../domain/dto/ServiceRegistrationDto";
import ServiceApi from "@/appModules/service/domain/repository/ServiceApi";
import ErrorItem from "../domain/command/ErrorItem";
import { ErrorItemCodeEnum } from "../domain/enumerated/ErrorItemCodeEnum";
import { ContactDetailsDto } from "../domain/dto/ContactDetailsDto";

const service = new ServiceApi();
const toast = useToast();

export const useCompanyRegistrationStore = defineStore({
   id: "companyRegistration",
   state: () => ({
      serviceRegistrationDto: new ServiceRegistrationDto(),
      contactDetailsDto: new ContactDetailsDto(),
      registrationSuccess: false as boolean,
      loading: false as boolean,
      error: false as boolean,
      noDataToDisplay: false as boolean,
      errors: [] as ErrorItem[],
   }),
   getters: {
      getServiceRegistrationDTO: (state) => state.serviceRegistrationDto,
      getContactDetailsDto: (state) => state.contactDetailsDto,
      isRegistrationSuccess: (state) => state.registrationSuccess,
      isLoading: (state) => state.loading,
      isError: (state) => state.error,
      isNoDataToDisplay: (state) => state.noDataToDisplay,
      getErrors(): ErrorItem[] {
         return this.errors;
      },
   },
   actions: {
      async clientRegistration() {
         this.loading = true;
         this.serviceRegistrationDto.company.firstName = this.serviceRegistrationDto.userApp.firstName;
         this.serviceRegistrationDto.company.lastName = this.serviceRegistrationDto.userApp.lastName;
         this.serviceRegistrationDto.company.numberPhone = this.contactDetailsDto.numberPhone;
         this.serviceRegistrationDto.company.email = this.contactDetailsDto.email;

         service
            .create(this.serviceRegistrationDto)
            .then(
               (success) => {
                  if (success.status === 201) {
                     this.registrationSuccess = true;
                     toast.success(i18n.global.t("RegistractionWasSuccessful"));
                     this.serviceRegistrationDto = new ServiceRegistrationDto();
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.registrationSuccess = false;
                        this.errors = error.response.data["invalid-params"];
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch((exception) => {
               // message.value = i18n.global.t("ErrorConnectToServer");
               // toast.error(message.value);
            })
            .finally(() => {
               this.loading = false;
               this.serviceRegistrationDto = new ServiceRegistrationDto();
            });
      },

      resetRegistrationSuccess() {
         this.registrationSuccess = false;
      },

      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === ErrorItemCodeEnum.DuplicateNip) {
               setFieldError("nip", i18n.global.t("CompanyWithThisNIPIsRegistered"));
            }
            if (e.code === ErrorItemCodeEnum.DuplicateLogin) {
               setFieldError("login", i18n.global.t("CompanyWithThisLoginIsRegistered"));
            }
         }
      },
   },
});
