import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "position-relative full-view" }
const _hoisted_2 = { class: "history-bar" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = { class: "breadcrumb-item active" }
const _hoisted_7 = { class: "container btn-group-vertical d-flex align-items-center" }
const _hoisted_8 = { class: "col-10 d-flex flex-column flex-lg-row" }
const _hoisted_9 = {
  key: 0,
  class: "col-12 col-lg-6 mt-5 text-center pe-lg-3"
}
const _hoisted_10 = { class: "fs-4 mx-2" }
const _hoisted_11 = { class: "col-12 col-lg-6 mt-5 ps-lg-3" }
const _hoisted_12 = { class: "fs-4 mx-2" }
const _hoisted_13 = { class: "col-10 d-flex flex-column flex-lg-row" }
const _hoisted_14 = {
  key: 0,
  class: "col-12 col-lg-6 mt-5 text-center pe-lg-3"
}
const _hoisted_15 = { class: "fs-4 mx-2" }
const _hoisted_16 = { class: "col-12 col-lg-6 mt-5 ps-lg-3" }
const _hoisted_17 = { class: "fs-4 mx-2" }
const _hoisted_18 = { class: "col-12 mt-5 d-flex align-items-center justify-content-center" }
const _hoisted_19 = { class: "fs-4 mx-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _createElementVNode("ol", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.$t("Settings")), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.getIdLoggedApplicationUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_router_link, {
                class: "w-100 d-flex align-items-center justify-content-center flex-wrap btn btn-lg border btn-outline-secondary p-3",
                to: { name: 'panelProfileUserApp', params: { id: _ctx.getIdLoggedApplicationUser } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "mx-2",
                    icon: "user",
                    size: "2x"
                  }),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("MyProfile").toUpperCase()), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_router_link, {
            class: "w-100 d-flex align-items-center justify-content-center flex-wrap btn btn-lg border btn-outline-secondary p-3",
            to: { name: 'panelUsers' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                class: "mx-2",
                icon: "users",
                size: "2x"
              }),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("Employees").toUpperCase()), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        (_ctx.getIdLoggedApplicationUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_router_link, {
                class: "w-100 d-flex align-items-center justify-content-center flex-wrap btn btn-lg border btn-outline-secondary p-3",
                to: { name: 'panelWarehouses' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "mx-2",
                    icon: "warehouse",
                    size: "2x"
                  }),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("Warehouses").toUpperCase()), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_router_link, {
            class: "w-100 d-flex align-items-center justify-content-center flex-wrap btn btn-lg border btn-outline-secondary p-3",
            to: { name: 'panelCategories' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                class: "mx-2",
                icon: "sitemap",
                size: "2x"
              }),
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("Categories").toUpperCase()), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_router_link, {
          class: "col-10 d-flex align-items-center justify-content-center flex-wrap btn btn-lg border btn-outline-secondary p-3",
          to: { name: 'instructionMobileApp' }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              class: "mx-2",
              icon: "book",
              size: "2x"
            }),
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("MobileAppManual").toUpperCase()), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}