
import { storeToRefs } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { defineComponent, onMounted, ref } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import LoadBar from "../../common/component/LoadBar.vue";
import DeleteItemModal from "../../common/component/DeleteItemModal.vue";
import PaginationPanel from "../../common/component/PaginationPanel.vue";
import SortComboBox from "../../common/component/SortComboBox.vue";
import SortingPaginationParam from "../../common/domain/command/SortingPaginationParam";
import { useProductsStore } from "../store/ProductsStore";
import EnumService from "@/appModules/common/domain/service/EnumService";
import { toStringEnum } from "@/appModules/common/domain/service/format";
import { useProductStore } from "@/appModules/product/store/ProductStore";
import ProductDto from "@/appModules/product/domain/dto/ProductDto";
import ProductListItem from "../component/ProductListItem.vue";
import PaginationService from "../../common/domain/service/PaginationService";
import CategoryFilterItemPanel from "@/appModules/category/component/CategoryFilterItemPanel.vue";
import CategoryDto from "@/appModules/category/domain/dto/CategoryDto";

export default defineComponent({
   name: "Products",
   components: {
      LoadBar,
      PaginationPanel,
      SortComboBox,
      DeleteItemModal,
      ProductListItem,
      CategoryFilterItemPanel,
   },
   directives: {
      debounce: vue3Debounce({ lock: true }),
   },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectProduct"],
   setup(props, { emit }) {
      const productsStore = useProductsStore();
      const productStore = useProductStore();
      const { getPageParams, getPageDto, sortOptions } = storeToRefs(productsStore);
      const { fetchPaginationData } = productsStore;
      const { typeGrid } = useBreakpointsGrid();
      const defaultSort = sortOptions.value[0];
      const searchBy = ref("");
      const selectedProduct = ref(new ProductDto());
      const category = ref(new CategoryDto());

      onMounted(() => {
         getPageParams.value.sort.value = defaultSort;
         if (!props.isModal) showValue();
      });

      function sortData(sortOption: SortingPaginationParam) {
         getPageParams.value.sort.value = sortOption;
         showValue();
      }

      function changePagination(page: number, size: number) {
         fetchPaginationData(page, size, searchBy.value);
      }

      function showValue() {
         fetchPaginationData(getPageDto.value.number, getPageDto.value.size, searchBy.value, category?.value.uuid);
      }

      async function deleteProduct(uuid: string | string[]) {
         await productStore.delete(uuid);
         fetchPaginationData(new PaginationService().calcPageNumber(getPageDto.value), getPageDto.value.size, searchBy.value, "");
      }

      function selectProduct(product: ProductDto) {
         selectedProduct.value = product;
         emit("selectProduct", product);
      }

      function filterByCategory(categoryDto: CategoryDto) {
         category.value = categoryDto;
         showValue();
      }

      function cancelCategory() {
         category.value = new CategoryDto();
         showValue();
      }

      return {
         selectedProduct,
         getPageDto,
         getPageParams,
         typeGrid,
         sortOptions,
         defaultSort,
         searchBy,
         category,
         EnumService,
         useBreakpointsGrid,
         equalsOrGreaterGrid,
         changePagination,
         sortData,
         showValue,
         toStringEnum,
         deleteProduct,
         selectProduct,
         cancelCategory,
         filterByCategory,
      };
   },
});
