export interface IResetPasswordClientStepTwo {
   password: string;
   confirmPassword: string;
   actionTokenKey: string;
   recaptchaTokenKey: string | undefined;
}

export default class ResetPasswordClientStepTwoDto implements IResetPasswordClientStepTwo {
   constructor(
      public password: string = "",
      public confirmPassword: string = "",
      public actionTokenKey: string = "",
      public recaptchaTokenKey: string | undefined = undefined
   ) {}
}
