
import { defineComponent, ref } from "vue";
import Categories from "../view/Categories.vue";
import CategoryDto from "../domain/dto/CategoryDto";

export default defineComponent({
   name: "CategoriesModal",
   components: {
      Categories,
   },
   emits: ["selectCategory"],
   setup(props, { emit }) {
      const showModal = ref(false);
      const categories = ref<typeof Categories>();

      function openModal() {
         categories.value?.showValue();
         showModal.value = true;
      }

      function selectCategory(category: CategoryDto) {
         showModal.value = false;
         emit("selectCategory", category);
      }

      return {
         categories,
         showModal,
         openModal,
         selectCategory,
      };
   },
});
