
import { defineComponent } from "vue";
import TitlePage from "@/appModules/common/component/TitlePage.vue";
import AboutAppBasicInfoMobile from "@/appModules/common/view/AboutAppBasicInfoMobile.vue";
import AboutAppBasicInfoWeb from "@/appModules/common/view/AboutAppBasicInfoWeb.vue";

export default defineComponent({
   name: "AboutAppBasicInfo",
   components: {
      TitlePage,
      AboutAppBasicInfoMobile,
      AboutAppBasicInfoWeb,
   },
   setup() {
      window.scrollTo(0, 0);
   },
});
