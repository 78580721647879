
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import TitlePage from "@/appModules/common/component/TitlePage.vue";
import ResetPasswordStep1 from "@/appModules/service/component/ResetPasswordStep1.vue";
import ResetPasswordStep2 from "@/appModules/service/component/ResetPasswordStep2.vue";

export default defineComponent({
   name: "ResetPassword",
   components: {
      TitlePage,
      ResetPasswordStep1,
      ResetPasswordStep2,
   },
   setup() {
      const stepNum = ref(1);
      const route = useRoute();

      window.scrollTo(0, 0);
      if (route.query.token && route.query.token.length > 0) {
         stepNum.value = 2;
      }
      return { stepNum };
   },
});
