
import { defineComponent, ref } from "vue";
import Products from "@/appModules/product/view/Products.vue";
import ProductDto from "@/appModules/product/domain/dto/ProductDto";

export default defineComponent({
   name: "ProductsModal",
   components: {
      Products,
   },
   emits: ["selectProduct"],
   setup(props, { emit }) {
      const showModal = ref(false);
      const products = ref<typeof Products>();

      function openModal() {
         products.value?.showValue();
         showModal.value = true;
      }

      function selectProduct(product: ProductDto) {
         showModal.value = false;
         emit("selectProduct", product);
      }

      return {
         products,
         showModal,
         openModal,
         selectProduct,
      };
   },
});
