import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.vertical ? 'btn-group-vertical' : 'btn-group'),
    role: "group"
  }, [
    (_ctx.visibleSearchButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-outline-primary size-button",
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: _ctx.$t('Search'),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.searchButtonOnClick && _ctx.searchButtonOnClick(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "search",
            size: "1x"
          })
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.visibleAddButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "btn btn-outline-success size-button",
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: _ctx.$t('Add'),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addButtonOnClick && _ctx.addButtonOnClick(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "plus",
            size: "1x"
          })
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.visibleEditButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "btn btn-outline-dark size-button",
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: _ctx.$t('Edit'),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editButtonOnClick && _ctx.editButtonOnClick(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "edit",
            size: "1x"
          })
        ], 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.visibleCancelButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: "btn btn-outline-secondary size-button",
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: _ctx.$t('Cancel'),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelButtonOnClick && _ctx.cancelButtonOnClick(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "eraser",
            size: "1x"
          })
        ], 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 2))
}