import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemInput = _resolveComponent("ItemInput")!
  const _component_ItemInputCollapseResults = _resolveComponent("ItemInputCollapseResults")!
  const _component_UsersModal = _resolveComponent("UsersModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ItemInput, {
      name: _ctx.name,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      visibleSearchButton: !_ctx.isReadOnly,
      visibleAddButton: false,
      visibleEditButton: false,
      visibleCancelButton: _ctx.userAppExists,
      isRequiredStyle: false,
      onSearchButtonOnClick: _ctx.searchUserApp,
      onCancelButtonOnClick: _ctx.cancelUserApp,
      onShowValue: _ctx.showValue,
      onSetCollapse: _ctx.setCollapse,
      modelValue: _ctx.userAppName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userAppName) = $event)),
      ref: "itemInput"
    }, null, 8, ["name", "label", "placeholder", "visibleSearchButton", "visibleCancelButton", "onSearchButtonOnClick", "onCancelButtonOnClick", "onShowValue", "onSetCollapse", "modelValue"]),
    _createVNode(_component_ItemInputCollapseResults, {
      name: 'itemInputCollapseResults',
      results: _ctx.getPageDto.content,
      collapse: _ctx.collapse,
      maxResultsNumber: _ctx.maxResultsNumber,
      searchByLength: _ctx.searchByLength,
      params: _ctx.getPageParams,
      onSelectItem: _ctx.selectUserApp
    }, null, 8, ["results", "collapse", "maxResultsNumber", "searchByLength", "params", "onSelectItem"]),
    _createVNode(_component_UsersModal, {
      ref: "usersModal",
      userApp: _ctx.userApp,
      onSelectUserApp: _ctx.selectUserApp
    }, null, 8, ["userApp", "onSelectUserApp"])
  ]))
}