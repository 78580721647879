import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-4 d-lg-none" }
const _hoisted_2 = { class: "col-4 d-lg-none" }
const _hoisted_3 = { class: "col-4 d-lg-none" }
const _hoisted_4 = { class: "col-4 d-lg-none" }
const _hoisted_5 = {
  key: 0,
  class: "col-8 col-md-9 col-lg-1 my-auto d-lg-flex justify-content-lg-center text-end"
}
const _hoisted_6 = { class: "dropdown" }
const _hoisted_7 = {
  class: "btn",
  type: "button",
  id: "dropdown",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_8 = {
  class: "dropdown-menu",
  "aria-labelledby": "dropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("Number")) + ":", 1),
    _createElementVNode("div", {
      class: _normalizeClass(["col-8 col-lg-6 ps-4", { 'text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
    }, [
      _createVNode(_component_router_link, {
        to: { name: 'panelDocument', params: { id: _ctx.documentRef.uuid } },
        class: "table-a fs-5 text-success text-decoration-none"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.documentRef.number), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ], 2),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("ExternalNumber")) + ":", 1),
    _createElementVNode("div", {
      class: _normalizeClass(["col-8 col-lg-3 ps-4", { 'fw-bold text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
    }, _toDisplayString(_ctx.documentRef.externalNumber), 3),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("Type")) + ":", 1),
    _createElementVNode("div", {
      class: _normalizeClass(["col-8 col-lg-2 ps-4", { 'fw-bold text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
    }, _toDisplayString(_ctx.documentRef.type), 3),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("Action")) + ":", 1),
    (_ctx.isModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectDocument && _ctx.selectDocument(...args)))
          }, _toDisplayString(_ctx.$t("Choose")), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["col-8 col-lg-1 my-auto d-lg-flex justify-content-lg-center", { 'fw-bold text-end': !_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid) }])
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_font_awesome_icon, {
                icon: "ellipsis",
                size: "2x",
                class: "text-success"
              })
            ]),
            _createElementVNode("ul", _hoisted_8, [
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: { name: 'panelDocument', params: { id: _ctx.documentRef.uuid } },
                  class: "dropdown-item text-success text-decoration-none"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Edit")), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("button", {
                  class: "dropdown-item text-danger",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#deleteItemModal",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectDocument && _ctx.selectDocument(...args)))
                }, _toDisplayString(_ctx.$t("Delete")), 1)
              ])
            ])
          ])
        ], 2))
  ], 64))
}