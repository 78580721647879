import { defineStore } from "pinia";
import { i18n } from "@/main";
import useListParams from "@/appModules/common/domain/composable/useListParams";
import SortingPaginationParam from "@/appModules/common/domain/command/SortingPaginationParam";
import PaginationParams from "@/appModules/common/domain/command/PaginationParam";
import RentApi from "../domain/repository/RentApi";
import RentPaginationDto from "../domain/dto/RentPaginationDto";
import PaginationService from "@/appModules/common/domain/service/PaginationService";

const api = new RentApi();
const params = useListParams();

export const useRentsStore = defineStore({
   id: "rentsStore",
   state: () => ({
      pageDto: new RentPaginationDto(),
      sortOptions: [
         new SortingPaginationParam(i18n.global.t("DateFromLatest"), "beginDate", true),
         new SortingPaginationParam(i18n.global.t("DateFromOldest"), "beginDate", false),
      ] as SortingPaginationParam[],
      querySearchBy: ["name"] as string[],
      queryFilterBy: ["userAppUuid"] as string[],
      query: "" as string,
   }),
   getters: {
      getPageParams: () => params,
      getPageDto: (state) => state.pageDto,
   },
   actions: {
      async fetchPaginationData(
         pageNumber: number,
         pageSize: number,
         searchBy: string | undefined,
         filterBy: string | undefined = ""
      ) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         const searchByMap = new PaginationService().GetSearchByMap([
            new Map(this.querySearchBy.map((key) => [key, searchBy ? searchBy : ""])),
            new Map(this.queryFilterBy.map((key) => [key, filterBy ? filterBy : ""])),
         ]);

         const paginationParam = new PaginationParams(
            pageNumber,
            pageSize,
            params.sort.value ? params.sort.value : this.sortOptions[0],
            searchByMap,
            this.query
         );
         this.query = paginationParam.getQueryFromParams();

         api.findByPagination(this.query)
            .then(
               (success) => {
                  this.pageDto = success.data;
                  params.noDataToDisplay.value = this.pageDto.content.length === 0;
               },
               (error) => {
                  if (error.response && error.response.status == 401) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },
   },
});
