import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-lg-6 mb-3" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  for: "documentType",
  class: "form-label"
}
const _hoisted_5 = {
  key: 0,
  class: "col-12 col-lg-6 mb-3"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  for: "documentNumber",
  class: "form-label"
}
const _hoisted_8 = { class: "col-12 mb-3" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  for: "documentExternalNumber",
  class: "form-label"
}
const _hoisted_11 = { class: "row mt-3" }
const _hoisted_12 = { class: "col-12 mb-3" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  for: "documentNote",
  class: "form-label"
}
const _hoisted_15 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("DocumentType")), 1),
          _withDirectives(_createElementVNode("input", {
            name: "documentType",
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.documentRef.type) = $event)),
            disabled: ""
          }, null, 512), [
            [_vModelText, _ctx.documentRef.type]
          ])
        ])
      ]),
      (_ctx.documentRef.number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("Number")), 1),
              _withDirectives(_createElementVNode("input", {
                name: "documentNumber",
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.documentRef.number) = $event)),
                disabled: ""
              }, null, 512), [
                [_vModelText, _ctx.documentRef.number]
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("ExternalNumber")), 1),
          _createVNode(_component_Field, {
            id: "documentExternalNumber",
            name: "documentExternalNumber",
            type: "text",
            class: _normalizeClass(["form-control", { 'is-invalid': _ctx.errors.documentExternalNumber }]),
            modelValue: _ctx.documentRef.externalNumber,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.documentRef.externalNumber) = $event)),
            placeholder: _ctx.$t('EnterExternalNumber')
          }, null, 8, ["class", "modelValue", "placeholder"]),
          _createVNode(_component_ErrorMessage, {
            name: "documentExternalNumber",
            class: "invalid-feedback"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("Note")), 1),
          _withDirectives(_createElementVNode("textarea", {
            name: "documentNote",
            type: "text",
            class: "form-control",
            placeholder: _ctx.$t('EnterNote'),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.documentRef.note) = $event)),
            rows: "4"
          }, null, 8, _hoisted_15), [
            [_vModelText, _ctx.documentRef.note]
          ])
        ])
      ])
    ])
  ]))
}