
import { defineComponent } from "vue";

export default defineComponent({
   name: "deleteItemModal",
   props: {
      uuid: {
         type: String,
         required: true,
      },
      name: {
         type: String,
         required: true,
      },
      bodyText: {
         type: String,
         required: true,
      },
   },
   emits: ["deleteItem"],
   setup(props, { emit }) {
      function deleteItem(uuid: string | string[]) {
         emit("deleteItem", uuid);
      }
      return { deleteItem };
   },
});
