import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderPage = _resolveComponent("HeaderPage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterPage = _resolveComponent("FooterPage")!
  const _component_CookiesAgreementModal = _resolveComponent("CookiesAgreementModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderPage),
    _createVNode(_component_router_view),
    _createVNode(_component_FooterPage),
    _createVNode(_component_CookiesAgreementModal, {
      modelValue: _ctx.showAgreement,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showAgreement) = $event)),
      onHideAgreement: _ctx.hideAgreement
    }, null, 8, ["modelValue", "onHideAgreement"])
  ]))
}