import { i18n } from "@/main";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import {
   getTokenUserApp,
   saveTokenUserApp,
   removeTokenUserApp,
} from "@/appModules/service/domain/repository/TokenUserAppStorage";
import ServiceApi from "@/appModules/service/domain/repository/ServiceApi";
import UserAuth from "../domain/dto/UserAuthDto";
import TokenUserAppDto from "../domain/dto/TokenUserAppDto";
import ResetPasswordClientStepOneDto from "../domain/dto/ResetPasswordClientStepOneDto";
import ResetPasswordClientStepTwoDto from "../domain/dto/ResetPasswordClientStepTwoDto";
import { useToast } from "vue-toastification";
import useListParams from "@/appModules/common/domain/composable/useListParams";
import UpdatePasswordUserAppDto from "@/appModules/service/domain/dto/UpdatePasswordUserAppDto";
import { RoleEnum } from "../../userApp/domain/enumerated/RoleEnum";
import { RefreshTokenUserAppDto } from "../domain/dto/RefreshTokenUserAppDto";

const toast = useToast();
const params = useListParams();
const serviceApi = new ServiceApi();

export const useLoginStore = defineStore({
   id: "loginStore",
   state: () => ({
      signInMode: true as boolean,
      loggedIn: (getTokenUserApp() ? true : false) as boolean,
      updatePasswordUserApp: new UpdatePasswordUserAppDto(),
      resetSuccess: 0,
      message: "" as string,
      router: useRouter(),
   }),
   getters: {
      getParams: () => params,
      messageError: (state) => state.message,
      isLoggedIn: (state) => state.loggedIn,
      getUpdatePasswordUserApp: (state) => state.updatePasswordUserApp,
      isResetSuccess: (state) => state.resetSuccess,
      getIdLoggedApplicationUser() {
         if (this.loggedIn) {
            return getTokenUserApp().uuid;
         }
         return null;
      },
      getUsernameLoggedApplicationUser() {
         if (this.loggedIn) {
            return getTokenUserApp().username;
         }
         return null;
      },
      getTokenExpireDateLoggedApplicationUser() {
         if (this.loggedIn) {
            return getTokenUserApp().expirationDateAccessToken;
         }
         return null;
      },
      isLoggedAdministrator() {
         if (this.loggedIn) {
            return getTokenUserApp().roles.some((x) => x === RoleEnum.Admin);
         }
         return false;
      },
   },
   actions: {
      async logInUser(userAuth: UserAuth) {
         this.message = "";

         params.loadingData.value = true;
         this.signInMode = true;

         serviceApi
            .signIn(userAuth)
            .then(
               (success) => {
                  if (success.data) {
                     this.loggedIn = true;
                     const clientToken = new TokenUserAppDto(success.data);
                     saveTokenUserApp(clientToken);
                     toast.success(i18n.global.t("LoginSuccessToast"));
                     this.router.push({ name: "panelDashboard" });
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 401) {
                        toast.error(i18n.global.t("IncorrectLoginOrPassword"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => {
               this.message = i18n.global.t("ErrorConnectToServer");
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      logOutUser() {
         params.loadingData.value = true;
         this.signInMode = false;

         serviceApi
            .logout(new RefreshTokenUserAppDto(getTokenUserApp().refreshToken))
            .then((error) => {
               if (error.data) {
                  if (error.status == 403) {
                     this.message = i18n.global.t("ErrorUnknown");
                  }
               } else {
                  this.message = i18n.global.t("ErrorConnectToServer");
               }
            })
            .catch(() => {
               this.message = i18n.global.t("ErrorConnectToServer");
            })
            .finally(() => {
               params.loadingData.value = false;
               this.signInMode = true;
               this.loggedIn = false;
               removeTokenUserApp();
               this.router.push({ name: "login" });
            });
      },

      async resetPassStep1(resetPass: ResetPasswordClientStepOneDto) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         serviceApi
            .resetPassStep1(resetPass)
            .then(
               (success) => {
                  if (success.status === 200) {
                     this.resetSuccess = 1;
                  }
               },
               (error) => {
                  if (error.response && (error.response.status === 400 || error.response.status === 401)) {
                     // showMessage(1);
                  } else {
                     // showMessage(0);
                  }
               }
            )
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      async resetPassStep2(resetPass: ResetPasswordClientStepTwoDto) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         serviceApi
            .resetPassStep2(resetPass)
            .then(
               (response) => {
                  if (response.status === 200) {
                     this.resetSuccess = 1;
                  } else {
                     if (response.status === 404) {
                        this.resetSuccess = 2;
                     }
                  }
               },
               (error) => {
                  if (error.response && (error.response.status === 400 || error.response.status === 401)) {
                     // showMessage(1);
                  } else {
                     this.resetSuccess = 2;
                  }
               }
            )
            .finally(() => {
               params.loadingData.value = false;
            });
      },
      resetSuccessToInitial() {
         this.resetSuccess = 0;
      },
   },
});
