
import { i18n } from "@/main";
import * as yup from "yup";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";
import { defineComponent, onUnmounted, ref } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import { useCreateUserAppStore } from "../store/CreateUserAppStore";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import UserAppAccessForm from "@/appModules/userApp/component/UserAppAccessForm.vue";
import { useAccessToServiceUserAppStore } from "../store/AccessToServiceUserAppStore";

export default defineComponent({
   name: "CreateUserApp",
   components: { Field, ErrorMessage, LoadBar, UserAppAccessForm },
   setup() {
      const createStore = useCreateUserAppStore();
      const addAccessStore = useAccessToServiceUserAppStore();
      const { create, getPageParams, resetToInitial } = createStore;
      const { getCreateUserApp } = storeToRefs(createStore);
      const { getAddAccessUserAppDto } = storeToRefs(addAccessStore);
      const userAppAccessForm = ref<typeof UserAppAccessForm>();
      const toast = useToast();
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            lastName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"]))
               .nullable(true),
            firstName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(25, i18n.global.t("TextMaxLength", ["25"]))
               .nullable(true),
         }),
      });

      onUnmounted(() => {
         resetToInitial();
      });

      async function onValidate() {
         let res = true;
         const { valid } = await validate();

         res = valid;
         if (getCreateUserApp.value.account) {
            if (userAppAccessForm.value) {
               res = await userAppAccessForm.value.onValidate();
            }
         }

         return res;
      }

      async function submitSave() {
         if (await onValidate()) {
            if (userAppAccessForm.value) {
               create(userAppAccessForm.value.setFieldError, getAddAccessUserAppDto.value);
            }
         } else {
            toast.error(i18n.global.t("FormHasError"));
         }
      }
      return {
         errors,
         getCreateUserApp,
         getPageParams,
         userAppAccessForm,
         submitSave,
         resetToInitial,
      };
   },
});
