
import { defineComponent, ref } from "vue";
import AddressPanel from "@/appModules/service/component/AddressPanel.vue";
import ContactDetailsPanel from "@/appModules/service/component/ContactDetailsPanel.vue";
import { useCompanyRegistrationStore } from "../store/CompanyRegistrationStore";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import { storeToRefs } from "pinia";

export default defineComponent({
   name: "CompanyRegistrationForm",
   components: {
      AddressPanel,
      Field,
      ErrorMessage,
      ContactDetailsPanel,
   },
   setup() {
      const store = useCompanyRegistrationStore();
      const { getServiceRegistrationDTO, getContactDetailsDto } = storeToRefs(store);
      const addressPanel = ref<typeof AddressPanel>();
      const contactDetailsPanel = ref<typeof ContactDetailsPanel>();

      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            companyName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            nip: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(10, i18n.global.t("TextMaxLength", ["10"]))
               .test("testNipValidation", i18n.global.t("NipIsInvalid"), function (value) {
                  return testNipValidation(value);
               }),
         }),
      });

      function testNipValidation(value: string | undefined) {
         if (value?.length != 10) {
            return false;
         }
         let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
         let j = 0;
         let sum = 0;
         let control = 0;
         for (let i = 0; i < value.length - 1; i++) {
            let c: number = +value.charAt(i);
            j = c;
            sum += j * weights[i];
         }
         control = sum % 11;
         let csum = parseInt(value.substring(value.length - 1));
         return control == csum;
      }

      // watch(
      //    () => getErrors.value,
      //    () => {
      //       setErrorMsg(setFieldError);
      //    }
      // );

      async function onValidate() {
         let rez = true;
         const { valid, errors } = await validate();

         rez = valid;

         if (addressPanel.value) {
            rez = await addressPanel.value.onValidate();
         }

         if (contactDetailsPanel.value) {
            rez = await contactDetailsPanel.value.onValidate();
         }
         return rez;
      }

      return { addressPanel, contactDetailsPanel, getServiceRegistrationDTO, getContactDetailsDto, errors, onValidate };
   },
});
