import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemInput = _resolveComponent("ItemInput")!
  const _component_ItemInputCollapseResults = _resolveComponent("ItemInputCollapseResults")!
  const _component_CategoriesModal = _resolveComponent("CategoriesModal")!
  const _component_CategoryModal = _resolveComponent("CategoryModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ItemInput, {
      name: _ctx.name,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      visibleSearchButton: true,
      visibleAddButton: true,
      visibleEditButton: _ctx.categoryExists,
      visibleCancelButton: _ctx.categoryExists,
      isRequiredStyle: _ctx.isRequiredStyle,
      disableInput: _ctx.categoryName.length > 0,
      errors: _ctx.errors,
      onSearchButtonOnClick: _ctx.searchCategory,
      onCancelButtonOnClick: _ctx.cancelCategory,
      onAddButtonOnClick: _ctx.addCategory,
      onEditButtonOnClick: _ctx.editCategory,
      onShowValue: _ctx.showValue,
      onSetCollapse: _ctx.setCollapse,
      modelValue: _ctx.categoryName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.categoryName) = $event)),
      ref: "itemInput"
    }, null, 8, ["name", "label", "placeholder", "visibleEditButton", "visibleCancelButton", "isRequiredStyle", "disableInput", "errors", "onSearchButtonOnClick", "onCancelButtonOnClick", "onAddButtonOnClick", "onEditButtonOnClick", "onShowValue", "onSetCollapse", "modelValue"]),
    _createVNode(_component_ItemInputCollapseResults, {
      name: 'categoryItemPanel',
      results: _ctx.getPageDto.content,
      collapse: _ctx.collapse && !_ctx.category.uuid,
      maxResultsNumber: _ctx.maxResultsNumber,
      searchByLength: _ctx.searchByLength,
      params: _ctx.getPageParams,
      onSelectItem: _ctx.selectCategory
    }, null, 8, ["results", "collapse", "maxResultsNumber", "searchByLength", "params", "onSelectItem"]),
    _createVNode(_component_CategoriesModal, {
      ref: "categoriesModal",
      onSelectCategory: _ctx.selectCategory
    }, null, 8, ["onSelectCategory"]),
    _createVNode(_component_CategoryModal, {
      ref: "categoryModal",
      onSelectCategory: _ctx.selectCategory
    }, null, 8, ["onSelectCategory"])
  ]))
}