
import { defineComponent } from "vue";
import Menu from "@/appModules/common/component/Menu.vue";
import MenuPanel from "@/appModules/common/component/MenuPanel.vue";
import { useLoginStore } from "@/appModules/service/store/LoginStore";

export default defineComponent({
   name: "HeaderPage",
   components: {
      Menu,
      MenuPanel,
   },
   setup() {
      const loginStore = useLoginStore();
      return {
         loginStore,
      };
   },
});
