
import { defineComponent, ref, onMounted } from "vue";
import { httpAuthorization } from "@/appModules/common/domain/service/http-common";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import Format from "@/appModules/common/domain/service/format";
import DashboardCharts from "@/appModules/common/component/DashboardCharts.vue";

export default defineComponent({
   name: "Dashboard",
   components: {
      LoadBar,
      DashboardCharts,
   },
   setup() {
      const loading = ref(true);
      const errored = ref(false);
      const isDataToShow = ref(false);
      const activeButtonClass = ref("btn btn-primary");
      const inactiveButtonClass = ref("btn btn-outline-secondary");
      const minDate = ref("1970-01-01");
      const maxDate = ref("3000-12-31");
      const raport = ref();
      const periodObject = ref({
         nowDate: "",
         startDate: "",
         endDate: "",
      });
      const authUserLogin = ref(null);
      const authUserFullName = ref("");
      const typePeriod = ref(1);
      const startDate = ref("");
      const endDate = ref("");
      const format = new Format();

      onMounted(() => {
         fetchDashboard();
      });

      function fetchDashboard() {
         httpAuthorization()
            .get("api/c/dashboard/period")
            .then(
               (response) => {
                  periodObject.value = response.data;

                  startDate.value = periodObject.value.nowDate;
                  endDate.value = periodObject.value.nowDate;

                  fetchClientDetail();
                  onFetchRaport();
               },
               () => {
                  errored.value = true;
                  //   $parent.checkErrorAuthorization(error);
               }
            )
            .catch(() => (errored.value = true))
            .finally(() => (loading.value = false));
      }
      function fetchClientDetail() {
         if (localStorage.authUser) {
            const tokenData = JSON.parse(localStorage.authUser);

            authUserLogin.value = tokenData.login;
            authUserFullName.value = tokenData.name + " " + tokenData.lastName;
         }

         /*
                httpAuthorization().get("api/c/client-auth").then(response => (
                    clientDetail = response.data));
                    */
      }
      function onPeriodClickButton(periodType: number) {
         typePeriod.value = periodType;

         if (typePeriod.value == 1) {
            startDate.value = periodObject.value.nowDate;
            endDate.value = periodObject.value.nowDate;
         } else {
            startDate.value = periodObject.value.startDate;
            endDate.value = periodObject.value.endDate;
         }
      }
      function onFetchRaport() {
         const paramsTemp = new URLSearchParams();
         paramsTemp.append("start_date", startDate.value);
         paramsTemp.append("end_date", endDate.value);

         loading.value = true;
         errored.value = false;
         isDataToShow.value = false;

         httpAuthorization()
            .get("api/c/dashboard/raport", { params: paramsTemp })
            .then(
               (response) => {
                  raport.value = response.data;
                  isDataToShow.value = true;
               },
               () => {
                  //   $parent.checkErrorAuthorization(error);
               }
            )
            .catch(() => {
               errored.value = true;
            })
            .finally(() => (loading.value = false));
      }
      return {
         loading,
         errored,
         isDataToShow,
         activeButtonClass,
         inactiveButtonClass,
         minDate,
         maxDate,
         raport,
         authUserLogin,
         authUserFullName,
         typePeriod,
         startDate,
         endDate,
         format,
         onPeriodClickButton,
         onFetchRaport,
      };
   },
});
