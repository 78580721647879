
import { PropType, defineComponent, toRefs } from "vue";
import * as yup from "yup";
import { i18n } from "@/main";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { getPostalCodeMask } from "@/appModules/common/domain/command/MaskCommon";
import AddressDto from "../domain/dto/AddressDto";

export default defineComponent({
   name: "AddressPanel",
   props: {
      addressDto: {
         type: Object as PropType<AddressDto>,
         required: true,
      },
   },
   components: { Field, ErrorMessage },
   setup(props) {
      const { addressDto: address } = toRefs(props);
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            street: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(100, i18n.global.t("TextMaxLength", ["100"])),
            streetNumber: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(10, i18n.global.t("TextMaxLength", ["10"])),
            apartmentNumber: yup.string().max(10, i18n.global.t("TextMaxLength", ["10"])),
            postalCode: yup
               .string()
               .nullable(true)
               .matches(getPostalCodeMask(), i18n.global.t("IncorrectPostalCodeWithMask"))
               .max(6, i18n.global.t("TextMaxLength", ["6"])),
            postalName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(50, i18n.global.t("TextMaxLength", ["50"])),
            city: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(50, i18n.global.t("TextMaxLength", ["50"])),
            country: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(50, i18n.global.t("TextMaxLength", ["50"])),
            voviodeship: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(50, i18n.global.t("TextMaxLength", ["50"])),
         }),
      });
      async function onValidate() {
         let rez = true;
         const { valid, errors } = await validate();
         rez = valid;

         return rez;
      }
      return { address, errors, onValidate };
   },
});
