
import { PropType, defineComponent, toRefs } from "vue";
import LoadBar from "../../common/component/LoadBar.vue";

export default defineComponent({
   name: "ItemInputCollapseResults",
   components: {
      LoadBar,
   },
   props: {
      name: {
         type: String,
         default: "",
      },
      results: {
         type: Object as PropType<Array<any>>,
         required: true,
      },
      collapse: {
         type: Boolean,
         default: false,
      },
      maxResultsNumber: {
         type: Number,
         default: 0,
      },
      searchByLength: {
         type: Number,
         default: 0,
      },
      params: {
         type: Object as PropType<any>,
         required: true,
      },
   },
   emits: ["selectItem"],
   setup(props, { emit }) {
      const { searchByLength: searchByLengthRef, params: paramsRef, results: resultsRef } = toRefs(props);
      const minChars = 3;

      function selectItem(item: any) {
         emit("selectItem", item);
      }

      return { paramsRef, minChars, searchByLengthRef, resultsRef, selectItem };
   },
});
