
import { useLoginStore } from "@/appModules/service/store/LoginStore";
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";

export default defineComponent({
   name: "PanelSettings",
   components: {},
   setup() {
      const client_id = ref();
      const store = useLoginStore();
      const { getIdLoggedApplicationUser } = storeToRefs(store);

      return { getIdLoggedApplicationUser };
   },
});
