import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemInput = _resolveComponent("ItemInput")!
  const _component_ItemInputCollapseResults = _resolveComponent("ItemInputCollapseResults")!
  const _component_ProductsModal = _resolveComponent("ProductsModal")!
  const _component_ProductModal = _resolveComponent("ProductModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ItemInput, {
      name: _ctx.name,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      visibleSearchButton: !_ctx.isReadOnly,
      visibleAddButton: !_ctx.isReadOnly,
      visibleEditButton: !_ctx.isReadOnly && _ctx.productExists,
      visibleCancelButton: !_ctx.isReadOnly && _ctx.productExists,
      isRequiredStyle: _ctx.isRequiredStyle,
      disableInput: _ctx.productName.length > 0,
      errors: _ctx.errors,
      onSearchButtonOnClick: _ctx.searchProduct,
      onCancelButtonOnClick: _ctx.cancelProduct,
      onAddButtonOnClick: _ctx.addProduct,
      onEditButtonOnClick: _ctx.editProduct,
      onShowValue: _ctx.showValue,
      onSetCollapse: _ctx.setCollapse,
      modelValue: _ctx.productName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productName) = $event)),
      ref: "itemInput"
    }, null, 8, ["name", "label", "placeholder", "visibleSearchButton", "visibleAddButton", "visibleEditButton", "visibleCancelButton", "isRequiredStyle", "disableInput", "errors", "onSearchButtonOnClick", "onCancelButtonOnClick", "onAddButtonOnClick", "onEditButtonOnClick", "onShowValue", "onSetCollapse", "modelValue"]),
    _createVNode(_component_ItemInputCollapseResults, {
      name: 'productItemPanel',
      results: _ctx.getPageDto.content,
      collapse: _ctx.collapse && !_ctx.product.uuid,
      maxResultsNumber: _ctx.maxResultsNumber,
      searchByLength: _ctx.searchByLength,
      params: _ctx.getPageParams,
      onSelectItem: _ctx.selectProduct
    }, null, 8, ["results", "collapse", "maxResultsNumber", "searchByLength", "params", "onSelectItem"]),
    (!_ctx.isReadOnly)
      ? (_openBlock(), _createBlock(_component_ProductsModal, {
          key: 0,
          ref: "productsModal",
          onSelectProduct: _ctx.selectProduct
        }, null, 8, ["onSelectProduct"]))
      : _createCommentVNode("", true),
    (!_ctx.isReadOnly)
      ? (_openBlock(), _createBlock(_component_ProductModal, {
          key: 1,
          ref: "productModal",
          onSelectProduct: _ctx.selectProduct
        }, null, 8, ["onSelectProduct"]))
      : _createCommentVNode("", true)
  ]))
}