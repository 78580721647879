
import { storeToRefs } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { defineComponent, onMounted, ref } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "@/plugins/window-width";
import { toStringRoleEnum } from "@/appModules/common/domain/service/format";
import { useUsersStore } from "@/appModules/userApp/store/UsersStore";
import { RoleEnum } from "@/appModules/userApp/domain/enumerated/RoleEnum";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import PaginationPanel from "@/appModules/common/component/PaginationPanel.vue";
import SortComboBox from "@/appModules/common/component/SortComboBox.vue";
import SortingPaginationParam from "@/appModules/common/domain/command/SortingPaginationParam";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";

export default defineComponent({
   name: "Users",
   components: {
      LoadBar,
      PaginationPanel,
      SortComboBox,
   },
   directives: {
      debounce: vue3Debounce({ lock: true }),
   },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectUserApp"],
   setup(props, { emit }) {
      const store = useUsersStore();
      const { getPageParams, getPageDto, sortOptions } = storeToRefs(store);
      const { fetchPaginationData } = store;
      const { typeGrid } = useBreakpointsGrid();
      const defaultSort = sortOptions.value[0];
      const searchBy = ref("");

      onMounted(() => {
         getPageParams.value.sort.value = defaultSort;
         showValue();
      });

      function sortData(sortOption: SortingPaginationParam) {
         getPageParams.value.sort.value = sortOption;
         showValue();
      }

      function changePagination(page: number, size: number) {
         fetchPaginationData(page, size, searchBy.value);
      }

      function showValue() {
         fetchPaginationData(getPageDto.value.number, getPageDto.value.size, searchBy.value);
      }

      function selectUserApp(user: UserAppDto) {
         emit("selectUserApp", user);
      }

      return {
         getPageDto,
         getPageParams,
         store,
         typeGrid,
         RoleEnum,
         sortOptions,
         defaultSort,
         searchBy,
         useBreakpointsGrid,
         equalsOrGreaterGrid,
         toStringRoleEnum,
         changePagination,
         sortData,
         showValue,
         selectUserApp,
      };
   },
});
