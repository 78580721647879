import { defineStore } from "pinia";
import { i18n } from "@/main";
import useListParams from "@/appModules/common/domain/composable/useListParams";
import SortingPaginationParam from "@/appModules/common/domain/command/SortingPaginationParam";
import PaginationParams from "@/appModules/common/domain/command/PaginationParam";
import DocumentApi from "../domain/repository/DocumentApi";
import DocumentPaginationDto from "../domain/dto/DocumentPaginationDto";

const api = new DocumentApi();
const params = useListParams();

export const useDocumentsStore = defineStore({
   id: "documentsStore",
   state: () => ({
      pageDto: new DocumentPaginationDto(),
      sortOptions: [
         new SortingPaginationParam(i18n.global.t("NumberFromA"), "number", true),
         new SortingPaginationParam(i18n.global.t("NumberFromZ"), "number", false),
      ] as SortingPaginationParam[],
      queryFields: ["number"] as string[],
      query: "" as string,
   }),
   getters: {
      getPageParams: () => params,
      getPageDto: (state) => state.pageDto,
   },
   actions: {
      async fetchPaginationData(pageNumber: number, pageSize: number, searchBy: string | undefined) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         const searchByMap = new Map<string, string>();
         if (this.queryFields && searchBy) {
            this.queryFields.forEach((element) => {
               searchByMap.set(element, searchBy);
            });
         }
         const paginationParam = new PaginationParams(
            pageNumber,
            pageSize,
            params.sort.value ? params.sort.value : this.sortOptions[0],
            searchByMap,
            this.query
         );
         this.query = paginationParam.getQueryFromParams();
         api.findByPagination(this.query)
            .then(
               (success) => {
                  this.pageDto = success.data;
                  params.noDataToDisplay.value = this.pageDto.content.length === 0;
               },
               (error) => {
                  if (error.response && error.response.status == 401) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },
   },
});
