
import { defineComponent, ref } from "vue";
import InstructionMobileAppProduct from "@/appModules/instruction/mobileApp/InstructionMobileAppProduct.vue";
import InstructionMobileAppProductItem from "@/appModules/instruction/mobileApp/InstructionMobileAppProductItem.vue";
export default defineComponent({
   name: "InstructionMobileApp",
   components: {
      InstructionMobileAppProduct,
      InstructionMobileAppProductItem,
   },
   setup() {
      const productTitle = ref("Przedmioty - dodawanie i edycja");
      const productItemTitle = ref("Pozycje przedmiotu - dodawanie i edycja");

      window.scrollTo(0, 0);
      return {
         productItemTitle,
         productTitle,
      };
   },
});
