import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "text-muted" }
const _hoisted_4 = { class: "row form-group mt-2" }
const _hoisted_5 = { class: "col-sm-12 col-lg-6" }
const _hoisted_6 = {
  for: "username",
  class: "form-label"
}
const _hoisted_7 = {
  id: "loginHelpLabel",
  class: "form-text text-muted"
}
const _hoisted_8 = { class: "row form-group mt-3" }
const _hoisted_9 = { class: "col-sm-12 col-lg-4" }
const _hoisted_10 = {
  for: "password",
  class: "form-label"
}
const _hoisted_11 = { class: "col-sm-12 col-lg-4 mt-3 mt-lg-0" }
const _hoisted_12 = {
  for: "confirmPassword",
  class: "form-label"
}
const _hoisted_13 = { class: "row form-group mt-1" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "text-secondary" }
const _hoisted_16 = { class: "col-sm-12 col-lg-6 mt-2" }
const _hoisted_17 = {
  class: "mb-2",
  or: "role"
}
const _hoisted_18 = {
  disabled: "",
  value: ""
}
const _hoisted_19 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.$t("LoginDetailsInMobileAppAndInWebApp")) + ":", 1)
      ])
    ]),
    _createElementVNode("form", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("EmailOrLogin")), 1),
        _createVNode(_component_Field, {
          id: "username",
          name: "username",
          type: "email",
          class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.username }]),
          placeholder: _ctx.$t('EnterLogin'),
          modelValue: _ctx.getAddAccessUserAppDto.username,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getAddAccessUserAppDto.username) = $event))
        }, null, 8, ["class", "placeholder", "modelValue"]),
        _createVNode(_component_ErrorMessage, {
          name: "username",
          class: "invalid-feedback"
        }),
        _createElementVNode("small", _hoisted_7, _toDisplayString(_ctx.$t("UniqueLoginForEmployeeWithinTheEntireSystem")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("Password")), 1),
        _createVNode(_component_Field, {
          id: "password",
          name: "password",
          type: "password",
          class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.password }]),
          placeholder: _ctx.$t('EnterPassword'),
          modelValue: _ctx.getAddAccessUserAppDto.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getAddAccessUserAppDto.password) = $event)),
          autocomplete: "new-password"
        }, null, 8, ["class", "placeholder", "modelValue"]),
        _createVNode(_component_ErrorMessage, {
          name: "password",
          class: "invalid-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("RepeatPassword")), 1),
        _createVNode(_component_Field, {
          id: "confirmPassword",
          name: "confirmPassword",
          type: "password",
          class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.confirmPassword }]),
          placeholder: _ctx.$t('RepeatPassword'),
          modelValue: _ctx.getAddAccessUserAppDto.confirmPassword,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getAddAccessUserAppDto.confirmPassword) = $event)),
          autocomplete: "confirm-Password"
        }, null, 8, ["class", "placeholder", "modelValue"]),
        _createVNode(_component_ErrorMessage, {
          name: "confirmPassword",
          class: "invalid-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("h6", _hoisted_15, _toDisplayString(_ctx.$t("LongerPasswordMoreSecureUseAtLeast8Characters")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("Role")) + ":", 1),
        _createVNode(_component_Field, {
          as: "select",
          name: "role",
          id: "role",
          class: _normalizeClass(["form-select required", { 'is-invalid': _ctx.errors.role }]),
          modelValue: _ctx.selected,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selected) = $event)),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addRole(_ctx.selected)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$t("SelectRole")), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.RoleEnum, (role) => {
              return (_openBlock(), _createElementBlock("option", {
                class: _normalizeClass(["row table-body", [_ctx.roleExists(role) ? 'text-dark' : 'text-black-50']]),
                key: role,
                value: role
              }, _toDisplayString(_ctx.roleExists(role) ? _ctx.checkedUnicode : _ctx.uncheckedUnicode) + " " + _toDisplayString(_ctx.toStringRoleEnum(role, true)), 11, _hoisted_19))
            }), 128))
          ]),
          _: 1
        }, 8, ["class", "modelValue"]),
        _createVNode(_component_ErrorMessage, {
          name: "role",
          class: "invalid-feedback"
        })
      ])
    ])
  ], 64))
}