
import { defineComponent, toRefs } from "vue";
import * as yup from "yup";
import { i18n } from "@/main";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { getPhoneNumberMask } from "@/appModules/common/domain/command/MaskCommon";
import { ContactDetailsDto } from "../domain/dto/ContactDetailsDto";

export default defineComponent({
   name: "ContactDetailsPanel",
   props: {
      contactDetailsDto: {
         type: ContactDetailsDto,
         required: true,
      },
   },
   components: { Field, ErrorMessage },
   setup(props) {
      const { contactDetailsDto: contactDetails } = toRefs(props);
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            phone: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(12, i18n.global.t("TextMaxLength", ["12"]))
               .matches(getPhoneNumberMask(), i18n.global.t("IncorrectPhoneNumberWithMask"))
               .test("testPhoneNumber", i18n.global.t("IncorrectPhoneNumberWithMask"), function (value) {
                  if (value?.length != 12 && value?.length != 9) {
                     return false;
                  }
                  if (value.length === 12) {
                     return value.substring(0, 3) === "+48";
                  }
                  if (value.length === 9) {
                     return value.substring(0, 3) !== "+48";
                  }
                  return false;
               }),
            email: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .email(i18n.global.t("IncorrectFormatEmail"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
         }),
      });
      async function onValidate() {
         let rez = true;
         const { valid, errors } = await validate();

         rez = valid;
         return rez;
      }
      return { contactDetails, errors, onValidate };
   },
});
