import { ref } from "vue";
import SortingPaginationParam from "../command/SortingPaginationParam";

export default function useListParams() {
   const noDataToDisplay = ref(false);
   const noAccessToDisplay = ref(false);
   const loadingData = ref(false);
   const errorLoadData = ref(false);
   const tokenExpired = ref(false);
   const created = ref(false);
   const sort = ref<SortingPaginationParam | undefined>(undefined);

   return {
      noDataToDisplay,
      noAccessToDisplay,
      loadingData,
      errorLoadData,
      tokenExpired,
      created,
      sort,
   };
}
