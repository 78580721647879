export interface IUpdateProduct {
   name: string;
   description: string;
   categoryUuid: string;
   version: number;
}

export default class UpdateProductDto implements IUpdateProduct {
   constructor(public name: string = "", public description = "", public categoryUuid: string = "", public version: number = 0) {}
}
