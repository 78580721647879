import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "full-view" }
const _hoisted_2 = {
  key: 0,
  class: "history-bar"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = { class: "breadcrumb-item" }
const _hoisted_7 = {
  key: 0,
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_8 = {
  key: 1,
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_9 = { class: "container mt-3 d-flex flex-column justify-content-center" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 0,
  class: "position-absolute top-50 start-50 translate-middle"
}
const _hoisted_12 = {
  key: 0,
  class: "col"
}
const _hoisted_13 = { class: "row text-center" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "display-5 text-success" }
const _hoisted_16 = { class: "row mt-3 mt-md-3 text-center" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { class: "text-secondary" }
const _hoisted_19 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "row mt-3 mt-md-4 text-center" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = {
  key: 1,
  class: "row"
}
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "col-12 mb-3" }
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = {
  for: "productName",
  class: "form-label"
}
const _hoisted_28 = { class: "row mt-3" }
const _hoisted_29 = { class: "col-12" }
const _hoisted_30 = { class: "row mt-3" }
const _hoisted_31 = { class: "col-12 mb-3" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = {
  for: "productDescription",
  class: "form-label"
}
const _hoisted_34 = { class: "col-12 mt-4 text-center" }
const _hoisted_35 = { class: "col-6 col-sm-4 d-grid gap-2 mx-auto mt-1 mb-5" }
const _hoisted_36 = { class: "ms-2 ms-sm-3 d-md-inline d-lg-inline d-xl-inline d-xxl-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_CategoryItemPanel = _resolveComponent("CategoryItemPanel")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("nav", _hoisted_4, [
              _createElementVNode("ol", _hoisted_5, [
                _createElementVNode("li", _hoisted_6, [
                  _createVNode(_component_router_link, { to: { name: 'panelProducts' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Products")), 1)
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.uuid)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString(_ctx.$t("EditProduct")), 1))
                  : (_openBlock(), _createElementBlock("li", _hoisted_8, _toDisplayString(_ctx.$t("NewProduct")), 1))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        noDataToDisplay: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "noDataToDisplay"]),
      (!_ctx.getPageParams.noDataToDisplay.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
            (_ctx.getPageParams.created.value)
              ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
                  (!_ctx.isModal)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("h1", _hoisted_15, _toDisplayString(_ctx.$t("NewProductAdded")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("DoYouWantNow")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("button", {
                              class: "btn btn-outline-success btn-lg",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetToInitial()))
                            }, _toDisplayString(_ctx.$t("AddAnotherNewProduct")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_router_link, {
                              class: "btn btn-outline-primary btn-lg text-center",
                              to: { name: 'panelProducts' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("ShowProductList")), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _withDirectives(_createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("Name")), 1),
                        _createVNode(_component_Field, {
                          name: "productName",
                          type: "text",
                          class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.productName }]),
                          placeholder: _ctx.$t('EnterName'),
                          modelValue: _ctx.getProduct.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getProduct.name) = $event))
                        }, null, 8, ["class", "placeholder", "modelValue"]),
                        _createVNode(_component_ErrorMessage, {
                          name: "productName",
                          class: "invalid-feedback"
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_CategoryItemPanel, {
                          name: `categoryItemPanel`,
                          label: _ctx.$t('Category'),
                          placeholder: _ctx.$t('SelectCategory'),
                          category: _ctx.getProduct.category,
                          isRequiredStyle: true,
                          categoryExists: _ctx.getProduct.category?.uuid.length > 0,
                          errors: _ctx.errors,
                          onSelectCategory: _ctx.selectCategory,
                          onCancelCategory: _ctx.cancelCategory
                        }, null, 8, ["label", "placeholder", "category", "categoryExists", "errors", "onSelectCategory", "onCancelCategory"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t("Description")), 1),
                          _createVNode(_component_Field, {
                            as: "textarea",
                            name: "productDescription",
                            type: "text",
                            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.productDescription }]),
                            placeholder: _ctx.$t('EnterDescription'),
                            modelValue: _ctx.getProduct.description,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getProduct.description) = $event))
                          }, null, 8, ["class", "placeholder", "modelValue"]),
                          _createVNode(_component_ErrorMessage, {
                            name: "productDescription",
                            class: "invalid-feedback"
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("button", {
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitSave && _ctx.submitSave(...args))),
                            class: "btn btn-success btn-lg d-flex align-items-center justify-content-center"
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "save",
                              size: "1x"
                            }),
                            _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("Save")), 1)
                          ])
                        ])
                      ])
                    ])
                  ], 512), [
                    [_vShow, !_ctx.getPageParams.loadingData.value]
                  ])
                ]))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}