import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemInput = _resolveComponent("ItemInput")!
  const _component_ItemInputCollapseResults = _resolveComponent("ItemInputCollapseResults")!
  const _component_WarehousesModal = _resolveComponent("WarehousesModal")!
  const _component_WarehouseModal = _resolveComponent("WarehouseModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ItemInput, {
      name: _ctx.name,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      visibleSearchButton: !_ctx.isReadOnly,
      visibleAddButton: !_ctx.isReadOnly,
      visibleEditButton: !_ctx.isReadOnly && _ctx.warehouseExists,
      visibleCancelButton: !_ctx.isReadOnly && _ctx.warehouseExists,
      isRequiredStyle: _ctx.isRequiredStyle,
      disableInput: _ctx.warehouseName.length > 0,
      errors: _ctx.errors,
      onSearchButtonOnClick: _ctx.searchWarehouse,
      onCancelButtonOnClick: _ctx.cancelWarehouse,
      onAddButtonOnClick: _ctx.addWarehouse,
      onEditButtonOnClick: _ctx.editWarehouse,
      onShowValue: _ctx.showValue,
      onSetCollapse: _ctx.setCollapse,
      modelValue: _ctx.warehouseName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.warehouseName) = $event)),
      ref: "itemInput"
    }, null, 8, ["name", "label", "placeholder", "visibleSearchButton", "visibleAddButton", "visibleEditButton", "visibleCancelButton", "isRequiredStyle", "disableInput", "errors", "onSearchButtonOnClick", "onCancelButtonOnClick", "onAddButtonOnClick", "onEditButtonOnClick", "onShowValue", "onSetCollapse", "modelValue"]),
    _createVNode(_component_ItemInputCollapseResults, {
      name: 'warehouseItemPanel',
      results: _ctx.getPageDto.content,
      collapse: _ctx.collapse && !_ctx.warehouse.uuid,
      maxResultsNumber: _ctx.maxResultsNumber,
      searchByLength: _ctx.searchByLength,
      params: _ctx.getPageParams,
      onSelectItem: _ctx.selectWarehouse
    }, null, 8, ["results", "collapse", "maxResultsNumber", "searchByLength", "params", "onSelectItem"]),
    (!_ctx.isReadOnly)
      ? (_openBlock(), _createBlock(_component_WarehousesModal, {
          key: 0,
          ref: "warehousesModal",
          onSelectWarehouse: _ctx.selectWarehouse
        }, null, 8, ["onSelectWarehouse"]))
      : _createCommentVNode("", true),
    (!_ctx.isReadOnly)
      ? (_openBlock(), _createBlock(_component_WarehouseModal, {
          key: 1,
          ref: "warehouseModal",
          onSelectWarehouse: _ctx.selectWarehouse
        }, null, 8, ["onSelectWarehouse"]))
      : _createCommentVNode("", true)
  ]))
}