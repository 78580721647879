import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "full-view" }
const _hoisted_2 = {
  key: 0,
  class: "history-bar"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "row ms-sm-2 me-sm-2" }
const _hoisted_9 = {
  key: 0,
  class: "col-2 col-md-1 mt-2"
}
const _hoisted_10 = { class: "col-2 col-md-1 mt-2" }
const _hoisted_11 = { class: "col-8 col-sm-8 col-md-4 col-lg-6 mt-2 d-flex align-items-end justify-content-end" }
const _hoisted_12 = { class: "col-12 col-sm-12 col-md-6 col-lg-4 mt-2" }
const _hoisted_13 = { class: "input-group" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = {
  key: 0,
  class: "row mt-4"
}
const _hoisted_16 = { class: "col" }
const _hoisted_17 = {
  key: 0,
  class: "row table-head"
}
const _hoisted_18 = { class: "col-6 ps-4" }
const _hoisted_19 = { class: "col-3 ps-4" }
const _hoisted_20 = { class: "col-2 ps-4" }
const _hoisted_21 = { class: "col-1 d-lg-flex justify-content-lg-center" }
const _hoisted_22 = {
  key: 1,
  class: "h3 mt-3 text-secondary text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SortComboBox = _resolveComponent("SortComboBox")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_DocumentListItem = _resolveComponent("DocumentListItem")!
  const _component_DeleteItemModal = _resolveComponent("DeleteItemModal")!
  const _component_PaginationPanel = _resolveComponent("PaginationPanel")!
  const _directive_debounce = _resolveDirective("debounce")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("nav", _hoisted_4, [
              _createElementVNode("ol", _hoisted_5, [
                _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.$t("Documents")), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (!_ctx.isModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_router_link, {
                class: "btn btn-outline-success",
                to: { name: 'panelCreateDocument' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "plus",
                    size: "1x"
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-outline-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showValue && _ctx.showValue(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "redo-alt",
              size: "1x"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_SortComboBox, {
            sortOptions: _ctx.sortOptions,
            defaultSort: _ctx.defaultSort,
            onOnChangeSort: _ctx.sortData
          }, null, 8, ["sortOptions", "defaultSort", "onOnChangeSort"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _withDirectives(_createElementVNode("input", {
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchBy) = $event)),
              type: "text",
              placeholder: _ctx.$t('DocumentSearchBy')
            }, null, 8, _hoisted_14), [
              [_directive_debounce, _ctx.showValue, "800ms"],
              [_vModelText, _ctx.searchBy]
            ])
          ])
        ])
      ]),
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        noDataToDisplay: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "noDataToDisplay"]),
      (!_ctx.getPageParams.noDataToDisplay.value && !_ctx.getPageParams.errorLoadData.value && !_ctx.getPageParams.loadingData.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              (_ctx.equalsOrGreaterGrid('lg', _ctx.typeGrid))
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("Number")), 1),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("ExternalNumber")), 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("Type")), 1),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("Action")), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.getPageDto.content?.length === 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_22, [
                    _createElementVNode("i", null, _toDisplayString(_ctx.$t("Absence")), 1)
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.getPageDto.content, (document) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: document.uuid,
                      class: "row table-body"
                    }, [
                      _createVNode(_component_DocumentListItem, {
                        documentDto: document,
                        onSelectDocument: _ctx.selectDocument,
                        isModal: _ctx.isModal
                      }, null, 8, ["documentDto", "onSelectDocument", "isModal"])
                    ]))
                  }), 128)),
              (_ctx.selectedDocument)
                ? (_openBlock(), _createBlock(_component_DeleteItemModal, {
                    key: 3,
                    onDeleteItem: _ctx.deleteDocument,
                    uuid: _ctx.selectedDocument.uuid,
                    name: _ctx.selectedDocument.number,
                    bodyText: _ctx.$t('DocumentWillBeDeletedYouWillNotBeAbletoAndothisAction')
                  }, null, 8, ["onDeleteItem", "uuid", "name", "bodyText"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.getPageDto.content?.length > 0)
              ? (_openBlock(), _createBlock(_component_PaginationPanel, {
                  key: 0,
                  class: "mt-3",
                  pageSize: _ctx.getPageDto.size,
                  totalPages: _ctx.getPageDto.totalPages,
                  totalElements: _ctx.getPageDto.totalElements,
                  pageNumber: _ctx.getPageDto.number,
                  isTheFirstPage: _ctx.getPageDto.first,
                  isTheLastPage: _ctx.getPageDto.last,
                  onOnChangePagination: _ctx.changePagination
                }, null, 8, ["pageSize", "totalPages", "totalElements", "pageNumber", "isTheFirstPage", "isTheLastPage", "onOnChangePagination"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}