import { DocumentTypeEnum } from "../enumerated/DocumentTypeEnum";
import DocumentPositionsDto from "./DocumentPosition";

export interface IDocument {
   uuid: string;
   number: string;
   note: string;
   type: DocumentTypeEnum;
   externalNumber: string | undefined;
   documentPositions: Array<DocumentPositionsDto>;
   version: number;
}

export default class DocumentDto implements IDocument {
   constructor(
      public uuid: string = "",
      public note: string = "",
      public number: string = "",
      public type: DocumentTypeEnum = DocumentTypeEnum.PZ,
      public externalNumber: string | undefined = undefined,
      public documentPositions: Array<DocumentPositionsDto> = new Array<DocumentPositionsDto>(),
      public version: number = 0
   ) {}
}
