
import { defineComponent, ref } from "vue";
import TitlePage from "@/appModules/common/component/TitlePage.vue";
import ContactForm from "@/appModules/common/component/ContactForm.vue";
import { useContactFormStore } from "../store/ContactFormStore";
export default defineComponent({
   name: "About",
   components: {
      TitlePage,
      ContactForm,
   },
   setup() {
      const formVisible = ref(false);
      const store = useContactFormStore();
      window.scrollTo(0, 0);
      return { formVisible, store };
   },
});
