import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  for: "firstName",
  class: "form-label"
}
const _hoisted_5 = {
  id: "firstName",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_6 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  for: "lastName",
  class: "form-label"
}
const _hoisted_9 = {
  id: "lastName",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = {
  for: "beginDate",
  class: "form-label"
}
const _hoisted_14 = {
  id: "beginDate",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_15 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = {
  for: "untilDate",
  class: "form-label"
}
const _hoisted_18 = {
  id: "untilDate",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_19 = { class: "mb-3" }
const _hoisted_20 = { class: "my-2 fw-bold" }
const _hoisted_21 = { class: "border rounded p-4 bg-light" }
const _hoisted_22 = { class: "d-lg-flex justify-content-between" }
const _hoisted_23 = { class: "col-sm-12 col-lg-9 mb-3" }
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = {
  for: "productName",
  class: "form-label"
}
const _hoisted_26 = {
  id: "productName",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_27 = { class: "col-sm-12 col-lg-3 mb-3 ps-lg-4" }
const _hoisted_28 = { class: "form-group" }
const _hoisted_29 = {
  for: "quantity",
  class: "form-label"
}
const _hoisted_30 = {
  id: "quantity",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_31 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = {
  for: "categoryName",
  class: "form-label"
}
const _hoisted_34 = {
  id: "categoryName",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_35 = { class: "mb-3" }
const _hoisted_36 = { class: "my-2 fw-bold" }
const _hoisted_37 = { class: "border rounded p-4 bg-light" }
const _hoisted_38 = { class: "d-lg-flex justify-content-between mx-2" }
const _hoisted_39 = { class: "col-sm-12 col-lg-9 mb-3" }
const _hoisted_40 = { class: "form-group" }
const _hoisted_41 = {
  for: "warehouseName",
  class: "form-label"
}
const _hoisted_42 = {
  id: "warehouseName",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_43 = { class: "col-sm-12 col-lg-3 mb-3 ps-lg-4" }
const _hoisted_44 = { class: "form-group" }
const _hoisted_45 = {
  for: "warehouseType",
  class: "form-label"
}
const _hoisted_46 = {
  id: "warehouseType",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_47 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_48 = { class: "my-2 fw-bold" }
const _hoisted_49 = { class: "border rounded p-4 bg-light" }
const _hoisted_50 = { class: "d-lg-flex justify-content-between mx-2" }
const _hoisted_51 = { class: "col-sm-12 col-lg-9 mb-3" }
const _hoisted_52 = { class: "form-group" }
const _hoisted_53 = {
  for: "warehouseName",
  class: "form-label"
}
const _hoisted_54 = {
  id: "warehouseName",
  type: "text",
  class: "form-control",
  readonly: ""
}
const _hoisted_55 = { class: "col-sm-12 col-lg-3 mb-3 ps-lg-4" }
const _hoisted_56 = { class: "form-group" }
const _hoisted_57 = {
  for: "warehouseType",
  class: "form-label"
}
const _hoisted_58 = {
  id: "warehouseType",
  type: "text",
  class: "form-control",
  readonly: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("FirstName")), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getRent.userApp.firstName), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("LastName")), 1),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getRent.userApp.lastName), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("BeginDate")), 1),
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.dateTimeService.dateTimeToString(_ctx.getRent.beginDate)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("UntilDate")), 1),
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.dateTimeService.dateTimeToString(_ctx.getRent.untilDate)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("ProductData")), 1),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("ProductName")), 1),
              _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.getRent.product.name), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("Quantity")), 1),
              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.getRent.quantityBegin), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t("Category")), 1),
            _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.getRent.product.category.name), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_35, [
      _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t("WarehouseBeginData")), 1),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t("WarehouseName")), 1),
              _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.getRent.warehouseBegin.name), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t("Type")), 1),
              _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.$t("WarehouseTypeEnum" + _ctx.toStringEnum(_ctx.getRent.warehouseBegin.type.toString(), _ctx.WarehouseTypeEnum))), 1)
            ])
          ])
        ])
      ])
    ]),
    (_ctx.getRent.warehouseUntil)
      ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
          _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("WarehouseUntilData")), 1),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("label", _hoisted_53, _toDisplayString(_ctx.$t("WarehouseName")), 1),
                  _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.getRent.warehouseUntil?.name), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("label", _hoisted_57, _toDisplayString(_ctx.$t("Type")), 1),
                  _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.$t("WarehouseTypeEnum" + _ctx.toStringEnum(_ctx.getRent.warehouseUntil?.type.toString(), _ctx.WarehouseTypeEnum))), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}