import SortingPaginationParam from "./SortingPaginationParam";

export interface IPaginationParam {
   page: number;
   size: number;
   sort: SortingPaginationParam;
   searchByMap: Map<string, string>;
}

export default class PaginationParam implements IPaginationParam {
   constructor(
      public page: number = 0,
      public size: number = 5,
      public sort = new SortingPaginationParam(),
      public searchByMap = new Map<string, string>(),
      public previousQuery: string = ""
   ) {}

   public getQueryFromParams() {
      let query = "";
      if (this.sort.name.length > 0) {
         query += `${this.sort.sortAscending ? "&sort=" + this.sort.sortParam : "&sort=" + this.sort.sortParam + ",desc"}`;
      }
      this.searchByMap.forEach((value, key) => {
         query += "&" + key + "=" + value;
      });

      return this.getCompletedQuery(query);
   }

   private getCompletedQuery(query: string) {
      const slicedPrevQuery = this.previousQuery.slice(this.previousQuery.indexOf("&sort="));
      const slicedCurrentQuery = query.slice(query.indexOf("&sort="));
      if (slicedPrevQuery.length > 0 && slicedPrevQuery !== slicedCurrentQuery) {
         this.page = 0;
      }

      return `?page=${this.page}&size=${this.size}${slicedCurrentQuery}`;
   }
}
