
import { PropType, defineComponent, toRefs } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import { WarehouseTypeEnum } from "../domain/enumerated/WarehouseTypeEnum";
import WarehouseDto from "../domain/dto/WarehouseDto";
import { toStringEnum } from "@/appModules/common/domain/service/format";
import AddressService from "@/appModules/common/domain/service/AddressService";

export default defineComponent({
   name: "WarehouseListItem",
   components: {},
   props: {
      warehouse: {
         type: Object as PropType<WarehouseDto>,
         required: true,
      },
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectWarehouse"],
   setup(props, { emit }) {
      const { warehouse: warehouseRef } = toRefs(props);
      const { typeGrid } = useBreakpointsGrid();
      const addressService = new AddressService();

      function selectWarehouse() {
         emit("selectWarehouse", warehouseRef.value);
      }
      return {
         warehouseRef,
         typeGrid,
         WarehouseTypeEnum,
         addressService,
         equalsOrGreaterGrid,
         selectWarehouse,
         toStringEnum,
      };
   },
});
