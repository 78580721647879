import usePageParams from "@/appModules/common/domain/composable/usePageParams";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { defineStore } from "pinia";
import RentDto from "../domain/dto/RentDto";
import RentApi from "../domain/repository/RentApi";

const api = new RentApi();
const params = usePageParams();

export const useRentStore = defineStore({
   id: "rentStore",
   state: () => ({
      rentDto: new RentDto(),
   }),
   getters: {
      getPageParams: () => params,
      getRent: (state) => state.rentDto,
   },
   actions: {
      async findById(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .findById(id)
            .then(
               (success) => {
                  this.rentDto = success.data;
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },

      resetToInitial() {
         params.created.value = false;
         params.noDataToDisplay.value = false;
         this.rentDto = new RentDto();
      },
   },
});
