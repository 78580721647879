import Contact from "@/appModules/service/domain/dto/ContactDto";
import { i18n } from "@/main";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import ContactService from "../domain/service/ContactService";

const service = new ContactService();
const toast = useToast();

export const useContactFormStore = defineStore({
   id: "clientsStore",
   state: () => ({
      contact: new Contact(),
      agreement: false as boolean,
      isMsgSent: false as boolean,
      loading: false as boolean,
      error: false as boolean,
      noDataToDisplay: false as boolean,
   }),
   getters: {
      getContact: (state) => state.contact,
      getAgreement: (state) => state.agreement,
      getMsgSent: (state) => state.isMsgSent,
      isLoading: (state) => state.loading,
      isError: (state) => state.error,
      isNoDataToDisplay: (state) => state.noDataToDisplay,
   },
   actions: {
      sendMsg() {
         this.loading = true;
         service
            .sendMsg(this.contact)
            .then(
               (success) => {
                  if (success) {
                     if (success.status === 201) {
                        this.isMsgSent = true;
                        this.contact = new Contact();
                     }
                  }
               },
               (error) => {
                  if (error.response && error.response.status === 422) {
                     toast.error(i18n.global.t("ProblemSendingMsgTryAgainLater"));
                  } else {
                     toast.error(i18n.global.t("FailedToSendMsgServerIsUnavailable"));
                  }
               }
            )
            .finally(() => (this.loading = false));
      },
      changeMsgSent() {
         this.isMsgSent = !this.isMsgSent;
      },
   },
});
