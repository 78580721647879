import { RoleEnum } from "@/appModules/userApp/domain/enumerated/RoleEnum";

export interface IAddAccessUserAppDto {
   uuid: string;
   username: string;
   password: string;
   confirmPassword: string;
   uuidRoles: RoleEnum[];
   version: number;
}

export default class AddAccessUserAppDto implements IAddAccessUserAppDto {
   constructor(
      public uuid: string = "",
      public username: string = "",
      public password: string = "",
      public confirmPassword: string = "",
      public uuidRoles: RoleEnum[] = [],
      public version: number = 0
   ) {}
}
