import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  for: "phone",
  class: "form-label"
}
const _hoisted_6 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  for: "email",
  class: "form-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("PhoneNumber")) + ":", 1),
          _createVNode(_component_Field, {
            name: "phone",
            type: "text",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.phone }]),
            modelValue: _ctx.contactDetails.numberPhone,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contactDetails.numberPhone) = $event)),
            placeholder: _ctx.$t('EnterPhoneNumber')
          }, null, 8, ["class", "modelValue", "placeholder"]),
          _createVNode(_component_ErrorMessage, {
            name: "phone",
            class: "invalid-feedback"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("Email")) + ":", 1),
          _createVNode(_component_Field, {
            name: "email",
            type: "email",
            class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.email }]),
            modelValue: _ctx.contactDetails.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contactDetails.email) = $event)),
            placeholder: _ctx.$t('EnterEmail')
          }, null, 8, ["class", "modelValue", "placeholder"]),
          _createVNode(_component_ErrorMessage, {
            name: "email",
            class: "invalid-feedback"
          })
        ])
      ])
    ])
  ]))
}