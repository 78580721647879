
import { defineComponent, onBeforeMount, ref } from "vue";
import TitlePage from "@/appModules/common/component/TitlePage.vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { RouterLink, useRoute } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ServiceApi from "../domain/repository/ServiceApi";
import { useReCaptcha } from "vue-recaptcha-v3";
import ClientActivationAccountDto from "../domain/dto/ClientActivationAccountDto";

export default defineComponent({
   name: "ClientVerification",
   components: {
      TitlePage,
      LoadBar,
   },
   setup() {
      const route = useRoute();
      const loading = ref(true);
      const errored = ref(false);
      const isDataToShow = ref(true);
      const tokenSuccess = ref(false);
      const token = ref(route.query.token as string);
      const serviceApi = new ServiceApi();
      const useRecaptcha = useReCaptcha();

      onBeforeMount(() => {
         if (token.value && token.value.length > 0) {
            fetchVerificationToken();
         }
      });

      async function fetchVerificationToken() {
         await useRecaptcha?.recaptchaLoaded();
         let recaptcha = await useRecaptcha?.executeRecaptcha("activation");
         serviceApi
            .activation(new ClientActivationAccountDto(token.value, recaptcha))
            .then((success) => {
               tokenSuccess.value = true;
            })
            .finally(() => {
               loading.value = false;
            });
      }
      return { loading, errored, isDataToShow, tokenSuccess };
   },
});
