<template>
   <div class="row d-flex justify-content-evenly mt-4 fs-5 fw-bold text-center">
      <div class="col-10 col-sm-6 col-md-4 col-xl-3 mb-4">
         <p>{{ $t("ProductAvailability") }}</p>
         <Doughnut :chartData="chartData" />
      </div>
      <div class="col-10 col-sm-6 col-md-4 col-xl-3 mb-4">
         <p>
            {{ $t("BorrowedProducts") }}<span class="fs-6 fw-normal"> ({{ $t("Last5Days") }})</span>
         </p>
         <Bar :chartData="chartData2" />
      </div>
   </div>
</template>

<script>
import { Doughnut, Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, Chart } from "chart.js";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
Chart.register(ArcElement);

export default {
   name: "DashboardCharts",
   components: { Doughnut, Bar },
   setup() {
      const chartData = {
         labels: ["dostępne", "uszkodzone", "wypożyczone"],
         datasets: [
            {
               backgroundColor: ["#41B883", "red", "orange"],
               data: [40, 20, 80],
            },
         ],
      };
      const chartData2 = {
         labels: ["10.06.2004", "11.06.2004", "12.06.2004", "13.06.2004", "14.06.2004"],
         datasets: [
            {
               label: "ilość",
               backgroundColor: ["orange"],
               data: [80, 33, 89, 77, 55],
            },
         ],
      };
      return { chartData, chartData2 };
   },
};
</script>
