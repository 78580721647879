import { DocumentTypeEnum } from "../enumerated/DocumentTypeEnum";
import CreateDocumentPositionDto from "./CreateDocumentPosition";

export interface ICreateDocument {
   note: string;
   type: DocumentTypeEnum;
   externalNumber: string;
   documentPositions: Array<CreateDocumentPositionDto>;
}

export default class CreateDocumentDto implements ICreateDocument {
   constructor(
      public note: string = "",
      public type: DocumentTypeEnum = DocumentTypeEnum.PZ,
      public externalNumber: string = "",
      public documentPositions: Array<CreateDocumentPositionDto> = new Array<CreateDocumentPositionDto>()
   ) {}
}
