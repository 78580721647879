
import * as yup from "yup";
import { i18n } from "@/main";
import { storeToRefs } from "pinia";
import { defineComponent, onUnmounted, watch } from "vue";
import { useCategoryStore } from "../store/CategoryStore";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { useToast } from "vue-toastification";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";

export default defineComponent({
   name: "CategoryControlPanel",
   components: { Field, ErrorMessage },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectCategory"],
   setup(props, { emit }) {
      const store = useCategoryStore();
      const { create, update, getPageParams, resetToInitial, findById, setErrorMsg } = store;
      const { getCategory, getErrors } = storeToRefs(store);
      const toast = useToast();
      const { typeGrid } = useBreakpointsGrid();

      const { errors, validate, setFieldError } = useForm({
         validationSchema: yup.object().shape({
            categoryName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
         }),
      });

      watch(
         () => getErrors.value,
         (newValue) => {
            if (newValue.length > 0) setErrorMsg(setFieldError);
         }
      );

      onUnmounted(() => {
         resetToInitial();
      });

      async function onValidate() {
         const { valid } = await validate();

         return valid;
      }

      async function submitSave() {
         if (await onValidate()) {
            if (getCategory.value.uuid) update();
            else await create();
            if (getCategory.value.uuid) {
               emit("selectCategory", getCategory.value);
            }
         } else toast.error(i18n.global.t("FormHasError"));
      }

      async function showValue(id: string | string[]) {
         if (id) {
            await findById(id);
         } else {
            resetToInitial();
         }
      }

      return {
         getCategory,
         getPageParams,
         errors,
         typeGrid,
         equalsOrGreaterGrid,
         submitSave,
         resetToInitial,
         setFieldError,
         showValue,
      };
   },
});
