
import { defineComponent, onMounted, ref } from "vue";
import { httpAuthorization } from "@/appModules/common/domain/service/http-common";
import LoadBar from "@/appModules//common/component/LoadBar.vue";
import Format from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "ReportTariff",
   //    mixins: [format],
   components: {
      LoadBar,
   },
   setup() {
      const amountSumAll = ref(0);
      const amountSumBegin = ref(0);
      const amountSumUntil = ref(0);
      const loading = ref(true);
      const errored = ref(false);
      const isDataToShow = ref(false);
      const activeButtonClass = ref("btn btn-primary");
      const inactiveButtonClass = ref("btn btn-outline-secondary noprint");
      const minDate = ref("1970-01-01");
      const maxDate = ref("3000-12-31");
      const reportTariffResult = ref();
      const periodObject = ref({
         nowDate: "",
         startDate: "",
         endDate: "",
      });
      const typePeriod = ref(1);
      const startDate = ref("");
      const endDate = ref("");
      const format = new Format();

      onMounted(() => {
         fetchDashboard();
      });

      function fetchDashboard() {
         httpAuthorization()
            .get("api/c/dashboard/period")
            .then(
               (response) => {
                  periodObject.value = response.data;

                  startDate.value = periodObject.value.nowDate;
                  endDate.value = periodObject.value.nowDate;

                  onFetchRaport();
               },
               () => {
                  errored.value = true;
                  //   $parent.checkErrorAuthorization(error);
               }
            )
            .catch(() => (errored.value = true))
            .finally(() => (loading.value = false));
      }
      function onPeriodClickButton(_typePeriod: number) {
         typePeriod.value = _typePeriod;

         if (typePeriod.value == 1) {
            startDate.value = periodObject.value.nowDate;
            endDate.value = periodObject.value.nowDate;
         } else {
            startDate.value = periodObject.value.startDate;
            endDate.value = periodObject.value.endDate;
         }
      }
      function onFetchRaport() {
         isDataToShow.value = true;
         loading.value = false;

         const paramsTemp = new URLSearchParams();
         paramsTemp.append("start_date", startDate.value);
         paramsTemp.append("end_date", endDate.value);

         loading.value = true;
         errored.value = false;
         isDataToShow.value = false;

         httpAuthorization()
            .get("api/c/report-tariff", { params: paramsTemp })
            .then(
               (response) => {
                  reportTariffResult.value = response.data;
                  isDataToShow.value = true;
               },
               () => {
                  //   $parent.checkErrorAuthorization(error);
               }
            )
            .catch(() => {
               errored.value = true;
            })
            .finally(() => (loading.value = false));
      }
      return {
         amountSumAll,
         amountSumBegin,
         amountSumUntil,
         loading,
         errored,
         isDataToShow,
         activeButtonClass,
         inactiveButtonClass,
         minDate,
         maxDate,
         reportTariffResult,
         periodObject,
         typePeriod,
         startDate,
         endDate,
         format,
         onPeriodClickButton,
         onFetchRaport,
      };
   },
});
