
import { defineComponent } from "vue";

export default defineComponent({
   name: "ItemButtonPanel",
   props: {
      vertical: {
         type: Boolean,
         default: false,
      },
      visibleSearchButton: {
         type: Boolean,
         default: true,
      },
      visibleAddButton: {
         type: Boolean,
         default: true,
      },
      visibleEditButton: {
         type: Boolean,
         default: true,
      },
      visibleCancelButton: {
         type: Boolean,
         default: true,
      },
   },
   emits: ["searchButtonOnClick", "addButtonOnClick", "editButtonOnClick", "cancelButtonOnClick"],
   setup(props, { emit }) {
      function searchButtonOnClick() {
         emit("searchButtonOnClick");
      }
      function addButtonOnClick() {
         emit("addButtonOnClick");
      }
      function editButtonOnClick() {
         emit("editButtonOnClick");
      }
      function cancelButtonOnClick() {
         emit("cancelButtonOnClick");
      }
      return {
         searchButtonOnClick,
         addButtonOnClick,
         editButtonOnClick,
         cancelButtonOnClick,
      };
   },
});
