import { defineStore } from "pinia";
import { i18n } from "@/main";
import { useToast } from "vue-toastification";
import useListParams from "@/appModules/common/domain/composable/useListParams";
import UserAppApi from "../domain/repository/UserAppApi";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { ErrorItemCodeEnum } from "@/appModules/service/domain/enumerated/ErrorItemCodeEnum";
import UpdateUserAppDto from "../domain/dto/UpdateUserAppDto";

const api = new UserAppApi();
const toast = useToast();
const params = useListParams();

export const useDataUserAppStore = defineStore({
   id: "dataUserAppStore",
   state: () => ({
      userApp: new UserAppDto(),
      updateUserApp: new UpdateUserAppDto(),
      errors: [] as ErrorItem[],
   }),
   getters: {
      getPageParams: () => params,
      getUserApp: (state) => state.userApp,
   },
   actions: {
      async findById(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .findById(id)
            .then(
               (success) => {
                  this.userApp = success.data;
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },
      async update(id: string | string[] | undefined = undefined) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         this.updateUserApp.firstName = this.userApp.firstName;
         this.updateUserApp.lastName = this.userApp.lastName;
         this.updateUserApp.version = this.userApp.version;

         api.update(this.updateUserApp, id)
            .then(
               (success) => {
                  if (success.status == 200) {
                     this.userApp = success.data;
                     toast.success(i18n.global.t("SaveSuccessfullyChangesSaved"));
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .catch(() => (params.errorLoadData.value = true))
            .finally(() => (params.loadingData.value = false));
      },
      resetToInitial() {
         params.created.value = false;
         this.userApp = new UserAppDto();
      },
      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === ErrorItemCodeEnum.DuplicateLogin) {
               setFieldError("username", i18n.global.t("ErrorLoginIsAlreadyTaken"));
            }
         }
      },
   },
});
