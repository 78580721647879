import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "history-bar" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_4 = { class: "breadcrumb" }
const _hoisted_5 = { class: "breadcrumb-item" }
const _hoisted_6 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "mt-4" }
const _hoisted_9 = { href: "#product" }
const _hoisted_10 = { class: "table_of_contents" }
const _hoisted_11 = { href: "#product_item" }
const _hoisted_12 = { class: "table_of_contents" }
const _hoisted_13 = { class: "mt-5" }
const _hoisted_14 = {
  id: "product",
  class: "table_of_contents"
}
const _hoisted_15 = {
  id: "product_item",
  class: "table_of_contents mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InstructionMobileAppProduct = _resolveComponent("InstructionMobileAppProduct")!
  const _component_InstructionMobileAppProductItem = _resolveComponent("InstructionMobileAppProductItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("nav", _hoisted_3, [
          _createElementVNode("ol", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, { to: { name: 'panelSettings' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Settings")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.$t("MobileAppManual")), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t("TableOfContents")) + ":", 1),
      _createElementVNode("ol", null, [
        _createElementVNode("a", _hoisted_9, [
          _createElementVNode("li", _hoisted_10, _toDisplayString(_ctx.productTitle), 1)
        ]),
        _createElementVNode("a", _hoisted_11, [
          _createElementVNode("li", _hoisted_12, _toDisplayString(_ctx.productItemTitle), 1)
        ])
      ]),
      _createElementVNode("ol", _hoisted_13, [
        _createElementVNode("li", _hoisted_14, _toDisplayString(_ctx.productTitle), 1),
        _createVNode(_component_InstructionMobileAppProduct),
        _createElementVNode("li", _hoisted_15, _toDisplayString(_ctx.productItemTitle), 1),
        _createVNode(_component_InstructionMobileAppProductItem)
      ])
    ])
  ], 64))
}