
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { RouterLink, useRoute } from "vue-router";
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import { getPasswordMask } from "@/appModules/common/domain/command/MaskCommon";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useReCaptcha } from "vue-recaptcha-v3";
import ResetPasswordClientStepTwoDto from "../domain/dto/ResetPasswordClientStepTwoDto";
import { useLoginStore } from "../store/LoginStore";
import { storeToRefs } from "pinia";
export default defineComponent({
   name: "ResetPasswordStep2",
   components: {
      LoadBar,
      Field,
      ErrorMessage,
   },
   setup() {
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            password: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .matches(getPasswordMask(), i18n.global.t("IncorrectPasswordWithMask")),
            confirmPassword: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .test("passwords-match", i18n.global.t("IncorrectPasswordConfirmedNotMatch"), function (value) {
                  return this.parent.password === value;
               }),
         }),
      });
      const tokenKey = ref();
      const password_1 = ref("");
      const password_2 = ref("");
      const route = useRoute();
      const useRecaptcha = useReCaptcha();
      const token = ref(route.query.token as string);
      const store = useLoginStore();
      const { resetPassStep2, resetSuccessToInitial } = store;
      const { isResetSuccess, getParams } = storeToRefs(store);

      onMounted(() => {
         tokenKey.value = route.query.token;
      });

      onUnmounted(() => {
         resetSuccessToInitial();
      });

      async function submitSave() {
         if (await onValidate()) {
            await useRecaptcha?.recaptchaLoaded();
            let recaptcha = await useRecaptcha?.executeRecaptcha("resetPass");
            resetPassStep2(new ResetPasswordClientStepTwoDto(password_1.value, password_2.value, token.value, recaptcha));
         }
      }

      async function onValidate() {
         let rez = true;
         const { valid } = await validate();

         rez = valid;
         return rez;
      }

      return {
         errors,
         getParams,
         isResetSuccess,
         tokenKey,
         password_1,
         password_2,
         submitSave,
         onValidate,
      };
   },
});
