
import { PropType, defineComponent, toRefs } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid, equalsOrLessGrid, greaterGrid } from "../../../plugins/window-width";
import DocumentPositionDto from "../domain/dto/DocumentPosition";
import ProductItemPanel from "@/appModules/product/component/ProductItemPanel.vue";
import WarehouseItemPanel from "@/appModules/warehouse/component/WarehouseItemPanel.vue";
import ProductDto from "@/appModules/product/domain/dto/ProductDto";
import { ErrorMessage, Field } from "vee-validate";
import WarehouseDto from "@/appModules/warehouse/domain/dto/WarehouseDto";

export default defineComponent({
   name: "DocumentPositionPanel",
   components: {
      ProductItemPanel,
      WarehouseItemPanel,
      Field,
      ErrorMessage,
   },
   props: {
      idx: {
         type: Number,
         required: true,
      },
      position: {
         type: Object as PropType<DocumentPositionDto>,
         required: true,
      },
      visible: {
         type: Boolean,
         default: true,
      },
      errors: {
         type: Object,
      },
      addRemoveBtn: {
         type: Boolean,
         required: true,
      },
      isReadOnly: {
         type: Boolean,
         required: true,
      },
   },
   emits: ["removeDocumentPosition", "selectProduct", "cancelProduct", "selectWarehouse", "cancelWarehouse"],
   setup(props, { emit }) {
      const { position: positionRef, errors: errorsRef } = toRefs(props);
      const { typeGrid } = useBreakpointsGrid();

      function removeDocumentPositionButtonOnClick() {
         emit("removeDocumentPosition", props.idx);
      }

      function cancelProduct() {
         emit("cancelProduct");
         positionRef.value.product = new ProductDto();
      }

      function selectProduct(product: ProductDto) {
         emit("selectProduct", product);
         positionRef.value.product = product;
      }

      function cancelWarehouse() {
         emit("cancelWarehouse");
         positionRef.value.warehouse = new WarehouseDto();
      }

      function selectWarehouse(warehouse: WarehouseDto) {
         emit("selectWarehouse", warehouse);
         positionRef.value.warehouse = warehouse;
      }

      function isErrors(keyParam: string) {
         if (errorsRef.value) {
            return Object.keys(errorsRef.value).includes(keyParam);
         }
         return false;
      }

      return {
         typeGrid,
         positionRef,
         equalsOrGreaterGrid,
         equalsOrLessGrid,
         greaterGrid,
         removeDocumentPositionButtonOnClick,
         selectProduct,
         cancelProduct,
         selectWarehouse,
         cancelWarehouse,
         isErrors,
      };
   },
});
