
import { storeToRefs } from "pinia";
import { vue3Debounce } from "vue-debounce";
import { defineComponent, onMounted, ref } from "vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";
import LoadBar from "../../common/component/LoadBar.vue";
import DeleteItemModal from "../../common/component/DeleteItemModal.vue";
import PaginationPanel from "../../common/component/PaginationPanel.vue";
import SortComboBox from "../../common/component/SortComboBox.vue";
import SortingPaginationParam from "../../common/domain/command/SortingPaginationParam";
import { useDocumentsStore } from "../store/DocumentsStore";
import EnumService from "@/appModules/common/domain/service/EnumService";
import { toStringEnum } from "@/appModules/common/domain/service/format";
import { useDocumentStore } from "@/appModules/document/store/DocumentStore";
import DocumentDto from "@/appModules/document/domain/dto/Document";
import DocumentListItem from "../component/DocumentListItem.vue";
import PaginationService from "../../common/domain/service/PaginationService";

export default defineComponent({
   name: "Documents",
   components: {
      LoadBar,
      PaginationPanel,
      SortComboBox,
      DeleteItemModal,
      DocumentListItem,
   },
   directives: {
      debounce: vue3Debounce({ lock: true }),
   },
   props: {
      isModal: {
         type: Boolean,
         default: false,
      },
   },
   emits: ["selectDocument"],
   setup(props, { emit }) {
      const documentsStore = useDocumentsStore();
      const documentStore = useDocumentStore();
      const { getPageParams, getPageDto, sortOptions } = storeToRefs(documentsStore);
      const { fetchPaginationData } = documentsStore;
      const { typeGrid } = useBreakpointsGrid();
      const defaultSort = sortOptions.value[0];
      const searchBy = ref("");
      const selectedDocument = ref(new DocumentDto());

      onMounted(() => {
         getPageParams.value.sort.value = defaultSort;
         showValue();
      });

      function sortData(sortOption: SortingPaginationParam) {
         getPageParams.value.sort.value = sortOption;
         showValue();
      }

      function changePagination(page: number, size: number) {
         fetchPaginationData(page, size, searchBy.value);
      }

      function showValue() {
         fetchPaginationData(getPageDto.value.number, getPageDto.value.size, searchBy.value);
      }

      async function deleteDocument(uuid: string | string[]) {
         await documentStore.delete(uuid);
         fetchPaginationData(new PaginationService().calcPageNumber(getPageDto.value), getPageDto.value.size, searchBy.value);
      }

      function selectDocument(document: DocumentDto) {
         selectedDocument.value = document;
         emit("selectDocument", document);
      }

      return {
         selectedDocument,
         getPageDto,
         getPageParams,
         typeGrid,
         sortOptions,
         defaultSort,
         searchBy,
         EnumService,
         useBreakpointsGrid,
         equalsOrGreaterGrid,
         changePagination,
         sortData,
         showValue,
         toStringEnum,
         deleteDocument,
         selectDocument,
      };
   },
});
