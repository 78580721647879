import { defineStore } from "pinia";
import { i18n } from "@/main";
import { useToast } from "vue-toastification";
import usePageParams from "@/appModules/common/domain/composable/usePageParams";
import UserAppApi from "../domain/repository/UserAppApi";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";
import ErrorItem from "@/appModules/service/domain/command/ErrorItem";
import { ErrorItemCodeEnum } from "@/appModules/service/domain/enumerated/ErrorItemCodeEnum";
import UpdatePasswordUserAppDto from "../../service/domain/dto/UpdatePasswordUserAppDto";

const api = new UserAppApi();
const toast = useToast();
const params = usePageParams();

export const useChangePasswordUserAppStore = defineStore({
   id: "changePasswordUserAppStore",
   state: () => ({
      userApp: new UserAppDto(),
      updatePasswordUserApp: new UpdatePasswordUserAppDto(),
      errors: [] as ErrorItem[],
   }),
   getters: {
      getPageParams: () => params,
      getUserApp: (state) => state.userApp,
      getUpdatePasswordUserApp: (state) => state.updatePasswordUserApp,
   },
   actions: {
      async changePassword(setFieldError: any) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;

         api.changePassword(this.updatePasswordUserApp)
            .then(
               (success) => {
                  if (success.status == 200) {
                     toast.success(i18n.global.t("SaveSuccessfullyChangesSaved"));
                  }
               },
               (error) => {
                  if (error.response) {
                     if (error.response.status == 400) {
                        this.errors = error.response.data["invalid-params"];
                        toast.error(i18n.global.t("FormHasError"));
                        this.setErrorMsg(setFieldError);
                     } else {
                        toast.error(i18n.global.t("ErrorUnknown"));
                     }
                  } else {
                     toast.error(i18n.global.t("ErrorConnectToServer"));
                  }
               }
            )
            .finally(() => {
               params.loadingData.value = false;
            });
      },
      setErrorMsg(setFieldError: any) {
         for (const e of this.errors) {
            if (e.code === ErrorItemCodeEnum.CurrentPasswordInvalid) {
               setFieldError("currentPassword", i18n.global.t("CurrentPasswordInvalid"));
            }
         }
      },
      resetToInitial() {
         this.updatePasswordUserApp = new UpdatePasswordUserAppDto();
      },
   },
});
