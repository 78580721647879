import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  for: "warehouseName",
  class: "form-label"
}
const _hoisted_7 = { class: "col-sm-12 col-lg-6 mb-3" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  for: "type",
  class: "form-label"
}
const _hoisted_10 = { class: "input-group" }
const _hoisted_11 = {
  disabled: "",
  value: ""
}
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "row mt-3" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "text-muted" }
const _hoisted_16 = {
  class: "btn-group d-flex mt-2 col-12 col-lg-6",
  role: "group"
}
const _hoisted_17 = ["checked"]
const _hoisted_18 = {
  class: "btn btn-outline-success",
  for: "btnSameAddress"
}
const _hoisted_19 = ["checked"]
const _hoisted_20 = {
  class: "btn btn-outline-success",
  for: "btnOtherAddress"
}
const _hoisted_21 = {
  key: 0,
  class: "col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_AddressPanel = _resolveComponent("AddressPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("WarehouseName")), 1),
            _createVNode(_component_Field, {
              name: "warehouseName",
              type: "text",
              class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.warehouseName }]),
              placeholder: _ctx.$t('EnterName'),
              modelValue: _ctx.getWarehouse.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getWarehouse.name) = $event))
            }, null, 8, ["class", "placeholder", "modelValue"]),
            _createVNode(_component_ErrorMessage, {
              name: "warehouseName",
              class: "invalid-feedback"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("WarehouseType")), 1),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Field, {
                as: "select",
                name: "type",
                id: "type",
                class: _normalizeClass(["form-select required", { 'is-invalid': _ctx.errors.type }]),
                modelValue: _ctx.selected,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
                  _cache[2] || (_cache[2] = ($event: any) => (_ctx.change(_ctx.selected)))
                ]
              }, {
                default: _withCtx(() => [
                  _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.$t("SelectType")), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.EnumService.getValues(_ctx.WarehouseTypeEnum), (type) => {
                    return (_openBlock(), _createElementBlock("option", {
                      class: "row table-body",
                      key: type,
                      value: type
                    }, _toDisplayString(_ctx.$t("WarehouseTypeEnum" + type)), 9, _hoisted_12))
                  }), 128))
                ]),
                _: 1
              }, 8, ["class", "modelValue"]),
              _createVNode(_component_ErrorMessage, {
                name: "type",
                class: "invalid-feedback"
              })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("h5", _hoisted_15, [
            _createTextVNode(_toDisplayString(_ctx.$t("WarehouseAddress")) + " ", 1),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("input", {
                type: "radio",
                class: "btn-check",
                name: "btnradio",
                id: "btnSameAddress",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getWarehouse.addressOtherThanMain = false)),
                checked: _ctx.getWarehouse.addressOtherThanMain === false,
                autocomplete: "off"
              }, null, 8, _hoisted_17),
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("SameAsCompanyAddress")), 1),
              _createElementVNode("input", {
                type: "radio",
                class: "btn-check",
                name: "btnradio",
                id: "btnOtherAddress",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getWarehouse.addressOtherThanMain = true)),
                checked: _ctx.getWarehouse.addressOtherThanMain === true,
                autocomplete: "off"
              }, null, 8, _hoisted_19),
              _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("OtherThanCompanyAddress")), 1)
            ])
          ])
        ])
      ]),
      (_ctx.getWarehouse.addressOtherThanMain && _ctx.getWarehouse.address)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _createVNode(_component_AddressPanel, {
              addressDto: _ctx.getWarehouse.address,
              ref: "addressPanel"
            }, null, 8, ["addressDto"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}