
import { defineComponent, onMounted, ref } from "vue";
import AddressPanel from "@/appModules/service/component/AddressPanel.vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import { useWarehouseStore } from "../store/WarehouseStore";
import { storeToRefs } from "pinia";
import { WarehouseTypeEnum } from "../domain/enumerated/WarehouseTypeEnum";
import EnumService from "@/appModules/common/domain/service/EnumService";

export default defineComponent({
   name: "WarehouseProfileForm",
   components: {
      AddressPanel,
      Field,
      ErrorMessage,
   },
   setup() {
      const store = useWarehouseStore();
      const { getWarehouse } = storeToRefs(store);
      const { setErrorMsg } = store;
      const addressPanel = ref<typeof AddressPanel>();
      const selected = ref(0);

      const { errors, validate, setFieldError } = useForm({
         validationSchema: yup.object().shape({
            warehouseName: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            type: yup.string().required(i18n.global.t("FieldCanNotBeEmpty")),
         }) as any,
      });

      onMounted(() => {
         if (getWarehouse.value.uuid) {
            selected.value = EnumService.getIndex(WarehouseTypeEnum, getWarehouse.value.type.toString());
         }
         setErrorMsg(setFieldError);
      });

      async function onValidate() {
         let res = true;
         const { valid } = await validate();

         res = valid;

         if (getWarehouse.value.addressOtherThanMain) {
            if (addressPanel.value) {
               res = await addressPanel.value.onValidate();
            }
         }

         return res;
      }
      function change(type: number) {
         getWarehouse.value.type = type;
      }

      return { selected, addressPanel, getWarehouse, errors, WarehouseTypeEnum, EnumService, onValidate, setFieldError, change };
   },
});
