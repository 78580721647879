
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import TitlePage from "@/appModules/common/component/TitlePage.vue";
import RegistrationForm from "@/appModules/service/component/RegistrationForm.vue";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { useCompanyRegistrationStore } from "../store/CompanyRegistrationStore";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useReCaptcha } from "vue-recaptcha-v3";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";
import i18n from "@/plugins/i18n";

export default defineComponent({
   name: "Registration",
   components: {
      TitlePage,
      RegistrationForm,
      LoadBar,
   },
   // mixins: [format],
   setup() {
      const loading = ref(false);
      const errored = ref(false);
      const isDataToShow = ref(true);
      const successText = ref(false);
      const errorText = ref("");
      const store = useCompanyRegistrationStore();
      const { clientRegistration, resetRegistrationSuccess } = store;
      const { isRegistrationSuccess, isLoading, isError, isNoDataToDisplay } = storeToRefs(store);
      const toast = useToast();

      const registrationForm = ref<typeof RegistrationForm>();

      window.scrollTo(0, 0);
      const useRecaptcha = useReCaptcha();
      const recaptchaBadge = useReCaptcha()?.instance;

      onMounted(() => {
         setTimeout(() => {
            recaptchaBadge?.value?.showBadge();
         }, 500);
      });

      onUnmounted(() => {
         recaptchaBadge?.value?.hideBadge();
         resetRegistrationSuccess();
      });

      async function submitSave() {
         if (await onValidate()) {
            await useRecaptcha?.recaptchaLoaded();
            store.getServiceRegistrationDTO.recaptchaTokenKey = await useRecaptcha?.executeRecaptcha("registration");
            clientRegistration();
         } else toast.error(i18n.global.t("FormHasError"));
      }

      async function onValidate() {
         let valid = true;

         if (registrationForm.value) {
            valid = await registrationForm.value.onValidate();
         }
         return valid;
      }
      return {
         loading,
         errored,
         isDataToShow,
         successText,
         errorText,
         store,
         registrationForm,
         isRegistrationSuccess,
         isError,
         isLoading,
         isNoDataToDisplay,
         submitSave,
      };
   },
});
