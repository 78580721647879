import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "full-view" }
const _hoisted_2 = { class: "history-bar" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  style: {"--bs-breadcrumb-divider":"'>'"},
  "aria-label": "breadcrumb"
}
const _hoisted_5 = { class: "breadcrumb" }
const _hoisted_6 = {
  key: 0,
  class: "breadcrumb-item"
}
const _hoisted_7 = {
  key: 1,
  class: "breadcrumb-item"
}
const _hoisted_8 = { class: "breadcrumb-item" }
const _hoisted_9 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}
const _hoisted_10 = { class: "container mt-3" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "row form-group" }
const _hoisted_15 = { class: "col-sm-12 col-lg-4" }
const _hoisted_16 = { for: "currentPassword" }
const _hoisted_17 = { class: "row form-group mt-3" }
const _hoisted_18 = { class: "col-sm-12 col-lg-4" }
const _hoisted_19 = { for: "newPassword" }
const _hoisted_20 = { class: "col-sm-12 col-lg-4" }
const _hoisted_21 = { for: "confirmNewPassword" }
const _hoisted_22 = { class: "row form-group" }
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "text-secondary" }
const _hoisted_25 = { class: "col-12 mt-4 text-center" }
const _hoisted_26 = { class: "col-6 col-sm-4 d-grid gap-2 mx-auto mt-1 mb-5" }
const _hoisted_27 = {
  type: "submit",
  class: "btn btn-success btn-lg d-flex align-items-center justify-content-center"
}
const _hoisted_28 = { class: "ms-2 ms-sm-3 d-md-inline d-lg-inline d-xl-inline d-xxl-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadBar = _resolveComponent("LoadBar")!
  const _component_ProfileHeaderUserApp = _resolveComponent("ProfileHeaderUserApp")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _createElementVNode("ol", _hoisted_5, [
            (_ctx.equalsOrGreaterGrid('sm', _ctx.typeGrid))
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _createVNode(_component_router_link, { to: { name: 'panelSettings' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Settings")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.equalsOrGreaterGrid('sm', _ctx.typeGrid))
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                  _createVNode(_component_router_link, { to: { name: 'panelUsers' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Employees")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_router_link, { to: { name: 'panelProfileUserApp' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTokenUserApp().uuid === _ctx.route.params.id ? _ctx.$t("MyProfile") : _ctx.$t("Profile")), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.getTokenUserApp().uuid === _ctx.route.params.id ? _ctx.$t("MyPasswordEdit") : _ctx.$t("EmployeePasswordEdit")), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_LoadBar, {
        loading: _ctx.getPageParams.loadingData.value,
        errored: _ctx.getPageParams.errorLoadData.value,
        isDataToShow: _ctx.getPageParams.noDataToDisplay.value
      }, null, 8, ["loading", "errored", "isDataToShow"]),
      (!_ctx.getPageParams.noDataToDisplay.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
            _createVNode(_component_ProfileHeaderUserApp),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("form", {
                  onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.submitSave()), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("CurrentPassword")) + ":", 1),
                      _createVNode(_component_Field, {
                        id: "currentPassword",
                        name: "currentPassword",
                        type: "password",
                        class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.currentPassword }]),
                        modelValue: _ctx.getUpdatePasswordUserApp.currentPassword,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getUpdatePasswordUserApp.currentPassword) = $event)),
                        placeholder: _ctx.$t('CurrentPassword')
                      }, null, 8, ["class", "modelValue", "placeholder"]),
                      _createVNode(_component_ErrorMessage, {
                        name: "currentPassword",
                        class: "invalid-feedback"
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("NewPassword")) + ":", 1),
                      _createVNode(_component_Field, {
                        id: "newPassword",
                        name: "newPassword",
                        type: "password",
                        class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.newPassword }]),
                        modelValue: _ctx.getUpdatePasswordUserApp.newPassword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getUpdatePasswordUserApp.newPassword) = $event)),
                        placeholder: _ctx.$t('Password')
                      }, null, 8, ["class", "modelValue", "placeholder"]),
                      _createVNode(_component_ErrorMessage, {
                        name: "newPassword",
                        class: "invalid-feedback"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("RepeatNewPassword")) + ":", 1),
                      _createVNode(_component_Field, {
                        id: "confirmNewPassword",
                        name: "confirmNewPassword",
                        type: "password",
                        class: _normalizeClass(["form-control required", { 'is-invalid': _ctx.errors.confirmNewPassword }]),
                        modelValue: _ctx.getUpdatePasswordUserApp.confirmNewPassword,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getUpdatePasswordUserApp.confirmNewPassword) = $event)),
                        placeholder: _ctx.$t('RepeatNewPassword')
                      }, null, 8, ["class", "modelValue", "placeholder"]),
                      _createVNode(_component_ErrorMessage, {
                        name: "confirmNewPassword",
                        class: "invalid-feedback"
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("h6", _hoisted_24, _toDisplayString(_ctx.$t("LongerPasswordMoreSecureUseAtLeast8Characters")), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("button", _hoisted_27, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: "save",
                          size: "1x"
                        }),
                        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("Save")), 1)
                      ])
                    ])
                  ])
                ], 32)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}