
import { i18n } from "@/main";
import * as yup from "yup";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { defineComponent, onMounted } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import { useChangePasswordUserAppStore } from "@/appModules/userApp/store/ChangePasswordUserAppStore";
import { getPasswordMask } from "@/appModules/common/domain/command/MaskCommon";
import { getTokenUserApp } from "@/appModules/service/domain/repository/TokenUserAppStorage";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import ProfileHeaderUserApp from "@/appModules/userApp/component/ProfileHeaderUserApp.vue";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "../../../plugins/window-width";

export default defineComponent({
   name: "ChangePasswordUserApp",
   components: {
      LoadBar,
      Field,
      ErrorMessage,
      ProfileHeaderUserApp,
   },
   setup() {
      const route = useRoute();
      const store = useChangePasswordUserAppStore();
      const { changePassword, resetToInitial } = store;
      const { getPageParams, getUpdatePasswordUserApp, getUserApp } = storeToRefs(store);
      const toast = useToast();
      const { typeGrid } = useBreakpointsGrid();

      const { errors, validate, setFieldError } = useForm({
         validationSchema: yup.object().shape({
            currentPassword: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            newPassword: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .matches(getPasswordMask(), i18n.global.t("IncorrectPasswordWithMask"))
               .max(75, i18n.global.t("TextMaxLength", ["75"])),
            confirmNewPassword: yup
               .string()
               .required(i18n.global.t("FieldCanNotBeEmpty"))
               .max(75, i18n.global.t("TextMaxLength", ["75"]))
               .test("passwords-match", i18n.global.t("IncorrectPasswordConfirmedNotMatch"), function (value) {
                  return this.parent.newPassword === value;
               }),
         }),
      });

      onMounted(() => {
         resetToInitial();
      });

      async function onValidate() {
         let rez = true;
         const { valid } = await validate();

         rez = valid;
         return rez;
      }

      async function submitSave() {
         if (await onValidate()) {
            changePassword(setFieldError);
         } else {
            toast.error(i18n.global.t("FormHasError"));
         }
      }
      return {
         getPageParams,
         getUpdatePasswordUserApp,
         errors,
         getUserApp,
         route,
         typeGrid,
         equalsOrGreaterGrid,
         getTokenUserApp,
         submitSave,
      };
   },
});
