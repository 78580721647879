import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row mt-5" }
const _hoisted_2 = {
  key: 0,
  class: "col-4 d-grid gap-2 mx-auto mt-1 mb-5"
}
const _hoisted_3 = { class: "ms-3 d-none d-md-inline d-lg-inline d-xl-inline d-xxl-inline" }
const _hoisted_4 = {
  key: 1,
  class: "col-4 d-grid gap-2 mx-auto mt-1 mb-5"
}
const _hoisted_5 = { class: "me-3 d-none d-md-inline d-lg-inline d-xl-inline d-xxl-inline" }
const _hoisted_6 = {
  key: 2,
  class: "col-4 d-grid gap-2 mx-auto mt-1 mb-5"
}
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "spinner-border spinner-border-sm me-3" }
const _hoisted_9 = { class: "ms-3 d-none d-md-inline d-lg-inline d-xl-inline d-xxl-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isNewViewMode() && _ctx.currentStep > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-outline-secondary btn-lg",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backStepClick && _ctx.backStepClick(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "arrow-left",
              size: "1x"
            }),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("Back")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isNewViewMode() && _ctx.currentStep < _ctx.maxStep)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-outline-primary btn-lg",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextStepClick && _ctx.nextStepClick(...args)))
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("Next")), 1),
            _createVNode(_component_font_awesome_icon, {
              icon: "arrow-right",
              size: "1x"
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-success btn-lg",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveClick && _ctx.saveClick(...args))),
            disabled: _ctx.savingData
          }, [
            _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
              [_vShow, _ctx.savingData]
            ]),
            _createVNode(_component_font_awesome_icon, {
              icon: "save",
              size: "1x"
            }),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("Save")), 1)
          ], 8, _hoisted_7)
        ]))
  ]))
}