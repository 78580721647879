
import { PropType, defineComponent, onMounted, ref, toRefs } from "vue";
import { FieldArray, useForm } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";
import useBreakpointsGrid, { equalsOrGreaterGrid } from "@/plugins/window-width";
import DocumentDto from "@/appModules/document/domain/dto/Document";
import DocumentPositionDto from "../domain/dto/DocumentPosition";
import DocumentPositionPanel from "@/appModules/document/component/DocumentPositionPanel.vue";

export default defineComponent({
   name: "DocumentPositionsControlPanel",
   components: {
      FieldArray,
      DocumentPositionPanel,
   },
   props: {
      document: {
         type: Object as PropType<DocumentDto>,
         required: true,
      },
   },
   setup(props) {
      const index = ref(0);
      const { document } = toRefs(props);
      const { typeGrid } = useBreakpointsGrid();
      const { errors, validate } = useForm({
         validationSchema: yup.object().shape({
            positions: yup
               .array()
               .of(
                  yup.object().shape({
                     quantity: yup
                        .number()
                        .min(1, i18n.global.t("EnterIntegerMoreThanOne"))
                        .typeError(i18n.global.t("EnterInteger"))
                        .required(i18n.global.t("FieldCanNotBeEmpty")),
                     productItemPanel: yup.string().required(i18n.global.t("FieldCanNotBeEmpty")),
                     warehouseItemPanel: yup.string().required(i18n.global.t("FieldCanNotBeEmpty")),
                  })
               )
               .strict(),
         }) as any,
      });

      onMounted(() => {
         if (document.value.documentPositions?.length === 0) addPositionOnButtonClick();
      });

      async function onValidate() {
         const { valid } = await validate();

         return valid;
      }

      function addPositionOnButtonClick() {
         document.value.documentPositions.push(new DocumentPositionDto());
      }

      function removeDocumentPosition(idx: number) {
         document.value.documentPositions = document.value.documentPositions.filter((x, index) => index != idx);
      }

      return {
         typeGrid,
         errors,
         index,
         equalsOrGreaterGrid,
         onValidate,
         addPositionOnButtonClick,
         removeDocumentPosition,
      };
   },
});
