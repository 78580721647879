import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitlePage = _resolveComponent("TitlePage")!
  const _component_ResetPasswordStep1 = _resolveComponent("ResetPasswordStep1")!
  const _component_ResetPasswordStep2 = _resolveComponent("ResetPasswordStep2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TitlePage, {
      title: _ctx.$t('PasswordReset')
    }, null, 8, ["title"]),
    (_ctx.stepNum === 1)
      ? (_openBlock(), _createBlock(_component_ResetPasswordStep1, { key: 0 }))
      : (_openBlock(), _createBlock(_component_ResetPasswordStep2, { key: 1 }))
  ], 64))
}