import DocumentDto from "@/appModules/document/domain/dto/Document";
import ProductDto from "@/appModules/product/domain/dto/ProductDto";
import UserApp from "@/appModules/userApp/domain/dto/UserAppDto";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";
import WarehouseDto from "@/appModules/warehouse/domain/dto/WarehouseDto";

export interface IRent {
   uuid: string;
   product: ProductDto;
   userApp: UserAppDto;
   documentBegin: DocumentDto;
   quantityBegin: number;
   warehouseBegin: WarehouseDto;
   beginDate: string;
   documentUntil: DocumentDto;
   quantityUntil: number;
   warehouseUntil: WarehouseDto;
   untilDate: string;
   userAppUntil: UserAppDto;
   timeRentTotalSeconds: number;
   quantityDamagedUntil: number;
   version: number;
}

export default class RentDto implements IRent {
   constructor(
      public uuid: string = "",
      public product: ProductDto = new ProductDto(),
      public userApp: UserApp = new UserApp(),
      public documentBegin: DocumentDto = new DocumentDto(),
      public quantityBegin: number = 0,
      public warehouseBegin: WarehouseDto = new WarehouseDto(),
      public beginDate: string = "",
      public documentUntil: DocumentDto = new DocumentDto(),
      public quantityUntil: number = 0,
      public warehouseUntil: WarehouseDto = new WarehouseDto(),
      public untilDate: string = "",
      public userAppUntil: UserApp = new UserApp(),
      public timeRentTotalSeconds: number = 0,
      public quantityDamagedUntil: number = 0,
      public version: number = 0
   ) {}
}
