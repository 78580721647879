import AddressDto from "./AddressDto";

export interface ICreateCompany {
   name: string;
   firstName: string;
   lastName: string;
   nip: string;
   numberPhone: string;
   email: string;
   address: AddressDto;
   addressToInvoice: AddressDto;
}

export default class CreateCompanyDto implements ICreateCompany {
   constructor(
      public name: string = "",
      public firstName: string = "",
      public lastName: string = "",
      public nip: string = "",
      public numberPhone: string = "",
      public email: string = "",
      public address: AddressDto = new AddressDto(),
      public addressToInvoice: AddressDto = new AddressDto()
   ) {}
}
