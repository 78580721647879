import { defineStore } from "pinia";
import useListParams from "@/appModules/common/domain/composable/useListParams";
import UserAppApi from "../domain/repository/UserAppApi";
import UserAppDto from "@/appModules/userApp/domain/dto/UserAppDto";

const api = new UserAppApi();
const params = useListParams();

export const useProfileUserAppStore = defineStore({
   id: "profileUserAppStore",
   state: () => ({
      userApp: new UserAppDto(),
   }),
   getters: {
      getPageParams: () => params,
      getUserApp: (state) => state.userApp,
   },
   actions: {
      async findById(id: string | string[]) {
         params.loadingData.value = true;
         params.errorLoadData.value = false;
         params.noDataToDisplay.value = false;

         return api
            .findById(id)
            .then(
               (success) => {
                  this.userApp = success.data;
               },
               (error) => {
                  if (error.response && error.response.status == 404) {
                     params.noDataToDisplay.value = true;
                  } else {
                     params.errorLoadData.value = true;
                  }
               }
            )
            .catch(() => {
               params.errorLoadData.value = true;
            })
            .finally(() => {
               params.loadingData.value = false;
            });
      },
      resetToInitial() {
         params.created.value = false;
         this.userApp = new UserAppDto();
      },
   },
});
