
import { defineComponent, onMounted, ref } from "vue";
import { httpAuthorization } from "@/appModules/common/domain/service/http-common";
import format from "@/appModules/common/domain/service/format";
import LoadBar from "@/appModules/common/component/LoadBar.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Format from "@/appModules/common/domain/service/format";

export default defineComponent({
   name: "DeclareTimeRent",
   components: {
      LoadBar,
   },
   // mixins: [format],
   setup() {
      const loading = ref(false);
      const errored = ref(false);
      const isDataToShow = ref(true);
      const errorText = ref("");
      const successText = ref("");
      const rowsData = ref([] as any);
      const format = new Format();
      const loadingData = ref(false);

      onMounted(() => {
         fetchData();
      });

      function fetchData() {
         loading.value = true;
         errored.value = false;
         isDataToShow.value = false;

         httpAuthorization()
            .get("/api/c/declare-time-rents")
            .then(
               (response) => {
                  rowsData.value = response.data;
                  if (rowsData.value.length === 0) {
                     for (var i = 0; 3 > i; i++) {
                        addRow(i);
                     }
                  }
                  isDataToShow.value = true;
               },
               (error) => {
                  errored.value = true;
               }
            )
            .catch(() => (errored.value = true))
            .finally(() => (loading.value = false));
      }
      function addRow(number: number) {
         rowsData.value.push({
            id: 0,
            number: number,
            timeValue: 1,
            timeType: 2,
            status: 0,
            multiplyType: 0,
         });
      }
      function onChangeStatus(event: any, row: any) {
         if (event.target.checked) {
            row.status = 1;
         } else {
            row.status = 0;
         }
      }
      function onChangeIsManual(event: any, row: any) {
         if (event.target.checked) {
            row.multiplyType = 1;
         } else {
            row.multiplyType = 0;
         }
      }
      function validate() {
         var rez = false;
         for (var i = 0; rowsData.value.length > i; i++) {
            if (rowsData.value[i].status === 1) {
               rez = true;
               break;
            }
         }
         if (rez === false) {
            showMessage(3);
         }
         return rez;
      }
      function submitSave() {
         successText.value = "";
         errorText.value = "";

         if (validate()) {
            loading.value = true;
            errored.value = false;
            isDataToShow.value = false;

            httpAuthorization()
               .put("/api/c/declare-time-rents/save-all", rowsData)
               .then(
                  (response) => {
                     if (response.status === 200) {
                        rowsData.value = response.data;
                        isDataToShow.value = true;
                        showMessage(0);
                     }
                  },
                  (error) => {
                     validError(error);
                  }
               )
               .catch(() => showMessage(2))
               .finally(() => (loading.value = false));
         }
      }
      function validError(error: any) {
         if (error.response.status === 404) {
            isDataToShow.value = false;
         } else {
            isDataToShow.value = true;
            if (error.response.status === 409) {
               // http status 409 oznacz problem z unikalnoscia pola
               /*
                            if(error.response.data.message === "tariff_name") {
                                nameInvalidFeedback = 'Cennik o takiej nazwie już istnieje, proszę podać inną nazwe.';
                                nameIsInvalid = true;
                                showMessage(1);
                            }
                            else
                            */
               {
                  showMessage(2);
               }
            } else {
               showMessage(2);
            }
         }
      }
      function showMessage(messageType: number) {
         if (messageType === 0) {
            successText.value = "Zapis zakończony powodzeniem.";
         } else if (messageType === 1) {
            errorText.value = "Formularz zawiera błędy, proszę poprawić.";
         } else if (messageType === 2) {
            errorText.value = "Problem z zapisem, proszę spróbować później.";
         } else if (messageType === 3) {
            errorText.value = "Proszę aktywować przynajmniej jedną pozycje.";
         } else {
            errorText.value = "Problem z połaczeniem do serwera, proszę spróbować później.";
         }
      }
      return {
         loading,
         errored,
         isDataToShow,
         errorText,
         successText,
         rowsData,
         format,
         loadingData,
         onChangeIsManual,
         onChangeStatus,
         submitSave,
      };
   },
});
