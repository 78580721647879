import moment from "moment";
import "moment-timezone";

export default class Format {
   dateTimeToString(dateTime: string) {
      let rez = "-- : -- : --       _ _-_ _-_ _ _ _";
      if (dateTime && dateTime.length >= 19) {
         //2020-08-05 22:52:00
         rez = dateTime.substring(11, 19) + " " + dateTime.substring(0, 10);
      }
      return rez;
   }

   dateTimeToTimeString(dateTime: string) {
      return moment(dateTime).format("HH:mm:ss");
   }

   dateTimeToDateString(dateTime: string) {
      return moment(dateTime).format("DD.MM.YYYY");
   }

   formatTimeWithLeadingZero(time: number) {
      if (time < 10) {
         return "0" + time;
      }
      return time.toString();
   }

   getRemainingSeconds(time: any, format: string) {
      const dateTime = moment(time, format);
      const dateTimeNow = moment.tz(moment.tz(), "Europe/Warsaw");

      return dateTime.diff(dateTimeNow, "seconds");
   }
}
