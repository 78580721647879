import PaginationDto from "../dto/PaginationDto";

export default class PaginationService {
   calcPageNumber(paginationDto: PaginationDto): number {
      if (paginationDto.first) return 0;
      if (paginationDto.totalPages > 0 && paginationDto.numberOfElements === 1) return paginationDto.totalPages - 2;
      return paginationDto.number;
   }

   GetSearchByMap(arrayOfMaps: Array<Map<string, string>>) {
      const searchByMap = new Map<string, string>();
      arrayOfMaps.forEach((map) => {
         map.forEach((element) => {
            searchByMap.set(map.keys().next().value, element);
         });
      });

      return searchByMap;
   }
}
